<template>
  <v-dialog
    class="CsvUploadDialog"
    persistent
    scrollable
    v-model="dialog"
    width="35%"
  >
    <v-card>
      <v-card-title
        style="
          background-color: #ffffff;
          color: #2a409a;
          font-size: 20px;
          font-weight: 500;
        "
        >{{ csvDialogObject.title }}</v-card-title
      >
      <v-divider />
      <v-card-text
        class="scroll-2"
        style="overflow-y: scroll; overflow-x: hidden"
      >
        <!-- height:650px; padding: 30px 30px;  0px 0px" -->
        <v-container>
          <div class="input_field_div">
            <div style="width: 100%; margin-bottom: 8px" align="left">
              <label>Select Workflow</label>
            </div>
            <div style="width: 100%" align="right">
              <v-autocomplete
                style="border-radius: 10px"
                outlined
                background-color="#F2F2F2"
                :items="workFlows.option_values"
                item-value="workflow_id"
                item-text="value"
                v-model="workFlowId"
                @change="selectWorkflow()"
              ></v-autocomplete>
            </div>
          </div>
          <div class="input_field_div">
            <div style="width: 100%; margin-bottom: 8px" align="left">
              <label>{{ $t("DownloadSampleCsv") }}</label>
            </div>
            <div style="width: 100%" align="right">
              <v-btn
                depressed
                class="rounded-lg"
                color="#2A409A"
                style="color: white; height: 40px"
                @click="downloadSampleCsv()"
                :disabled="!workFlowId"
              >
                <v-icon dark> mdi-download </v-icon>
              </v-btn>
            </div>
          </div>
          <div class="input_field_div">
            <div style="width: 100%; margin-bottom: 8px" align="left">
              <label>Select File</label>
            </div>
            <div style="width: 100%" align="right">
              <v-file-input
                outlined
                background-color="#F2F2F2"
                prepend-icon=""
                append-icon="mdi-paperclip"
                show-size
                v-model="files"
                @change="onFileChange()"
              ></v-file-input>
            </div>
          </div>
        </v-container>
      </v-card-text>
      <v-divider />

      <v-card-actions style="display: flex; justify-content: right">
        <div style="margin-right: 30px">
          <v-btn
            outline
            style="
              background-color: #fffff;
              color: rgb(42, 64, 154);
              border: 1px solid rgb(42, 64, 154);
              width: 140px;
            "
            @click="clickCancel()"
            >{{ $t("Cancel") }}</v-btn
          >
        </div>

        <div>
          <v-btn
            depressed
            class="rounded-lg"
            color="#2A409A"
            style="color: white; height: 40px"
            :disabled="!workFlowId || !fileName"
            @click="clickCsvUpload()"
          >
            {{ $t("UploadCsv") }}
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
    <v-overlay :value="showSpinner">
      <SpinnerComponent></SpinnerComponent>
    </v-overlay>
    <div v-if="showMessageDialog">
      <MessageDialog
        :displayDialog="showMessageDialog"
        :messageObject="errorMessageShow ? errorMessageObject : okMessageObject"
        @closeMessageDialog="closeMessageDialog"
      ></MessageDialog>
    </div>
  </v-dialog>
</template>

<script>
import FileUploadComponent from "./FileUploadComponent.vue";
import axios from "axios";
// import Toasted from "vue-toasted";
import SpinnerComponent from "./SpinnerComponent.vue";
import MessageDialog from "./MessageDialog.vue";
import {getFromLocalStorage} from "../store/localStorageInterface"
export default {
  props: ["screenDefinition", "csvDialogObject"],
  components: {
    FileUploadComponent,
    SpinnerComponent,
    MessageDialog,
  },
  data() {
    return {
      fieldDefinition: [],
      // dialogObject: {},
      files: [],
      dialog: false,
      workFlowId: "",
      workFlows: {},
      fileName: "",
      showSpinner: false,
      showMessageDialog: false,
      errorMessageShow: false,
      errorMessageObject: {},
      okMessageObject: {},
    };
  },
  mounted() {
    this.fieldDefinition = this.$props.screenDefinition.fieldDetails.fields;
    this.csvDialogObject = this.$props.csvDialogObject;
    this.dialog = this.csvDialogObject.display;
    this.workFlows = this.fieldDefinition.find((fieldItem) => {
      return fieldItem.input_type === "workflowoptions";
    });
  },
  methods: {
    clickCancel() {
      this.dialog = false;
      this.$emit("closeCsvDialog", false);
    },
    closeDialog(flag) {
      this.$emit("closeDialog", false);
    },
    clickSave() {
      this.newValidation();
    },
    downloadSampleCsv() {
      var i = 0;
      var line = "";
      var csv = "";
      var columns = {};

      let currentWorkflowName = this.workFlows.option_values.find(
        (workflow) => workflow.workflow_id == this.workFlowId
      )["value"];
      for (var fieldItem of this.fieldDefinition) {
        if (
          fieldItem.isVisible &&
          (fieldItem.input_type === "input" ||
           fieldItem.input_type === "nrc" ||
            fieldItem.input_type === "optionsWithSearch" ||
            fieldItem.input_type === "workflowoptions" ||
            fieldItem.input_type === "AssigneeUseroptions" ||
            fieldItem.input_type === "assigneeOptions" ||
            fieldItem.input_type === "ConditionalOptions" ||
            fieldItem.input_type === "options" ||
            fieldItem.input_type === "toggle" ||
            fieldItem.input_type === "date" ||
            fieldItem.input_type === "datetime" ||
            fieldItem.input_type === "radio" ||
            fieldItem.input_type === "textarea" ||
            fieldItem.input_type === "phone" ||
            fieldItem.input_type === "email" ||
            fieldItem.input_type === "URL" ||
            fieldItem.input_type === "currency" ||
            fieldItem.input_type === "table")
        ) {
          if (fieldItem.isDependent) {
            if (fieldItem.dependentField == "current_status") {
              continue;
            } else if (fieldItem.dependentField == "issue_type") {
              if (!fieldItem.dependentValue.includes(currentWorkflowName)) {
                continue;
              }
            } else {
              let dependentFieldType = fieldItem.dependentField;
              let skipColumn = false;
              let dependentField;
              while (
                dependentFieldType != "current_status" &&
                dependentFieldType != "issue_type"
              ) {
                dependentField = this.fieldDefinition.find((field) => {
                  if (field.key == dependentFieldType) return true;
                  else return false;
                });
                if (dependentField.dependentField == "current_status") {
                  skipColumn = true;
                  break;
                }
                if (dependentField.dependentField == "issue_type") {
                  if (
                    !dependentField.dependentValue.includes(currentWorkflowName)
                  ) {
                    skipColumn = true;
                    break;
                  }
                }
                dependentFieldType = dependentField.dependentField;
                if (!dependentField.isDependent) {
                  break;
                }
              }
              if (skipColumn) {
                continue;
              }
            }
          }
          if (fieldItem.key) var keyString = fieldItem.key + "";
          keyString = '"' + keyString.replace(/"/g, '""') + '",';
          columns[i] = fieldItem.key;
          line += keyString;
          i++;
        }
      }
      csv += line + "\r\n";

      var downloadLink = document.createElement("a");
      downloadLink.href = "data:text/csv;charset=utf-8," + escape(csv);
      downloadLink.download = "sample.csv";

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    },
    onFileChange() {
      if (this.files.name) {
        this.fileName = this.files.name.split(" ").join("_").toLowerCase();
      } else {
        this.fileName = "";
      }
    },
    clickCsvUpload() {
      if (this.files.name) {
        this.showSpinner = true;
        var fileName = this.fileName;
        var activeFile = this.files;
        var getPreSignedURLBody = {
          file_name: fileName,
          file_type: activeFile.type,
          applicationId: this.$route.query.applicationId,
          viewId: this.csvDialogObject.viewId,
          token: getFromLocalStorage("token"),
          authType: getFromLocalStorage("authType"),
        };

        axios
          .post(
            process.env.VUE_APP_BACKEND_URL + "/getCSVPresignedPost",
            getPreSignedURLBody
          )
          .then((getPresignedURLSuccess) => {
            var fields = getPresignedURLSuccess.data.data.fields;
            var url = getPresignedURLSuccess.data.data.url;
            var post_body = {
              bucket: fields.bucket,
              ...fields,
              "Content-Type": activeFile.type,
              file: activeFile,
            };
            const formData = new FormData();
            for (const name in post_body) {
              formData.append(name, post_body[name]);
            }
            axios
              .post(url, formData)
              .then((uploadSuccess) => {
                var registerCsvBody = {
                  file: fileName,
                  viewId: this.csvDialogObject.viewId,
                  workFlowId: this.workFlowId,
                  applicationId: this.$route.query.applicationId,
                  token: getFromLocalStorage("token"),
                  authType: getFromLocalStorage("authType"),
                };
                axios
                  .post(
                    process.env.VUE_APP_BACKEND_URL + "/registerCsvUpload",
                    registerCsvBody
                  )
                  .then((registerCsvResponse) => {
                    this.showSpinner = false;
                    this.$emit("closeCsvDialog", false);
                    this.$toasted.success(
                      "Successfully added CSV for processing",
                      {
                        theme: "toasted-primary",
                        position: "top-right",
                        duration: 3000,
                      }
                    );
                  })
                  .catch((registerCsvError) => {
                    if (registerCsvError.response && registerCsvError.response.status && registerCsvError.response.status == 401) {
                      this.$root.$emit('update_session_value', true);
                    }
                      else{ 
                        this.errorMessageShow = true;                   
                        if(registerCsvError.response && registerCsvError.response.data && registerCsvError.response.data.errorObject){
                          this.errorMessageObject = {
                            errorMessageShow: this.errorMessageShow,
                            error_code: registerCsvError.response.data.errorObject.error_code,
                            error_uuid: registerCsvError.response.data.errorObject.error_uuid,
                          };
                      }
                      else{
                        this.errorMessageObject = {
                          errorMessageShow: this.errorMessageShow,
                          error_code: "",
                          error_uuid: "",
                        };
                      }
                    this.showMessageDialog = true;
                    this.showSpinner = false;            
                      }        
                  });
              })
              .catch((uploadFailure) => {
                if (
                  uploadFailure.response && 
                  uploadFailure.response.status &&
                  uploadFailure.response.status == 401
                ) {
                  this.$root.$emit('update_session_value', true);
                }
                else{
                  this.errorMessageShow = true;
                   if(uploadFailure.response && uploadFailure.response.data && uploadFailure.response.data.errorObject){
                    this.errorMessageObject = {
                      errorMessageShow: this.errorMessageShow,
                      error_code: uploadFailure.response.data.errorObject.error_code,
                      error_uuid: uploadFailure.response.data.errorObject.error_uuid,
                    };
                  }
                  else{
                    this.errorMessageObject = {
                      errorMessageShow: this.errorMessageShow,
                      error_code: "",
                      error_uuid: "",
                    };
                  }
                  this.showSpinner = false;
                  this.showMessageDialog = true;
                }
              });
          })
          .catch((getPresignedURLError) => {
            if (
              getPresignedURLError.response && 
              getPresignedURLError.response.status &&
              getPresignedURLError.response.status == 401
            ) {
              this.$root.$emit('update_session_value', true);
            }
            else{
              this.errorMessageShow = true;
               if(getPresignedURLError.response && getPresignedURLError.response.data && getPresignedURLError.response.data.errorObject){
              this.errorMessageObject = {
                errorMessageShow: this.errorMessageShow,
                error_code: getPresignedURLError.response.data.errorObject.error_code,
                error_uuid: getPresignedURLError.response.data.errorObject.error_uuid,
              };
            }
            else{
              this.errorMessageObject = {
                errorMessageShow: this.errorMessageShow,
                error_code: "",
                error_uuid: "",
              };
            }
            this.showSpinner = false;
            this.showMessageDialog = true;
            }
          });
      }
    },
    closeMessageDialog(emitObject) {
      var closeDialogFlag = false;
      if(emitObject && emitObject.errorDisplayMessage){
        closeDialogFlag = emitObject.errorDisplayMessage
      }
      this.showMessageDialog = emitObject.flag;
      this.$emit("closeDialog", closeDialogFlag);
    },
    selectWorkflow() {
    },
  },
};
</script>

<style scoped>
input[type="text"]::hint,
input[type="password"]::hint {
  color: #ffffff !important;
  opacity: 1;
}

.flexColumn {
  display: flex;
  flex-direction: column;
}

.input_field_div {
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.375rem;
  letter-spacing: 1px;
  color: #4f4f4f;
}

.scroll-2::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
}

.scroll-2::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: 0;
  background-color: rgb(42, 64, 154);
}

.input_field_div {
  /* width: 600px; */
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.375rem;
  letter-spacing: 1px;
  color: #4f4f4f;
  /* border: 1px solid;
  padding: 8px;
  margin-top: auto;
  margin-bottom: auto; */
}
</style>