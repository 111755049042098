<template>
	<div style="width: 100%; padding: 0px; box-sizing: border-box;">
	  <v-list-item
		v-for="(file, index) in files" 
		:key="index"
	  >
	  <v-list-item-content>
		<div style="display: flex; flex-direction: row; justify-content: space-between; align-items: center;">
		<div
			class="hover-container"
			@mouseenter="hoveredIndex = index"
			@mouseleave="hoveredIndex = null"
			@click="viewAndDownloadFile(file, true)"
		  >
			<img
			  :src="hoveredIndex === index ? activeImage : unactiveImage"
			  style="height: 16px;"
			  class="file-icon"
			/>
			<span class="file-name">
			  {{ formatFileName(file) }}
			</span>
		  </div>
		  <v-btn
			color="#2A409A"
			style="color: white; font-size: 12px;"
			@click="viewAndDownloadFile(file, false)"
		  >
			<v-icon left>mdi-download</v-icon>
			Download
		  </v-btn>
		</div>
		</v-list-item-content>
	  </v-list-item>
	</div>
  </template>
  
  
  
  <script>
  import axios from "axios";
  import { getFromLocalStorage } from "../store/localStorageInterface";
  import { formatFileNameMixin } from "../mixins/formatFileName.js";
  import MessageDialog from "./MessageDialog.vue";
  export default {
	props: ["multiFileArray", "workflow_id", "fieldDetails", "fileFieldKey"],
	components: {
	  MessageDialog
	},
	mixins: [formatFileNameMixin],
	data() {
	  return {
		files: [],
		workflowId: "",
		showMessageDialog: false,
		errorMessageShow: false,
		errorMessageObject: {},
		okMessageObject: {},
		hoveredIndex: null,
		unactiveImage: require('@/assets/unactiveHyperlink.svg'),
      	activeImage: require('@/assets/activeHyperlink.svg'),
	  };
	},
	methods: {
	  formatFileName(file) {
		var folderUrl = file.split("/").slice(-1);
		var [first, ...rest] = folderUrl[0].split("_");
		var fileName = rest.join("_");
		if (
		  this.$props.fieldDetails["AdditionalFieldInformation"][
			this.$props.fileFieldKey
		  ] &&
		  this.$props.fieldDetails["AdditionalFieldInformation"][
			this.$props.fileFieldKey
		  ][folderUrl]
		) {
		  return this.fileNameFormat(
			this.$props.fieldDetails["AdditionalFieldInformation"][
			  this.$props.fileFieldKey
			][folderUrl]
		  );
		}
		else if (fileName) {
		  return this.fileNameFormat(fileName);
		} 
	  },
	  closeMessageDialog(emitObject) {
		var closeDialogFlag = false;
		if (emitObject && emitObject.errorDisplayMessage) {
		  closeDialogFlag = emitObject.errorDisplayMessage;
		}
		this.showMessageDialog = emitObject.flag;
		this.$emit("closeDialog", closeDialogFlag);
	  },
	  viewAndDownloadFile(file_name, isView) {
		var read_body = {
		  token: getFromLocalStorage("token"),
		  authType: getFromLocalStorage("authType"),
		  applicationId: this.$route.query.applicationId,
		  userId: this.$route.query.userId,
		  workflow_id: this.workflowId,
		  file_name: file_name
		};
		axios
		  .post(
			process.env.VUE_APP_BACKEND_URL + "/getPresignedURLRead",
			read_body
		  )
		  .then((readSuccess) => {
			if(isView){
				window.open(readSuccess.data.data, "_blank")
			}
			else{
				axios.get(readSuccess.data.data, {
					responseType: "blob",
				}).then((response) => {
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const a = document.createElement("a");
				a.href = url;
				a.download = this.formatFileName(file_name); 
				document.body.appendChild(a);
				a.click();
				a.remove();
				window.URL.revokeObjectURL(url);
				})
			}
		  })
		  .catch((readError) => {
			if (
			  readError.response &&
			  readError.response.status &&
			  readError.response.status == 401
			) {
			  this.$root.$emit("update_session_value", true);
			} else {
			  this.errorMessageShow = true;
			  if (
				readError.response &&
				readError.response.data &&
				readError.response.data.errorObject
			  ) {
				this.errorMessageObject = {
				  errorMessageShow: this.errorMessageShow,
				  error_code: readError.response.data.errorObject.error_code,
				  error_uuid: readError.response.data.errorObject.error_uuid
				};
			  } else {
				this.errorMessageObject = {
				  errorMessageShow: this.errorMessageShow,
				  error_code: "",
				  error_uuid: ""
				};
			  }
			  this.showMessageDialog = true;
			}
		  });
	  }
	},
	mounted(){
	this.files = this.$props.fieldDetails[this.$props.fileFieldKey];
	this.workflowId = this.$props.workflow_id;
	},
  };
  </script>

  
  <style>
  .hover-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	cursor: pointer;
	overflow: none;
  }
  
  .file-icon {
	padding-left: 0px;
	padding-right: 2px;
	margin: 0px;
  }
  
  .file-name {
	text-decoration: none;
	color: inherit;
  }
  
  .hover-container:hover .file-name {
	text-decoration: underline;
	color: #3553B8;
  }
  
  .hover-container:hover .file-icon {
	filter: brightness(0.8);
  }
  </style>
  
  