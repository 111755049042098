<template>
  <v-card style="box-shadow: none">
    <div
      style="
        display: flex;
        justify-content: space-between;
        padding: 0px 10px 0px 7px;
        flex-direction: column;
        position: relative;
        width: 100%;
      "
    >
      <div
        style="
          width: 15px;
          height: 15px;
          border-radius: 50%;
          position: absolute;
          padding-left: 15px;
          left: 0px;
          background: rgb(42, 64, 154);
          margin-top: 20px;
        "
      ></div>
      <div
        style="
          display: flex;
          justify-content: space-between;
          padding: 0px 0px 20px 10px;
          flex-direction: row;
          width: 100%;
          border-left: 1px solid #808080d6;
        "
      >
        <div
          style="
            display: flex;
            width: 60%;
            justify-content: space-between;
            flex-direction: column;
          "
        >
          <div
            v-if="item.status"
            style="
              display: flex;
              width: 60%;
              justify-content: flex-start;
              align-items: center;
              padding: 5px;
            "
          >
            <div
              v-if="item.status.prevStatus"
              style="display: flex; align-items: center"
            >
              <div class="my-2">
                <v-chip
                  label
                  :color="stageColor[item.status.prevStatus] || '#616161'"
                  style="
                    font-size: 1rem;
                    color: white;
                    padding: 12px;
                    height: 25px;
                  "
                >
                  {{ item.status.prevStatus }}
                </v-chip>
              </div>
              &nbsp;&nbsp;
              <img
                src="../assets/arrowRight.svg"
                alt="Arrow"
              />
              &nbsp;&nbsp;
            </div>
            <div :class="item.status.prevStatus?'mx-6 my-2':'my-2'">
              <v-chip
                label
                :color="stageColor[item.status.currStatus] || '#616161'"
                style="font-size: 1rem; color: white; padding: 12px; height: 25px"
              >
                {{ item.status.currStatus }}
              </v-chip>
            </div>
          </div>

          <div
            style="
              display: flex;
              justify-content: space-between;
              flex-direction: column;
              width: 100%;
            "
          >
            <div class="fileText pb-2" v-if="comment">
              <label style="color: #828282">Comments : </label>
              <label style="color: #333333">{{ comment }}</label>
            </div>
            <div class="fileText" v-if="assigneeText">
              <label style="color: #828282">Assigned To : </label>
              {{ assigneeText }}
            </div>
            <div class="fileText" v-for="item in commentText" :key="item">
              <label
                v-if="
                  typeof item == 'object' && item.hasOwnProperty('field_name')
                "
                style="color: #828282"
                >Attachments :
              </label>
              <label
                v-if="
                  typeof item == 'object' &&
                  item.hasOwnProperty('checklist_field_name')
                "
                style="color: #828282"
                >CheckList Items :
              </label>
              <label
                v-if="
                  typeof item == 'object' &&
                  item.hasOwnProperty('multifile_field_name')
                "
                style="color: #828282"
                >Multi File Attachments :
              </label>
              <v-list-item>
                <table>
                  <ul>
                    <li
                      class="d-flex flex-row"
                      v-if="
                        typeof item == 'object' &&
                        item.hasOwnProperty('field_name') &&
                        item.hasOwnProperty('oldFilePath') &&
                        item.oldFilePath == ''
                      "
                    >
                      <div class="fileText" style="color: black">
                        <v-icon color="#0054A6"
                          >mdi-file-document-outline</v-icon
                        >
                        The File
                        <span
                          @click="readFile(item, item.newFilePath)"
                          class="fileText"
                          style="color: #0054a6; cursor: pointer"
                          >{{  getFileDisplayName(item.newFilePath, item.newFileName) }}</span
                        >
                        <span class="fileText"
                          ><p style="color: black"> has been uploaded to {{ item.field_name }}</p></span
                        >
                      </div>
                    </li>
                    <li
                      class="d-flex flex-row"
                      v-else-if="
                        typeof item == 'object' &&
                        item.hasOwnProperty('field_name') &&
                        item.hasOwnProperty('newFilePath') &&
                        item.newFilePath !== '' &&
                        !item.hasOwnProperty('oldFilePath')
                      "
                    >
                      <div class="fileText" style="color: black">
                        <v-icon color="#0054A6"
                          >mdi-file-document-outline</v-icon
                        >
                        The File
                        <span
                          @click="readFile(item, item.newFilePath)"
                          class="fileText"
                          style="color: #0054a6; cursor: pointer"
                          >{{  getFileDisplayName(item.newFilePath, item.newFileName) }}</span
                        >
                        <span class="fileText"><p style="color: black"> has been uploaded to {{ item.field_name }}</p></span>
                      </div>
                    </li>
                    <li
                      class="d-flex flex-row"
                      v-else-if="
                        typeof item == 'object' &&
                        item.hasOwnProperty('field_name') &&
                        item.hasOwnProperty('urlLink')
                      "
                    >
                      <div class="fileText" style="color: black">
                        <span
                          @click="readFile(item, item.field_name)"
                          class="fileText"
                          style="color: black"
                        >
                          {{ item.field_name }}
                        </span>
                        <span
                          class="fileText"
                          style="cursor: pointer"
                          @click="readFile(item, item.urlLink)"
                          ><p style="color: black">set to</p>
                          <a :href="item.urlLink" target="_blank">{{
                            item.urlLink
                          }}</a></span
                        >
                      </div>
                    </li>
                    <li
                      class="d-flex flex-row"
                      v-else-if="
                        typeof item == 'object' &&
                        item.hasOwnProperty('field_name') &&
                        item.hasOwnProperty('oldUrlLink') &&
                        item.hasOwnProperty('newUrlLink')
                      "
                    >
                      <div class="fileText" style="color: black">
                        <span
                          @click="readFile(item, item.field_name)"
                          class="fileText"
                          style="color: black"
                        >
                          {{ item.field_name }}
                        </span>
                        <span
                          class="fileText"
                          style="cursor: pointer"
                          @click="readFile(item, item.urlLink)"
                        >
                          <a :href="item.oldUrlLink" target="_blank">{{
                            item.oldUrlLink
                          }}</a>
                          <p style="color: black">to</p>
                          <a :href="item.newUrlLink" target="_blank">{{
                            item.newUrlLink
                          }}</a></span
                        >
                      </div>
                    </li>
                    <li
                      class="d-flex flex-row fileText"
                      v-else-if="
                        typeof item == 'object' &&
                        item.hasOwnProperty('field_name') &&
                        item.hasOwnProperty('oldFilePath') &&
                        item.oldFilePath != '' &&
                        item.newFilePath != ''
                      "
                    >
                      <div
                        class="fileText mr-3"
                        style="color: black"
                        @click="readFile(item, item.oldFilePath)"
                      >
                        <v-icon color="#0054A6"
                          >mdi-file-document-outline</v-icon
                        >
                        The File has been changed from
                        <span
                          class="fileText"
                          style="color: #0054a6; cursor: pointer"
                          >{{ getFileDisplayName(item.oldFilePath, item.oldFileName) + " " }}</span
                        >
                        <span
                          class="fileText"
                          style="cursor: pointer"
                          @click="readFile(item, item.newFilePath)"
                          ><p style="color: black">to</p>
                          {{  getFileDisplayName(item.newFilePath, item.newFileName) }}</span
                        >
                        in {{ item.field_name }}
                      </div>
                    </li>
                    <li
                      class="d-flex flex-row fileText"
                      v-else-if="
                        typeof item == 'object' &&
                        (item.hasOwnProperty('newCheckListItems') ||
                          item.hasOwnProperty('oldCheckListItems'))
                      "
                    >
                      <div style="display: flex; flex-direction: column">
                        <span style="display: flex">
                          <h4
                            style="
                              color: black;
                              font-size: 16px;
                              font-weight: 500;
                            "
                          >
                            {{ item.checklist_field_name }}
                            <p v-if="item.hasOwnProperty('oldCheckListItems')">
                              changed from
                            </p>
                            <p
                              v-else-if="
                                item.hasOwnProperty('newCheckListItems')
                              "
                            >
                              Set to
                            </p>
                          </h4>
                        </span>
                        <span
                          v-if="oldchecked.checked"
                          style="display: flex; flex-wrap: wrap"
                          class="pl-5"
                          v-for="oldchecked in item.oldCheckListItems"
                        >
                          <v-icon style="color: green"
                            >mdi-checkbox-marked</v-icon
                          >
                          <h4 class="items">{{ oldchecked.display.en }}</h4>
                        </span>
                        <span
                          v-if="!oldUnChecked.checked"
                          style="display: flex; flex-wrap: wrap"
                          class="pl-5"
                          v-for="oldUnChecked in item.oldCheckListItems"
                        >
                          <v-icon style="color: red"
                            >mdi-checkbox-blank-outline</v-icon
                          >
                          <h4 class="items">{{ oldUnChecked.display.en }}</h4>
                        </span>
                        <v-icon
                          style="color: black; font-size: 30px"
                          class="mt-2 mb-2"
                          >mdi-arrow-down-thick</v-icon
                        >
                        <span
                          v-if="newchecked.checked"
                          style="display: flex; flex-wrap: wrap"
                          class="pl-5"
                          v-for="newchecked in item.newCheckListItems"
                        >
                          <v-icon style="color: green"
                            >mdi-checkbox-marked</v-icon
                          >
                          <h4 class="items">{{ newchecked.display.en }}</h4>
                        </span>
                        <span
                          v-if="!newUnChecked.checked"
                          style="display: flex; flex-wrap: wrap"
                          class="pl-5"
                          v-for="newUnChecked in item.newCheckListItems"
                        >
                          <v-icon style="color: red"
                            >mdi-checkbox-blank-outline</v-icon
                          >
                          <h4 class="items">{{ newUnChecked.display.en }}</h4>
                        </span>
                      </div>
                    </li>
                    <li
                      v-else-if="
                        typeof item == 'object' &&
                        item.hasOwnProperty('oldNumberValue') &&
                        item.hasOwnProperty('newNumberValue') &&
                        item.oldNumberValue != '' &&
                        item.number_field_name
                      "
                    >
                      <div
                        class="d-flex flex-row fileText"
                        style="white-space: pre-wrap; color: black"
                      >
                        <span
                          >{{ item.number_field_name }} has changed from
                          <span style="color: #0054a6">
                            {{ numberFormatter(item.oldNumberValue, item.key) }}
                          </span>
                          to
                          <span style="color: #0054a6">{{
                            numberFormatter(item.newNumberValue, item.key)
                          }}</span></span
                        >
                      </div>
                    </li>
                    <li
                      v-else-if="
                        typeof item == 'object' &&
                        item.hasOwnProperty('oldBreachValue') &&
                        item.hasOwnProperty('newBreachValue') &&
                        item.oldBreachValue != ''
                      "
                    >
                      <div
                        class="d-flex flex-row fileText"
                        style="white-space: pre-wrap; color: black"
                      >
                        <span
                          >{{ item.field_name }} has changed from
                          <span style="color: #0054a6">
                            {{
                              formatBreachTime(
                                item.field_key,
                                item.oldBreachValue
                              )
                            }}
                          </span>
                          to
                          <span style="color: #0054a6">{{
                            formatBreachTime(
                              item.field_key,
                              item.newBreachValue
                            )
                          }}</span></span
                        >
                      </div>
                    </li>
                    <li
                      v-else-if="
                        typeof item == 'object' &&
                        item.hasOwnProperty('newNumberValue') &&
                        item.number_field_name &&
                        (!item.oldNumberValue || item.oldNumberValue === '')
                      "
                    >
                      <div
                        class="d-flex flex-row fileText"
                        style="white-space: pre-wrap; color: black"
                      >
                        <span
                          >{{ item.number_field_name }} set to
                          <span style="color: #0054a6">{{
                            numberFormatter(item.newNumberValue, item.key)
                          }}</span></span
                        >
                      </div>
                    </li>
                    <li
                      v-else-if="
                        typeof item == 'object' &&
                        'oldDateTimeValue' in item &&
                        'newDateTimeValue' in item &&
                        item.oldDateTimeValue != ''
                      "
                    >
                      <div
                        class="d-flex flex-row fileText"
                        style="white-space: pre-wrap; color: black"
                      >
                        <span
                          >{{ item.datetime_field_name }} has changed from
                          <span style="color: #0054a6">
                            {{
                              datetimeFormatter(
                                item.oldDateTimeValue,
                                item.timezone
                              )
                            }}
                          </span>
                          to
                          <span style="color: #0054a6">{{
                            datetimeFormatter(
                              item.newDateTimeValue,
                              item.timezone
                            )
                          }}</span></span
                        >
                      </div>
                    </li>
                    <li
                      v-else-if="
                        typeof item == 'object' && 'newDateTimeValue' in item
                      "
                    >
                      <div
                        class="d-flex flex-row fileText"
                        style="white-space: pre-wrap; color: black"
                      >
                        <span
                          >{{ item.datetime_field_name }} set to
                          <span style="color: #0054a6">{{
                            datetimeFormatter(
                              item.newDateTimeValue,
                              item.timezone
                            )
                          }}</span></span
                        >
                      </div>
                    </li>
                    <li
                      v-else-if="
                        typeof item == 'object' &&
                        'oldMultiselectValue' in item &&
                        'newMultiselectValue' in item &&
                        item.oldMultiselectValue != ''
                      "
                    >
                      <div
                        class="d-flex flex-row fileText"
                        style="white-space: pre-wrap; color: black"
                      >
                        <span
                          >{{ item.multiselectFieldName }} has changed from
                          <span style="color: #0054a6">
                            {{ item.oldMultiselectValue }}
                          </span>
                          to
                          <span style="color: #0054a6">{{
                            item.newMultiselectValue
                          }}</span></span
                        >
                      </div>
                    </li>
                    <li
                      v-else-if="
                        typeof item == 'object' && 'newMultiselectValue' in item
                      "
                    >
                      <div
                        class="d-flex flex-row fileText"
                        style="white-space: pre-wrap; color: black"
                      >
                        <span
                          >{{ item.multiselectFieldName }} set to
                          <span style="color: #0054a6">{{
                            item.newMultiselectValue
                          }}</span></span
                        >
                      </div>
                    </li>
                    <li
                      v-else-if="
                        typeof item == 'object' &&
                        (item.hasOwnProperty('addedRows') ||
                          item.hasOwnProperty('updatedRows') ||
                          item.hasOwnProperty('deletedRows'))
                      "
                      align="left"
                    >
                      <div>{{ item.table_field_name }} has been changed</div>
                      <div>
                        <ul>
                          <li
                            v-if="
                              typeof item == 'object' &&
                              item.hasOwnProperty('addedRows') &&
                              item['addedRows'].length > 0
                            "
                          >
                            <div>
                              {{
                                getUpdatedRowMessage(
                                  item.key,
                                  item["addedRows"],
                                  true,
                                  false
                                )
                              }}
                            </div>
                          </li>
                          <li
                            v-if="
                              typeof item == 'object' &&
                              item.hasOwnProperty('addedRows') &&
                              item['addedRows'].length > 0
                            "
                          >
                            <div style="width: 500px">
                              <TableComponentVue
                                style="margin-top: 5%"
                                :mode="tableMode"
                                :isAdded="true"
                                :dataItems="item['addedRows']"
                                :tableConfiguration="getTableConfiguration(item.key)"
                                :showFooter="true"
                              ></TableComponentVue>
                            </div>
                          </li>
                          <li
                            v-if="
                              typeof item == 'object' &&
                              item.hasOwnProperty('deletedRows') &&
                              item['deletedRows'].length > 0
                            "
                          >
                            <div>{{ getDeleteRowMessage(item["deletedRows"]) }}</div>
                          </li>
                          <li
                            v-if="
                              typeof item == 'object' &&
                              item.hasOwnProperty('deletedRows') &&
                              item['deletedRows'].length > 0
                            "
                          >
                            <div style="width: 500px">
                              <TableComponentVue
                                style="margin-top: 5%"
                                :mode="tableMode"
                                :isDelete="true"
                                :dataItems="item['deletedRows']"
                                :tableConfiguration="getTableConfiguration(item.key)"
                                :showFooter="true"
                              ></TableComponentVue>
                            </div>
                          </li>
                          <li
                            v-if="
                              typeof item == 'object' &&
                              item.hasOwnProperty('updatedRows') &&
                              item['updatedRows'].length > 0
                            "
                          >
                            <div>
                              {{
                                getupdatedOldRowsMessage(
                                  item.key,
                                  item["updatedRows"],
                                  false,
                                  true,
                                  true
                                )
                              }}
                            </div>
                          </li>
                          <li
                            v-if="
                              typeof item == 'object' &&
                              item.hasOwnProperty('updatedRows') &&
                              item['updatedRows'].length > 0
                            "
                          >
                            <div style="width: 500px">
                              <TableComponentVue
                                style="margin-top: 5%"
                                :mode="tableMode"
                                :isUpdateOld="true"
                                :dataItems="item['updatedRows']"
                                :isOldValue="true"
                                :tableConfiguration="getTableConfiguration(item.key)"
                                :showFooter="true"
                              ></TableComponentVue>
                            </div>
                          </li>
                          <li
                            v-if="
                              typeof item == 'object' &&
                              item.hasOwnProperty('updatedRows') &&
                              item['updatedRows'].length > 0
                            "
                          >
                            <div>
                              {{
                                getUpdatedRowMessage(
                                  item.key,
                                  item["updatedRows"],
                                  false,
                                  true
                                )
                              }}
                            </div>
                          </li>
                          <li
                            v-if="
                              typeof item == 'object' &&
                              item.hasOwnProperty('updatedRows') &&
                              item['updatedRows'].length > 0
                            "
                          >
                            <div style="width: 500px">
                              <TableComponentVue
                                style="margin-top: 5%"
                                :mode="tableMode"
                                :isUpdateNew="true"
                                :isOldValue="false"
                                :dataItems="item['updatedRows']"
                                :tableConfiguration="getTableConfiguration(item.key)"
                                :showFooter="true"
                              ></TableComponentVue>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li
                      v-else-if="
                        typeof item == 'object' &&
                        item.hasOwnProperty('oldTableValue') &&
                        item.hasOwnProperty('newTableValue') &&
                        item.oldTableValue != ''
                      "
                      align="left"
                    >
                      <div>
                        {{ item.table_field_name }} has changed from
                        <div style="width: 500px">
                          <TableComponentVue
                            style="margin-top: 5%"
                            :mode="tableMode"
                            :dataItems="item.oldTableValue"
                            :tableConfiguration="
                              getTableConfiguration(item.key)
                            "
                            :showFooter="true"
                          ></TableComponentVue>
                        </div>
                        <v-icon
                          style="
                            color: black;
                            font-size: 30px;
                            margin-left: 10%;
                          "
                          >mdi-arrow-down-thick</v-icon
                        >
                        <div style="width: 500px">
                          <TableComponentVue
                            style="margin-top: 5%"
                            :mode="tableMode"
                            :oldItems="item.oldTableValue"
                            :dataItems="item.newTableValue"
                            :tableConfiguration="
                              getTableConfiguration(item.key)
                            "
                            :showFooter="true"
                            :key="item.newTableValue"
                          ></TableComponentVue>
                        </div>
                      </div>
                    </li>
                    <li
                      v-else-if="
                        typeof item == 'object' &&
                        item.hasOwnProperty('newTableValue') &&
                        item.oldTableValue == ''
                      "
                    >
                      <div>{{ item.table_field_name }} set to</div>
                      <div style="width: 500px">
                        <TableComponentVue
                          style="margin-top: 5%"
                          :mode="tableMode"
                          :dataItems="item.newTableValue"
                          :tableConfiguration="getTableConfiguration(item.key)"
                          :showFooter="true"
                          :key="item.newTableValue"
                        ></TableComponentVue>
                      </div>
                    </li>
                    <li
                      class="d-flex flex-row fileText"
                      v-else-if="
                        typeof item == 'object' &&
                        (item.hasOwnProperty('removedFiles') ||
                          item.hasOwnProperty('addedFiles')) &&
                        item.hasOwnProperty('multifile_field_name')
                      "
                    >
                      <div>
                        <div
                          v-if="
                            item.hasOwnProperty('removedFiles') &&
                            item.removedFiles.length > 0
                          "
                          class="fileText"
                          style="color: black"
                        >
                          <li>
                            Files Removed from {{ item.multifile_field_name }} :
                            <div v-for="file of item.removedFiles">
                              <ul>
                                <v-icon color="#0054A6"
                                  >mdi-file-document-outline</v-icon
                                >
                                <span
                                  class="fileText"
                                  style="cursor: pointer;  text-decoration: line-through; text-decoration-color: grey;"
                                  @click="readFile(item, file, item.filePaths)"
                                >
                                  {{ formatFileName(file) }}</span
                                >
                              </ul>
                            </div>
                          </li>
                        </div>
                        <div
                          v-if="
                            item.hasOwnProperty('addedFiles') &&
                            item.addedFiles.length > 0
                          "
                          class="fileText"
                          style="color: black"
                        >
                          <li>
                            Files added to {{ item.multifile_field_name }} :
                            <div v-for="file of item.addedFiles">
                              <ul>
                                <v-icon color="#0054A6"
                                  >mdi-file-document-outline</v-icon
                                >
                                <span
                                  class="fileText"
                                  style="cursor: pointer"
                                  @click="readFile(item, file, item.filePaths)"
                                >
                                  {{ formatFileName(file) }}</span
                                >
                              </ul>
                            </div>
                          </li>
                        </div>
                      </div>
                    </li>
                    <li
                      v-else-if="
                        typeof item == 'object' &&
                        item.hasOwnProperty('newBreachValue') &&
                        item.newBreachValue != item.oldBreachValue
                      "
                    >
                      <div class="fileText" style="color: black">
                        SLA breached on
                        {{
                          formatBreachTime(item.field_key, item.newBreachValue)
                        }}
                      </div>
                    </li>
                    <li v-else class="fileText" style="color: black">
                      {{ item }}
                    </li>
                  </ul>
                </table>
              </v-list-item>
            </div>
            <div v-if="workFlowName">Issue Type : {{ workFlowName }}</div>
          </div>
        </div>
        <div style="text-align: right; width: 40%">
          <h4 style="display: flex; flex-direction: row-reverse">
            <p style="padding-top: 15px; padding-left: 20px; font-size: 1.3rem">
              {{ commentUserId }}
            </p>
            <img src="../assets/userIcon.svg" />
          </h4>
          <p>{{ makerChecker }}</p>
          <br />
          <div style="display: flex; flex-direction: column">
            <label style="color: #828282">{{ commentroleGroup }}</label>
            <label style="color: #828282">{{ commentDateTime }}</label>
            <label style="color: #828282">{{ momentDate }}</label>
          </div>
          <!-- <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <label  style="color:#828282" v-bind="attrs" v-on="on">{{ momentDate }}</label>
            </template>
            <span>{{ commentDateTime }}</span>
          </v-tooltip> -->
        </div>
      </div>
      <v-divider></v-divider>
    </div>
  </v-card>
</template>

<script>
import { arrowRight } from "../assets/arrowRight.svg";
import { userIcon } from "../assets/userIcon.svg";
import TableComponentVue from "./TableComponent.vue";
import moment from "moment";
import { getFromLocalStorage } from "../store/localStorageInterface";
import axios from "axios";
export default {
  props: ["item", "dataItem", "screenDefinition", "stageColor"],
  components: {
    arrowRight,
    userIcon,
    TableComponentVue,
  },
  data() {
    return {
      commentUserId: "",
      commentDateTime: "",
      commentUserMail: "",
      commentText: [],
      statusComment: {},
      assigneeText: "",
      comment: "",
      momentDate: "",
      commentroleGroup: "",
      workFlowName: "",
      fileContent: "",
      isFileChange: false,
      oldFileName: "",
      newFileName: "",
      date: "",
      makerChecker: "",
      tableMode: "VIEW",
      requiredRecord: {},
    };
  },
  methods: {
    seperateFileName(fileField) {
      if (
        fileField.length > 0 &&
        fileField[0].hasOwnProperty("oldFilePath") &&
        fileField[0].oldFilePath != ""
      ) {
        this.oldFileName = fileField[0].oldFilePath.split("/").pop();
        if(fileField[0].hasOwnProperty("oldFileName")) this.oldFileName = fileField[0].oldFileName;
      }
      if (
        fileField.length > 0 &&
        fileField[0].hasOwnProperty("newFilePath") &&
        fileField[0].newFilePath != ""
      ) {
        this.newFileName = fileField[0].newFilePath.split("/").pop();
      }
    },
    getFileDisplayName(filePath, fileName) {
			let fileDisplayName;
			if (
				filePath &&
				filePath != ""
			) {
				fileDisplayName = filePath.split("/").pop();
				if(fileName) fileDisplayName = fileName;
			}
			return fileDisplayName;
		},
    formatFileName(file) {
      return file.split("/").pop();
    },
    readFile(fileDetails, file_name, filePaths) {
      if(filePaths && filePaths[file_name]){
        file_name = filePaths[file_name];
      }
      var read_body = {
        token: getFromLocalStorage("token"),
        authType: getFromLocalStorage("authType"),
        applicationId: this.$route.query.applicationId,
        file_name: file_name,
      };
      axios
        .post(
          process.env.VUE_APP_BACKEND_URL + "/getPresignedURLRead",
          read_body
        )
        .then((readSuccess) => {
          window.open(readSuccess.data.data, "_blank");
        })
        .catch((readError) => {
          if (
            readError.response &&
            readError.response.status &&
            readError.response.status == 401
          ) {
            this.$root.$emit("update_session_value", true);
          } else {
            this.errorMessageShow = true;
            if (
              readError.response &&
              readError.response.data &&
              readError.response.data.errorObject
            ) {
              this.errorMessageObject = {
                errorMessageShow: this.errorMessageShow,
                error_code: readError.response.data.errorObject.error_code,
                error_uuid: readError.response.data.errorObject.error_uuid,
              };
            } else {
              this.errorMessageObject = {
                errorMessageShow: this.errorMessageShow,
                error_code: "",
                error_uuid: "",
              };
            }
            this.showMessageDialog = true;
          }
        });
    },
    numberFormatter(numberInput, key) {
      var input = numberInput;
      for (let field of this.screenDefinition.fieldDetails.fields) {
        if (field.key == key) {
          if (
            field.number_configuration &&
            field.number_configuration.numberSystem
          ) {
            if (field.number_configuration.numberSystem == "English") {
              const NumberFormatter = new Intl.NumberFormat(
                "en-US",
                this.formatConfig
              );
              input = NumberFormatter.format(input);
            } else if (field.number_configuration.numberSystem == "Arabic") {
              const NumberFormatter = new Intl.NumberFormat(
                "en-AR",
                this.formatConfig
              );
              input = NumberFormatter.format(input);
            }
          }
        }
      }
      return input;
    },
    datetimeFormatter(value, timezone) {
      let formatedDatetime = "";
      if (value) {
        formatedDatetime = moment
          .unix(Number(value))
          .tz(timezone)
          .format("DD-MM-YYYY HH:mm:ss");
      }
      return formatedDatetime;
    },
    getTableConfiguration(key) {
      for (let field of this.screenDefinition.fieldDetails.fields) {
        if (field.key == key) {
          if (field.table_configuration) {
            return field.table_configuration;
          }
        }
      }
      return null;
    },
    fetchMessage(indices, isAdd, isUpdate, isDelete, isOldRowFlag) {
      if (indices.length === 0) return "";
      indices.sort((a, b) => a - b);
      if (isUpdate) {
        if (indices.length == 1) {
          if (isOldRowFlag) {
            return `Row ${indices[0]} has been updated from`;
          }
          return `Row ${indices[0]} has been updated to`;
        } else if (indices.length == 2) {
          if (isOldRowFlag) {
            return `Rows ${indices[0]} and ${indices[1]} have been updated from`;
          }
          return `Rows ${indices[0]} and ${indices[1]} have been updated to`;
        } else {
          let indicesStr = indices.slice(0, indices.length - 1).join(", ");
          if (isOldRowFlag) {
            return `Rows ${indicesStr} and ${
              indices[length - 1]
            } have been updated from`;
          }
          return `Rows ${indicesStr} and ${
            indices[length - 1]
          } have been updated to`;
        }
      }
      // General case for 'add' and 'delete' actions
      let action = isAdd ? "added" : isDelete ? "deleted" : "";
      if (indices.length == 1) {
        return `Row ${indices[0]} has been ${action}`;
      } else if (indices.length == 2) {
        return `Rows ${indices[0]} and ${indices[1]} have been ${action}`;
      } else {
        let indicesStr = indices.slice(0, indices.length - 1).join(", ");
        return `Rows ${indicesStr} and ${
          indices[length - 1]
        } have been ${action}`;
      }
    },
    findMatchingIndex(requiredRecord, id) {
      if (requiredRecord.length > 0) {
        for (let index in requiredRecord) {
          if (requiredRecord[index]["id"] == id) {
            return index;
          }
        }
      }
      return -1;
    },
    getUpdatedRowMessage(fieldKey, tableRows, isAdd, isUpdate) {
      let indices = [];
      let message = "";
      for (let item in this.$props.dataItem) {
        if (item == fieldKey) {
          this.requiredRecord = this.$props.dataItem[item];
        }
      }
      for (let tableRow of tableRows) {
        let id = isUpdate ? tableRow["row"]["id"] : tableRow["id"];
        let index = this.findMatchingIndex(this.requiredRecord, id);
        if (index >= 0) {
          indices.push(Number(index) + 1);
        }
      }
      message = this.fetchMessage(indices, isAdd, isUpdate, false);
      return message;
    },
    getDeleteRowMessage(tableRows) {
      let indices = [];
      let message = "";
      for (let row of tableRows) {
        indices.push(row["serialNumber"]);
      }
      message = this.fetchMessage(indices, false, false, true);
      return message;
    },
    getupdatedOldRowsMessage(
      fieldKey,
      tableRows,
      isAdd,
      isUpdate,
      isOldRowFlag
    ) {
      let indices = [];
      let message = "";
      for (let item in this.$props.dataItem) {
        if (item == fieldKey) {
          this.requiredRecord = this.$props.dataItem[item];
        }
      }
      for (var tableRow of tableRows) {
        let id = isUpdate ? tableRow["row"]["id"] : tableRow["id"];
        let index = this.findMatchingIndex(this.requiredRecord, id);
        if (index >= 0) {
          indices.push(Number(index) + 1);
        }
      }
      message = this.fetchMessage(
        indices,
        isAdd,
        isUpdate,
        false,
        isOldRowFlag
      );
      return message;
    },
    formatBreachTime(fieldKey, fieldData) {
      let breachFormat = "llll";
      for (let field of this.screenDefinition.fieldDetails.fields) {
        if (
          field.key === fieldKey &&
          "breachTimeConfiguration" in field &&
          "format" in field["breachTimeConfiguration"]
        ) {
          breachFormat = field["breachTimeConfiguration"]["format"];
          let showBreachTime = fieldData !== undefined && fieldData !== "";
          if (showBreachTime) {
            return moment(fieldData).format(breachFormat);
          } else {
            return "";
          }
        }
      }
    },
  },
  mounted() {
    this.commentUserMail = this.$props.item.userId;
    this.commentroleGroup = this.$props.item.userGroup;
    this.commentText = this.$props.item.text;
    this.makerChecker = this.$props.item.MakerChecker;
    if (this.commentText && this.commentText.length > 0) {
      this.isFileChange = true;
      this.fileContent = this.commentText.filter(function (field) {
        if (field.hasOwnProperty("newFilePath")) {
          return field;
        }
      });
      this.multifileContent = this.commentText.filter(function (field) {
        if (
          field.hasOwnProperty("removedFiles") ||
          field.hasOwnProperty("addedFiles")
        ) {
          return field;
        }
      });
    }

    if (this.$props.item.userName === undefined) {
      this.commentUserId = this.$props.item.userId.split("@")[0];
    } else {
      this.commentUserId = this.$props.item.userName;
    }
    this.commentDateTime = this.$props.item.dateTime;
    this.momentDate = moment(this.$props.item.dateTime).fromNow();
    this.date = moment(this.commentDateTime).format("DD-MM-YYYY");
    this.statusComment = this.$props.item.status;
    this.assigneeText = this.$props.item["Assigned To"];
    this.workFlowName = this.$props.item["workFlowName"];
    this.comment = this.$props.item["Comments"];
    this.seperateFileName(this.fileContent);
  },
};
</script>

<style scoped>
table ul li {
  margin-bottom: 1px;
  padding: 0px;
}

.fileText {
  color: #0054a6;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  width: 40vw;
}
.fileText p {
  display: inline;
}
.items {
  color: black;
  font-size: 16px;
  font-weight: 400;
  padding-left: 10px;
}
</style>
