<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="400">
      <v-card>
        <v-card-title
          style="
            background-color: #ffffff;
            color: #2a409a;
            font-size: 20px;
            font-weight: 500;
          "
          >{{$t("Confirmation")}}
        </v-card-title>
        <v-divider />
        <v-card-text>
          <br />
          <div
            style="
              font-size: 16px;
              font-weight: 500;
              font-style: normal;
              color: #000000;
            "
          >
            The search operation is taking too long. Do you still want to continue?
          </div>
        </v-card-text>
        <v-divider />

        <v-card-actions style="display: flex; justify-content: right">
          <div style="margin-right: 30px">
            <v-btn
              outlined
              style="
                background-color: #fffff;
                color: rgb(42, 64, 154);
                border: 1px solid rgb(42, 64, 154);
                width: 90px;
              "
              @click="clickNo()"
              >{{$t("NO")}} </v-btn
            >
          </div>
          <div>
            <v-btn
              style="
                background-color: rgb(42, 64, 154);
                color: #ffffff;
                width: 90px;
              "
              @click="clickYes()"
              >{{$t("YES")}} </v-btn
            >
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ["showMoreItemsPopup"],
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    clickYes() {
      this.dialog = false;
      this.$emit("closeShowMorePopup", true);
    },
    clickNo() {
      this.dialog = false;
      this.$emit("closeShowMorePopup", false);
    },
  },
  mounted() {
    this.dialog = true;
  },
};
</script>