<template>
  <div>
    {{ displayUserName }}
    <div v-if="showMessageDialog">
      <MessageDialog
        :displayDialog="showMessageDialog"
        :messageObject="errorMessageShow ? errorMessageObject : okMessageObject"
        @closeMessageDialog="closeMessageDialog"
      ></MessageDialog>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import MessageDialog from "./MessageDialog.vue";
export default {
  props: ["assigneeId"],
  components: {
    MessageDialog,
  },
  data() {
    return {
      displayUserName: "",
      showMessageDialog: false,
      errorMessageShow: false,
      errorMessageObject: {},
      okMessageObject: {},
    };
  },
  mounted() {
    var options = {
      applicationId: this.$route.query.applicationId,
      displayUserId: this.assigneeId,
    };
    axios({
      method: "post",
      url: process.env.VUE_APP_BACKEND_URL + "/getDisplayName",
      data: options,
      headers: {
        "content-type": "application/json",
      },
    })
      .then((displayNameResponse) => {
        this.displayUserName = displayNameResponse.data.data;
      })
      .catch((displayNameError) => {
        if (
          displayNameError.response.status &&
          displayNameError.response.status == 401
        ) {
          this.$root.$emit('update_session_value', true);
        }
        this.showMessageDialog = true;
        this.errorMessageShow = true;
        this.errorMessageObject = {
          errorMessageShow: this.errorMessageShow,
          // errorMessage: displayNameError.response.data.message.message,
          error_code: displayNameError.response.data.errorObject.error_code,
          error_uuid: displayNameError.response.data.errorObject.error_uuid,
        };
      });
  },
  methods: {
    closeMessageDialog(flag) {
      this.showMessageDialog = flag;
      this.$emit("closeDialog", false);
    },
  },
};
</script>