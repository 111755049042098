<template>
  <div style="background-color: #f3f3f9; height: 100vh">
    <div :key="refreshSideBar">
      <SideBar
        :sidebarToggleFlag="sidebarToggleFlag"
        :navigationBarTitle="applicationName"
        :resourcesObject="resourcesObject"
        :userGroup="userGroup"
        @closeFIlterDrawer="closeFIlterDrawer"
        :lockSelection="lockSelection"
        @reloadAppScreen="reloadAppScreen"
        @no_of_applications="no_of_applications"
      ></SideBar>
    </div>
    <v-main>
      <div v-if="emptyScreenShow">
        <div v-if="showSpinner">
          <EmptyScreenApplication></EmptyScreenApplication>
        </div>
      </div>
      <v-container v-else>
        <div v-if="showSpinner" style="padding: 10px">
          <SpinnerComponent></SpinnerComponent>
        </div>
        <div v-else>
          <v-tabs v-model="tab" hide-slider>
            <v-tab
              v-for="(item, index) in tabList"
              :key="item.viewId+'_'+item.viewType"
              @click="loadData(item, index)"
              class="pa-4"
              :class="{ 'ml-auto': item.viewType == 'archive' }"
            >       
              {{ item.viewName[$i18n.locale] }}
                 <div v-if="index === masterSelectedTabDetail && recordItemCount.count && (item.viewType === 'workflow' || item.viewType === 'archive')" style="display: flex; flex-direction: row; align-items: center; justify-content: center;">
                     <h4>&nbsp;&nbsp;({{ recordItemCount.count }}<span v-if="recordItemCount.loadmore">+</span>)</h4>
                 </div>
                 <div v-if="index === masterSelectedTabDetail && masterRecordCounterData && item.viewType === 'master'" style="display: flex; flex-direction: row; align-items: center; justify-content: center;">
                     <h4>&nbsp;&nbsp;({{ masterRecordCounterData }}<span v-if="masterloadmore">+</span>)</h4>
                 </div>
            </v-tab>
          </v-tabs>
        </div>
        <div v-if="showMasterSpinner" style="margin: 15px">
          <v-overlay color="transparent">
            <SpinnerComponent></SpinnerComponent>
          </v-overlay>
        </div>
        <div v-if="showTable" :key="refreshListTable">
          <v-spacer />
          <div class="p-4" v-if="showAddButton" style="text-align: right">
            <v-btn
              depressed
              color="#2A409A"
              style="color: white"
              @click="clickAdd()"
            >
              {{ $t("AddNew") }}
            </v-btn>
          </div>
          <ListTable
            :screenDefinition="screenDefinition"
            :viewId="viewId"
            @refreshTable="refreshTable"
            @loadMoreList="loadMoreList"
          ></ListTable>
        </div>
        <div v-if="showWorkFlow" :key="refreshWorkflowTable">
          <WorkflowTable
            :applicationName="applicationName"
            :closeSidebarFilter="closeSidebarFilter"
            @toggleSideBarFlagData="toggleSideBarFlagData"
            :workFlowScreenDetails="workFlowScreenDetails"
            @recordItemCounter="recordItemCounter"
          ></WorkflowTable>
        </div>
        <div v-if="showUserList">
          <UserList />
        </div>
        <div v-if="showDialog">
          <Dialog
            :screenDefinition="screenDefinition"
            :dialogObject="dialogObject"
            @closeDialog="closeDialog"
          ></Dialog>
        </div>
        <div v-if="showMessageDialog">
          <MessageDialog
            :displayDialog="showMessageDialog"
            :messageObject="
              errorMessageShow ? errorMessageObject : okMessageObject
            "
            @closeMessageDialog="closeMessageDialog"
          ></MessageDialog>
        </div>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import axios from "axios";
import SideBar from "../components/SideBar.vue";
import ListTable from "../components/ListTable.vue";
import UserCard from "../components/UserCard";
import UserList from "../components/UserListComponent.vue";
import Dialog from "../components/Dialog.vue";
import WorkflowTable from "../components/WorkflowTable.vue";
import SpinnerComponent from "../components/SpinnerComponent.vue";
import MessageDialog from "../components/MessageDialog.vue";
import EmptyScreenApplication from "../components/EmptyScreenApplication.vue";
import {getFromLocalStorage, setToLocalStorage} from "../store/localStorageInterface"
export default {
  name: "App",
  components: {
    UserCard,
    SideBar,
    ListTable,
    Dialog,
    WorkflowTable,
    UserList,
    SpinnerComponent,
    MessageDialog,
    EmptyScreenApplication,
  },
  data() {
    return {
      sidebarToggleFlag: "",
      resourcesObject: {},
      sidebarToggleWithDrawer: "",
      applicationName: "",
      tabList: [],
      viewId: "",
      viewType: "",
      refreshSideBar: 0,
      applicationList: [],
      tab: null,
      dialogObject: {},
      stagesList: [],
      lockSelection: false,
      refreshWorkflowTable: 0,
      screenDefinition: "",
      tabItem: "",
      workFlowScreenDetails: {},
      showAddButton: false,
      showTable: false,
      showWorkFlow: false,
      showSpinner: true,
      showMasterSpinner: false,
      showUserList: false,
      showDialog: false,
      refreshListTable: 0,
      userGroup: "",
      showMessageDialog: false,
      errorMessageShow: false,
      errorMessageObject: {},
      okMessageObject: {},
      applicationsCount: 0,
      emptyScreenShow: false,
      closeSidebarFilter: "",
      recordItemCount:0,
      masterRecordCounterData:0,
      masterSelectedTabDetail:0,
      masterloadmore:false,
    };
  },
  methods: {
    toggleSideBarFlagData(flag) {
      this.sidebarToggleFlag = flag;
    },
    recordItemCounter(numberOfRecords){
      this.recordItemCount=numberOfRecords
    },
    toggleWithFilterDrawer(flagData) {
      this.sidebarToggleWithDrawer = flagData;
    },
    closeFIlterDrawer(flag) {
      this.closeSidebarFilter = flag;
    },
    no_of_applications(count) {
      this.applicationsCount = count;
      if (this.applicationsCount != 0) {
        this.emptyScreenShow = false;
      } else {
        this.emptyScreenShow = true;
      }
    },
    onEdit(user) {
      alert(`Editing ${user.name}`);
    },
    onDelete(user) {
      alert(`Deleting ${user.name}`);
    },
    clickAdd() {
      this.refreshNewButton++;
      this.dialogObject["display"] = true;
      this.dialogObject["title"] = "Add a Record";
      this.dialogObject["viewId"] = this.viewId;
      this.dialogObject["buttons"] = ["Save", "Cancel"];
      this.dialogObject["viewType"] = "master";
      this.showDialog = true;
    },
    closeDialog() {
      this.showDialog = false;
      this.loadData(this.tabItem);
    },
    refreshTable(flag) {
      if (flag) {
        this.loadData(this.tabItem);
      }
    },
    loadData(item, index) {
      this.recordItemCount.count = 0
      this.masterSelectedTabDetail = index
      this.masterRecordCounterData = 0;
      this.tabItem = item;
      this.viewId = item.viewId;
      this.viewType = item.viewType;
      if (["workflow", "archive"].includes(item.viewType)) {
        this.showWorkFlow = true;
        this.showUserList = false;
        this.showTable = false;
        this.workFlowScreenDetails["viewId"] = this.viewId;
        this.workFlowScreenDetails["viewType"] = this.viewType;
        this.refreshWorkflowTable++;
      } else if (item.viewType === "userList") {
        this.showSpinner = false;
        this.showUserList = true;
        this.showWorkFlow = false;
        this.showTable = false;
      } else {
        this.showUserList = false;
        this.showWorkFlow = false;
        this.showTable = false;
        this.showMasterSpinner = true;
        axios({
          method: "post",
          url: process.env.VUE_APP_BACKEND_URL + "/getScreenDefinition",
          data: {
            applicationId: this.$route.query.applicationId,
            viewId: item.viewId,
            token: getFromLocalStorage("token"),
            authType: getFromLocalStorage("authType"),
          },
          headers: {
            "content-type": "application/json",
          },
        })
          .then((getScreenDefinitionResponse) => {
            this.masterRecordCounterData = getScreenDefinitionResponse.data.data.length;
            this.screenDefinition = getScreenDefinitionResponse.data;
            if (this.screenDefinition.LastEvaluatedKey) {
              this.masterloadmore = true;
            } else {
              this.masterloadmore = false;
            }
            this.showAddButton = this.screenDefinition.action.includes("add");
            this.showSpinner = false;
            this.showTable = true;
            this.showSpinner = false;
            this.showMasterSpinner = false;
          })
          .catch((getScreenDefinitionError) => {
            this.showSpinner = false;
            this.showMasterSpinner = false;
            if (
              getScreenDefinitionError.response &&
              getScreenDefinitionError.response.status &&
              getScreenDefinitionError.response.status == 401
            ) {
              this.$root.$emit("update_session_value", true);
            } else {
              this.errorMessageShow = true;
              if (
                getScreenDefinitionError.response &&
                getScreenDefinitionError.response.data &&
                getScreenDefinitionError.response.data.errorObject
              ) {
                this.errorMessageObject = {
                  errorMessageShow: this.errorMessageShow,
                  error_code:
                    getScreenDefinitionError.response.data.errorObject
                      .error_code,
                  error_uuid:
                    getScreenDefinitionError.response.data.errorObject
                      .error_uuid,
                };
              } else {
                this.errorMessageObject = {
                  errorMessageShow: this.errorMessageShow,
                  error_code: "",
                  error_uuid: "",
                };
              }
              this.showMessageDialog = true;
            }
          });
      }
    },
    loadMoreList(lastEvaluatedKey) {
      axios({
        method: "post",
        url: process.env.VUE_APP_BACKEND_URL + "/getScreenDefinition",
        data: {
          applicationId: this.$route.query.applicationId,
          viewId: this.viewId,
          token: getFromLocalStorage("token"),
          authType: getFromLocalStorage("authType"),
          lastEvaluatedKey: lastEvaluatedKey,
        },
        headers: {
          "content-type": "application/json",
        },
      })
        .then((getScreenDefinitionResponse) => {
          this.screenDefinition.data = [
            ...this.screenDefinition.data,
            ...getScreenDefinitionResponse.data.data,
          ];
          if (getScreenDefinitionResponse.data.LastEvaluatedKey) {
            this.screenDefinition.LastEvaluatedKey = getScreenDefinitionResponse.data.LastEvaluatedKey;
            this.masterloadmore = true;
          } else {
            this.masterloadmore = false;
            delete this.screenDefinition.LastEvaluatedKey;
          }
          this.showAddButton = this.screenDefinition.action.includes("add");
          this.refreshListTable++;
          this.masterRecordCounterData = this.screenDefinition.data.length;
        })
        .catch((getScreenDefinitionError) => {
          if (
            getScreenDefinitionError.response &&
            getScreenDefinitionError.response.status &&
            getScreenDefinitionError.response.status == 401
          ) {
            this.$root.$emit("update_session_value", true);
          } else {
            this.errorMessageShow = true;
            if (
              getScreenDefinitionError.response &&
              getScreenDefinitionError.response.data &&
              getScreenDefinitionError.response.data.errorObject
            ) {
              this.errorMessageObject = {
                errorMessageShow: this.errorMessageShow,
                error_code:
                  getScreenDefinitionError.response.data.errorObject.error_code,
                error_uuid:
                  getScreenDefinitionError.response.data.errorObject.error_uuid,
              };
            } else {
              this.errorMessageObject = {
                errorMessageShow: this.errorMessageShow,
                error_code: "",
                error_uuid: "",
              };
            }
            this.showMessageDialog = true;
          }
        });
    },
    async reloadAppScreen() {
      this.recordItemCount.count = 0
      this.masterSelectedTabDetail = 0;
      this.showSpinner = true;
      this.showTable = false;
      this.showWorkFlow = false;
      this.showUserList = false;
      this.tab = null;
      this.lockSelection = true;
      this.resourcesObject = {};
      axios({
        method: "post",
        url: process.env.VUE_APP_BACKEND_URL + "/getApplicationDefinition",
        data: {
          applicationId: this.$route.query.applicationId,
          token: getFromLocalStorage("token"),
          authType: getFromLocalStorage("authType"),
        },
        headers: {
          "content-type": "application/json",
        },
      })
        .then(async (applicationDefinitionResponse) => {
          // getting a supported language and settolocalstorage for displaying additional error message in other language
          if (
            applicationDefinitionResponse.data &&
            applicationDefinitionResponse.data.message &&
            applicationDefinitionResponse.data.message.secondarySupportLanguage
          ) {
            setToLocalStorage('secondarySupportLanguage', { secondarySupportLanguage: applicationDefinitionResponse.data.message.secondarySupportLanguage, applicationId: this.$route.query.applicationId } );
          }
          if (
            applicationDefinitionResponse.data &&
            applicationDefinitionResponse.data.message &&
            applicationDefinitionResponse.data.message.resources
          ) {
            this.resourcesObject["applicationId"] = this.$route.query.applicationId;
            this.resourcesObject['resources'] = []
            var roleGroup = applicationDefinitionResponse.data.message.userGroup;
            applicationDefinitionResponse.data.message.resources.map((item) => {
              if(item.showForGroups == undefined  || (item.showForGroups != undefined && item.showForGroups.includes(roleGroup))){
                this.resourcesObject['resources'].push(item);
              }
            });            
            this.resourcesObject['resources'].map((fileItem, index) => {
              if (fileItem.thumbnail && fileItem.thumbnail != "") {
                var file_name = this.resourcesObject.applicationId + "/KnowledgeBaseResources/" + fileItem.thumbnail;
                var read_body = {
                  token: getFromLocalStorage("token"),
                  authType: getFromLocalStorage("authType"),
                  applicationId: this.resourcesObject.applicationId,
                  userId: this.$route.query.userId,
                  file_name: file_name,
                };
                axios
                  .post(
                    process.env.VUE_APP_BACKEND_URL + "/getPresignedURLRead",
                    read_body
                  )
                  .then((readSuccess) => {
                    this.resourcesObject.resources[index]["thumbnailUrl"] = readSuccess.data.data;
                    this.resourcesObject.resources[index]["isThumbnail"] = true;
                  })
                  .catch((readError) => {
                    if (
                      readError.response &&
                      readError.response.status &&
                      readError.response.status == 401
                    ) {
                      this.$root.$emit("update_session_value", true);
                    } else {
                      this.errorMessageShow = true;
                      if (
                        readError.response &&
                        readError.response.data &&
                        readError.response.data.errorObject
                      ) {
                        this.errorMessageObject = {
                          errorMessageShow: this.errorMessageShow,
                          error_code:
                            readError.response.data.errorObject.error_code,
                          error_uuid:
                            readError.response.data.errorObject.error_uuid,
                        };
                      } else {
                        this.errorMessageObject = {
                          errorMessageShow: this.errorMessageShow,
                          error_code: "",
                          error_uuid: "",
                        };
                      }
                      this.showMessageDialog = true;
                    }
                  });
              }
            });
          }
          setToLocalStorage(
            "userGroup",
            applicationDefinitionResponse.data.message.userGroup
          );
          this.userGroup = applicationDefinitionResponse.data.message.userGroup;
          this.applicationName =
            applicationDefinitionResponse.data.message.application_display_name;
          this.tabList =
            applicationDefinitionResponse.data.message.viewDefinitions;
          this.lockSelection = false;
          if (["workflow", "archive"].includes(this.tabList[0].viewType)) {
            this.showWorkFlow = true;
            this.showSpinner = false;
            this.workFlowScreenDetails["viewId"] = this.tabList[0].viewId;
            this.workFlowScreenDetails["viewType"] = this.tabList[0].viewType;
            this.viewId = this.tabList[0].viewId;
          } else if (this.tabList[0].viewType === "userList") {
            this.showSpinner = false;
            this.showUserList = true;
          } else if (this.tabList[0].viewType === "master") {
            this.showSpinner = true;
            axios({
              method: "post",
              url: process.env.VUE_APP_BACKEND_URL + "/getScreenDefinition",
              data: {
                applicationId: this.$route.query.applicationId,
                viewId: this.tabList[0].viewId,
                token: getFromLocalStorage("token"),
                authType: getFromLocalStorage("authType"),
              },
              headers: { "content-type": "application/json" },
            })
              .then((getScreenDefinitionResponse) => {
                this.screenDefinition = getScreenDefinitionResponse.data;
                this.showAddButton =
                  this.screenDefinition.action.includes("add");
                this.showTable = true;
                this.showSpinner = false;
              })
              .catch((getScreenDefinitionError) => {
                this.showSpinner = false;
                this.lockSelection = false;
                if (
                  getScreenDefinitionError.response &&
                  getScreenDefinitionError.response.status &&
                  getScreenDefinitionError.response.status == 401
                ) {
                  this.$root.$emit("update_session_value", true);
                } else {
                  this.errorMessageShow = true;
                  if (
                    getScreenDefinitionError.response &&
                    getScreenDefinitionError.response.data &&
                    getScreenDefinitionError.response.data.errorObject
                  ) {
                    this.errorMessageObject = {
                      errorMessageShow: this.errorMessageShow,
                      error_code:
                        getScreenDefinitionError.response.data.errorObject
                          .error_code,
                      error_uuid:
                        getScreenDefinitionError.response.data.errorObject
                          .error_uuid,
                    };
                  } else {
                    this.errorMessageObject = {
                      errorMessageShow: this.errorMessageShow,
                      error_code: "",
                      error_uuid: "",
                    };
                  }
                  this.showMessageDialog = true;
                }
              });
          }
        })
        .catch((applicationDefinitionError) => {
          this.showSpinner = false;
          this.lockSelection = false;
          if (
            applicationDefinitionError.response &&
            applicationDefinitionError.response.status &&
            applicationDefinitionError.response.status == 401
          ) {
            this.$root.$emit("update_session_value", true);
          } else {
            this.errorMessageShow = true;
            if (
              applicationDefinitionError.response &&
              applicationDefinitionError.response.data &&
              applicationDefinitionError.response.data.errorObject
            ) {
              this.errorMessageObject = {
                errorMessageShow: this.errorMessageShow,
                error_code:
                  applicationDefinitionError.response.data.errorObject
                    .error_code,
                error_uuid:
                  applicationDefinitionError.response.data.errorObject
                    .error_uuid,
              };
            } else {
              this.errorMessageObject = {
                errorMessageShow: this.errorMessageShow,
                error_code: "",
                error_uuid: "",
              };
            }
            this.showMessageDialog = true;
          }
        });
    },
    closeMessageDialog(emitObject) {
      var closeDialogFlag = false;
      if (emitObject && emitObject.errorDisplayMessage) {
        closeDialogFlag = emitObject.errorDisplayMessage;
      }
      this.showMessageDialog = emitObject.flag;
      this.$emit("closeDialog", closeDialogFlag);
    },
  },
  mounted() {
    axios({
      method: "post",
      url: process.env.VUE_APP_BACKEND_URL + "/getApplicationDefinition",
      data: {
        applicationId: this.$route.query.applicationId,
        token: getFromLocalStorage("token"),
        authType: getFromLocalStorage("authType"),
      },
      headers: {
        "content-type": "application/json",
      },
    })
      .then((applicationDefinitionResponse) => {
        // getting a supported language and settolocalstorage for displaying additional error message in other language
        if (
            applicationDefinitionResponse.data &&
            applicationDefinitionResponse.data.message &&
            applicationDefinitionResponse.data.message.secondarySupportLanguage
          ) {
            setToLocalStorage('secondarySupportLanguage', { secondarySupportLanguage: applicationDefinitionResponse.data.message.secondarySupportLanguage, applicationId: this.$route.query.applicationId } );
          }
        if (
          applicationDefinitionResponse.data &&
          applicationDefinitionResponse.data.message &&
          applicationDefinitionResponse.data.message.resources
        ) {
          this.resourcesObject["applicationId"] = this.$route.query.applicationId;
          this.resourcesObject["resources"] = applicationDefinitionResponse.data.message.resources;
          var roleGroup = applicationDefinitionResponse.data.message.userGroup;
          this.resourcesObject['resources'] = [];
          applicationDefinitionResponse.data.message.resources.map((item) => {
            if(item.showForGroups == undefined  || (item.showForGroups != undefined && item.showForGroups.includes(roleGroup))){
              this.resourcesObject['resources'].push(item);
            }
          });
          this.resourcesObject['resources'].map((fileItem, index) => {
            if (fileItem.thumbnail && fileItem.thumbnail != "") {
              var file_name = this.resourcesObject.applicationId + "/KnowledgeBaseResources/" + fileItem.thumbnail;
              var read_body = {
                token: getFromLocalStorage("token"),
                authType: getFromLocalStorage("authType"),
                applicationId: this.resourcesObject.applicationId,
                userId: this.$route.query.userId,
                file_name: file_name,
              };
              axios
                .post(
                  process.env.VUE_APP_BACKEND_URL + "/getPresignedURLRead",
                  read_body
                )
                .then((readSuccess) => {
                  this.resourcesObject.resources[index]["thumbnailUrl"] = readSuccess.data.data;
                  this.resourcesObject.resources[index]["isThumbnail"] = true;
                })
                .catch((readError) => {
                  if (
                    readError.response &&
                    readError.response.status &&
                    readError.response.status == 401
                  ) {
                    this.$root.$emit("update_session_value", true);
                  } else {
                    this.errorMessageShow = true;
                    if (
                      readError.response &&
                      readError.response.data &&
                      readError.response.data.errorObject
                    ) {
                      this.errorMessageObject = {
                        errorMessageShow: this.errorMessageShow,
                        error_code:
                          readError.response.data.errorObject.error_code,
                        error_uuid:
                          readError.response.data.errorObject.error_uuid,
                      };
                    } else {
                      this.errorMessageObject = {
                        errorMessageShow: this.errorMessageShow,
                        error_code: "",
                        error_uuid: "",
                      };
                    }
                    this.showMessageDialog = true;
                  }
                });
            }
          });
        }
        setToLocalStorage(
          "userGroup",
          applicationDefinitionResponse.data.message.userGroup
        );
        this.userGroup = applicationDefinitionResponse.data.message.userGroup;
        this.applicationName =
          applicationDefinitionResponse.data.message.application_display_name;
        this.tabList =
          applicationDefinitionResponse.data.message.viewDefinitions;
        if (["workflow", "archive"].includes(this.tabList[0].viewType)) {
          this.showWorkFlow = true;
          this.showSpinner = false;
          this.workFlowScreenDetails["viewId"] = this.tabList[0].viewId;
          this.workFlowScreenDetails["viewType"] = this.tabList[0].viewType;
          this.viewId = this.tabList[0].viewId;
        } else if (this.tabList[0].viewType === "userList") {
          this.showSpinner = false;
          this.showUserList = true;
        } else if (this.tabList[0].viewType === "master") {
          this.showSpinner = true;
          axios({
            method: "post",
            url: process.env.VUE_APP_BACKEND_URL + "/getScreenDefinition",
            data: {
              applicationId: this.$route.query.applicationId,
              viewId: this.tabList[0].viewId,
              token: getFromLocalStorage("token"),
              authType: getFromLocalStorage("authType"),
            },
            headers: {
              "content-type": "application/json",
            },
          })
            .then((getScreenDefinitionResponse) => {
              this.screenDefinition = getScreenDefinitionResponse.data;
              this.showAddButton = this.screenDefinition.action.includes("add");
              this.showTable = true;
              this.showSpinner = false;
            })
            .catch((getScreenDefinitionError) => {
              this.showSpinner = false;
              if (
                getScreenDefinitionError.response &&
                getScreenDefinitionError.response.status &&
                getScreenDefinitionError.response.status == 401
              ) {
                this.$root.$emit("update_session_value", true);
              } else {
                this.errorMessageShow = true;
                if (
                  getScreenDefinitionError.response &&
                  getScreenDefinitionError.response.data &&
                  getScreenDefinitionError.response.data.errorObject
                ) {
                  this.errorMessageObject = {
                    errorMessageShow: this.errorMessageShow,
                    error_code:
                      getScreenDefinitionError.response.data.errorObject
                        .error_code,
                    error_uuid:
                      getScreenDefinitionError.response.data.errorObject
                        .error_uuid,
                  };
                } else {
                  this.errorMessageObject = {
                    errorMessageShow: this.errorMessageShow,
                    error_code: "",
                    error_uuid: "",
                  };
                }
                this.showMessageDialog = true;
              }
            });
        }
      })
      .catch((applicationDefinitionError) => {
        this.showSpinner = false;
        if (
          applicationDefinitionError.response &&
          applicationDefinitionError.response.status &&
          applicationDefinitionError.response.status == 401
        ) {
          this.$root.$emit("update_session_value", true);
        } else {
          this.errorMessageShow = true;
          if (
            applicationDefinitionError.response &&
            applicationDefinitionError.response.data &&
            applicationDefinitionError.response.data.errorObject
          ) {
            this.errorMessageObject = {
              errorMessageShow: this.errorMessageShow,
              error_code:
                applicationDefinitionError.response.data.errorObject.error_code,
              error_uuid:
                applicationDefinitionError.response.data.errorObject.error_uuid,
            };
          } else {
            this.errorMessageObject = {
              errorMessageShow: this.errorMessageShow,
              error_code: "",
              error_uuid: "",
            };
          }
          this.showMessageDialog = true;
        }
      });
  },
};
</script>


<style>
#app {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  background-color: #e8eeff;
}
.v-application,
.v-application .text-h5,
.v-application .text-h6 {
  font-family: "Poppins", sans-serif !important;
}

.moving-card {
  opacity: 0.5;
  background: #f7fafc;
  border: 1px solid #4299e1;
}

/* .v-tabs {
  padding: 18px !important;
} */

.v-tab--active {
  background-color: rgb(42, 64, 154);
  border-radius: 5px 5px 0px 0px;
  color: white;
  font-size: 0.8rem;
}

.v-tab.v-tab {
  color: white;
}

.v-sheet.v-toolbar:not(.v-sheet--outlined) {
  box-shadow: none !important;
  /* 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%); */
}

.v-application .elevation-2 {
  box-shadow: none !important;
  /* 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%) !important; */
}

.v-tabs-slider-wrapper {
  display: none !important;
}
</style>