<template>
  <v-container>
    <v-navigation-drawer
      v-model="showDrawer"
      right
      fixed
      width="75%"
      color="#E8EEFF"
    >
      <div style="display: flex; flex-direction: row">
        <div style="width: 70%" align="left">
          <div>
            <v-icon @click="clickClose"> mdi-close </v-icon>
            <div style="display: flex; flex-direction: row; margin-left: 50%">
              <div v-for="actionItem in status_action" style="margin: 0px 4px">
                <v-btn
                  depressed
                  :color="getButtonColor(actionItem)"
                  style="color: white"
                  @click="
                    changeWorkFlow(
                      actionItem.key,
                      actionItem.defaultAssigneeGroup,
                      actionItem
                    )
                  "
                  >{{ actionItem.displayText[$i18n.locale] }}</v-btn
                >
                <!-- {{actionItem}} -->
              </div>
            </div>
            <!-- <v-btn @click="clickEdit()">Edit</v-btn> -->
          </div>
          <div class="p-2">
            <v-card height="800" class="scroll">
              <v-card-text>
                <div class="p-2">
                  <div v-for="(fieldItem, index) in fieldDefinition">
                    <div v-if="!fieldItem.isDependent">
                      <div
                        v-if="
                          fieldItem.isVisible &&
                          fieldItem.input_type === 'input'
                        "
                        style="display: flex; flex-direction: row"
                      >
                        <div style="width: 50%; margin-top: 2%" align="left">
                          <label>{{
                            fieldItem.display_name[$i18n.locale]
                          }}</label>
                        </div>
                        <div style="width: 50%" align="right">
                          <v-text-field
                            :disabled="disabled"
                            v-model="fields.field_name[fieldItem.key]"
                          ></v-text-field>
                        </div>
                      </div>
                      <div
                        v-if="
                          fieldItem.isVisible &&
                          fieldItem.input_type === 'workflowoptions'
                        "
                        style="display: flex; flex-direction: row"
                      >
                        <div style="width: 50%; margin-top: 2%" align="left">
                          <label>{{
                            fieldItem.display_name[$i18n.locale]
                          }}</label>
                        </div>
                        <v-select
                          :items="fieldItem.option_values"
                          item-value="value"
                          item-text="value"
                          v-model="fields.field_name[fieldItem.key]"
                          :disabled="disabled"
                        ></v-select>
                      </div>
                      <div
                    v-if="
                      fieldItem.isVisible &&
                      fieldItem.input_type == 'nrc'
                    "
                    class="input_field_div"
                  >
                    <div style="width: 100%; margin-bottom: 8px" align="left">
                      <label
                        >{{ fieldItem.display_name[$i18n.locale] }}
                        <span
                          v-if="
                            fieldItem.validators &&
                            fieldItem.validators.required
                          "
                          style="color: red"
                          >*</span
                        >
                      </label>
                    </div>
                    <div style="width: 100%" align="right">
                      <NRCNumberComponent
                        :rules="
                          fieldItem.validators &&
                          fieldItem.validators.required === true
                            ? rulesForRequired
                            : rulesForNotRequired
                        "
                        v-model="fields.field_name[fieldItem.key]"
                      ></NRCNumberComponent>
                      
                    </div>
                  </div>
                      <div
                        v-if="
                          fieldItem.isVisible &&
                          fieldItem.input_type === 'AssigneeUseroptions'
                        "
                        style="display: flex; flex-direction: row"
                      >
                        <div style="width: 50%; margin-top: 2%" align="left">
                          <label>{{
                            fieldItem.display_name[$i18n.locale]
                          }}</label>
                        </div>
                        <div style="width: 50%" align="right">
                          <v-select
                            :disabled="disabled"
                            :items="assigneeUserGroupOptions"
                            v-model="fields.field_name[fieldItem.key]"
                            @change="loadAssigneeUserList"
                          ></v-select>
                        </div>
                      </div>
                      <div
                        v-if="
                          fieldItem.isVisible &&
                          fieldItem.input_type === 'apiOptions'
                        "
                        style="display: flex; flex-direction: row"
                      >
                        <div style="width: 50%; margin-top: 2%" align="left">
                          <label>{{
                            fieldItem.display_name[$i18n.locale]
                          }}</label>
                        </div>
                        <div style="width: 50%" align="right">
                          <v-select
                            :disabled="disabled"
                            :items="apiOptionValues"
                            v-model="fields.field_name[fieldItem.key]"
                          ></v-select>
                        </div>
                      </div>

                      <div
                        v-if="
                          fieldItem.isVisible &&
                          fieldItem.input_type === 'assigneeOptions'
                        "
                        style="display: flex; flex-direction: row"
                      >
                        <div style="width: 50%; margin-top: 2%" align="left">
                          <label>{{
                            fieldItem.display_name[$i18n.locale]
                          }}</label>
                        </div>
                        <div style="width: 50%" align="right">
                          <v-select
                            :disabled="disabled"
                            :items="assigneeUserList"
                            v-model="fields.field_name[fieldItem.key]"
                          ></v-select>
                        </div>
                      </div>
                      <div
                        v-if="
                          fieldItem.isVisible &&
                          fieldItem.input_type === 'fileupload'
                        "
                        style="display: flex; flex-direction: row"
                      >
                        <div style="width: 50%; margin-top: 2%" align="left">
                          <label>{{
                            fieldItem.display_name[$i18n.locale]
                          }}</label>
                        </div>
                        <div style="width: 50%" align="right">
                          <div v-if="fields.field_name[fieldItem.key]">
                            <img
                              :src="fields.field_name[fieldItem.key]"
                              :class="{ full: fullWidthImage }"
                              @click="fullWidthImage = !fullWidthImage"
                            />
                          </div>
                          <div v-else>
                            <v-file-input
                              v-model="fields.field_name[fieldItem.key]"
                              :placeholder="fieldItem.display_name.en"
                              :disabled="disabled"
                              prepend-icon="mdi-paperclip"
                              @change="fileChange($event, fieldItem.key)"
                            >
                              <template v-slot:selection="{ text }">
                                <v-chip small label color="#007FC4">
                                  {{ text }}
                                </v-chip>
                              </template>
                            </v-file-input>
                          </div>
                        </div>
                      </div>
                      <div
                        v-if="
                          fieldItem.isVisible &&
                          fieldItem.input_type === 'radio'
                        "
                        style="display: flex; flex-direction: row"
                      >
                        <div style="width: 50%; margin-top: 2%" align="left">
                          <label>{{
                            fieldItem.display_name[$i18n.locale]
                          }}</label>
                        </div>
                        <div style="width: 50%" align="right">
                          <div style="width: 50%" align="right">
                            <v-radio-group
                              v-model="fields.field_name[fieldItem.key]"
                            >
                              <v-radio
                                v-for="radioItem in fieldItem.option_values"
                                :disabled="disabled"
                                :key="radioItem"
                                :label="radioItem"
                                :value="radioItem"
                              ></v-radio>
                            </v-radio-group>
                          </div>
                        </div>
                      </div>
                      <div
                        v-if="
                          fieldItem.isVisible &&
                          fieldItem.input_type === 'options'
                        "
                        style="display: flex; flex-direction: row"
                      >
                        <div style="width: 50%; margin-top: 2%" align="left">
                          <label>{{
                            fieldItem.display_name[$i18n.locale]
                          }}</label>
                        </div>
                        <div
                          v-if="fieldItem.view_type === 'reference'"
                          style="width: 50%"
                          align="right"
                        >
                          <v-select
                            :items="fieldItem.option_values"
                            item-value="key"
                            item-text="value"
                            :disabled="disabled"
                            v-model="fields.field_name[fieldItem.key]"
                          ></v-select>
                        </div>
                        <div v-else style="width: 50%" align="right">
                          <v-select
                            :items="fieldItem.option_values"
                            :disabled="disabled"
                            v-model="fields.field_name[fieldItem.key]"
                          ></v-select>
                        </div>
                      </div>
                      <div
                        v-if="
                          fieldItem.isVisible &&
                          fieldItem.input_type === 'textarea'
                        "
                        style="display: flex; flex-direction: row"
                      >
                        <div style="width: 50%; margin-top: 2%" align="left">
                          <label>{{
                            fieldItem.display_name[$i18n.locale]
                          }}</label>
                        </div>
                        <div style="width: 50%" align="right">
                          <v-textarea
                            :disabled="disabled"
                            v-model="fields.field_name[fieldItem.key]"
                          ></v-textarea>
                        </div>
                      </div>
                      <div
                        v-if="
                          fieldItem.isVisible &&
                          fieldItem.input_type === 'phone'
                        "
                        style="display: flex; flex-direction: row"
                      >
                        <div style="width: 50%; margin-top: 2%" align="left">
                          <label>{{
                            fieldItem.display_name[$i18n.locale]
                          }}</label>
                        </div>
                        <div style="width: 50%" align="right">
                          <VuePhoneNumberInput
                            defaultCountryCode="MM"
                            v-model="fields.field_name[fieldItem.key]"
                          />
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      <div
                        v-if="
                          (fieldItem.dependentValue.includes(
                            fields.field_name[fieldItem.dependentField]
                          ) &&
                            fields.field_name[fieldItem.key] != '') ||
                          fields.field_name[fieldItem.key] != ''
                        "
                      >
                        <div
                          v-if="
                            fieldItem.isVisible &&
                            fieldItem.input_type === 'input'
                          "
                          style="display: flex; flex-direction: row"
                        >
                          <div style="width: 50%; margin-top: 2%" align="left">
                            <label>{{
                              fieldItem.display_name[$i18n.locale]
                            }}</label>
                          </div>
                          <div style="width: 50%" align="right">
                            <v-text-field
                              :disabled="disabled"
                              v-model="fields.field_name[fieldItem.key]"
                            ></v-text-field>
                          </div>
                        </div>
                        <div
                          v-if="
                            fieldItem.isVisible &&
                            fieldItem.input_type === 'workflowoptions'
                          "
                          style="display: flex; flex-direction: row"
                        >
                          <div style="width: 50%; margin-top: 2%" align="left">
                            <label>{{
                              fieldItem.display_name[$i18n.locale]
                            }}</label>
                          </div>
                          <v-select
                            :items="fieldItem.option_values"
                            item-value="value"
                            item-text="value"
                            v-model="fields.field_name[fieldItem.key]"
                            :disabled="disabled"
                          ></v-select>
                        </div>
                        <div
                          v-if="
                            fieldItem.isVisible &&
                            fieldItem.input_type === 'AssigneeUseroptions'
                          "
                          style="display: flex; flex-direction: row"
                        >
                          <div style="width: 50%; margin-top: 2%" align="left">
                            <label>{{
                              fieldItem.display_name[$i18n.locale]
                            }}</label>
                          </div>
                          <div style="width: 50%" align="right">
                            <v-select
                              :disabled="disabled"
                              :items="assigneeUserGroupOptions"
                              v-model="fields.field_name[fieldItem.key]"
                              @change="loadAssigneeUserList"
                            ></v-select>
                          </div>
                        </div>
                        <div
                          v-if="
                            fieldItem.isVisible &&
                            fieldItem.input_type === 'apiOptions'
                          "
                          style="display: flex; flex-direction: row"
                        >
                          <div style="width: 50%; margin-top: 2%" align="left">
                            <label>{{
                              fieldItem.display_name[$i18n.locale]
                            }}</label>
                          </div>
                          <div style="width: 50%" align="right">
                            <v-select
                              :disabled="disabled"
                              :items="apiOptionValues"
                              v-model="fields.field_name[fieldItem.key]"
                            ></v-select>
                          </div>
                        </div>

                        <div
                          v-if="
                            fieldItem.isVisible &&
                            fieldItem.input_type === 'assigneeOptions'
                          "
                          style="display: flex; flex-direction: row"
                        >
                          <div style="width: 50%; margin-top: 2%" align="left">
                            <label>{{
                              fieldItem.display_name[$i18n.locale]
                            }}</label>
                          </div>
                          <div style="width: 50%" align="right">
                            <v-select
                              :disabled="disabled"
                              :items="assigneeUserList"
                              v-model="fields.field_name[fieldItem.key]"
                            ></v-select>
                          </div>
                        </div>
                        <div
                          v-if="
                            fieldItem.isVisible &&
                            fieldItem.input_type === 'fileupload'
                          "
                          style="display: flex; flex-direction: row"
                        >
                          <div style="width: 50%; margin-top: 2%" align="left">
                            <label>{{
                              fieldItem.display_name[$i18n.locale]
                            }}</label>
                          </div>
                          <div style="width: 50%" align="right">
                            <img
                              :src="fields.field_name[fieldItem.key]"
                              :class="{ full: fullWidthImage }"
                              @click="fullWidthImage = !fullWidthImage"
                            />
                          </div>
                        </div>
                        <div
                          v-if="
                            fieldItem.isVisible &&
                            fieldItem.input_type === 'radio'
                          "
                          style="display: flex; flex-direction: row"
                        >
                          <div style="width: 50%; margin-top: 2%" align="left">
                            <label>{{
                              fieldItem.display_name[$i18n.locale]
                            }}</label>
                          </div>
                          <div style="width: 50%" align="right">
                            <div style="width: 50%" align="right">
                              <v-radio-group
                                v-model="fields.field_name[fieldItem.key]"
                              >
                                <v-radio
                                  v-for="radioItem in fieldItem.option_values"
                                  :disabled="disabled"
                                  :key="radioItem"
                                  :label="radioItem"
                                  :value="radioItem"
                                ></v-radio>
                              </v-radio-group>
                            </div>
                          </div>
                        </div>
                        <div
                          v-if="
                            fieldItem.isVisible &&
                            fieldItem.input_type === 'options'
                          "
                          style="display: flex; flex-direction: row"
                        >
                          <div style="width: 50%; margin-top: 2%" align="left">
                            <label>{{
                              fieldItem.display_name[$i18n.locale]
                            }}</label>
                          </div>
                          <div
                            v-if="fieldItem.view_type === 'reference'"
                            style="width: 50%"
                            align="right"
                          >
                            <v-select
                              :items="fieldItem.option_values"
                              item-value="key"
                              item-text="value"
                              :disabled="disabled"
                              v-model="fields.field_name[fieldItem.key]"
                            ></v-select>
                          </div>
                          <div v-else style="width: 50%" align="right">
                            <v-select
                              :items="fieldItem.option_values"
                              :disabled="disabled"
                              v-model="fields.field_name[fieldItem.key]"
                            ></v-select>
                          </div>
                        </div>
                        <div
                          v-if="
                            fieldItem.isVisible &&
                            fieldItem.input_type === 'textarea'
                          "
                          style="display: flex; flex-direction: row"
                        >
                          <div style="width: 50%; margin-top: 2%" align="left">
                            <label>{{
                              fieldItem.display_name[$i18n.locale]
                            }}</label>
                          </div>
                          <div style="width: 50%" align="right">
                            <v-textarea
                              :disabled="disabled"
                              v-model="fields.field_name[fieldItem.key]"
                            ></v-textarea>
                          </div>
                        </div>
                        <div
                          v-if="
                            fieldItem.isVisible &&
                            fieldItem.input_type === 'phone'
                          "
                          style="display: flex; flex-direction: row"
                        >
                          <div style="width: 50%; margin-top: 2%" align="left">
                            <label>{{
                              fieldItem.display_name[$i18n.locale]
                            }}</label>
                          </div>
                          <div style="width: 50%" align="right">
                            <VuePhoneNumberInput
                              defaultCountryCode="MM"
                              v-model="fields.field_name[fieldItem.key]"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </div>
        </div>
        <div style="width: 40%" align="right">
          <div style="display: flex; flex-direction: column">
            <!-- <div style="height: 30%" align="top">
              <div class="p-2">
                <v-card class="mx-auto">
                  <div class="p-2">
                    <v-textarea v-model="commentTextArea" outlined />
                    <v-btn depressed color="#2A409A" style="color: white" @click="insertComment()"
                      >Comment</v-btn
                    >
                  </div>
                </v-card>
              </div>
            </div> -->
            <!-- <div style="height: 70%" align="bottom"> -->
            <div class="p-2">
              <h4 align="left">HISTORY</h4>
              <v-card class="mx-auto">
                <v-timeline class="pr-2" dense>
                  <v-virtual-scroll
                    :bench="benched"
                    :items="commentsArray"
                    height="800"
                    min-height="50"
                    item-height="200"
                  >
                    <template v-slot:default="{ item }">
                      <v-timeline-item color="#2A409A" small>
                        <HistoryComponent :item="item"></HistoryComponent>
                      </v-timeline-item>
                    </template>
                  </v-virtual-scroll>
                </v-timeline>
              </v-card>
            </div>
            <!-- </div> -->
          </div>
        </div>
      </div>
      <div v-if="showWorkFlowFieldDialog">
        <WorkFlowFieldDialog
          :fieldObject="fieldObject"
          :screenDefinition="screenDetails"
          :itemDetails="itemDetails"
          @closeWorkFlowDialog="closeWorkFlowDialog"
        ></WorkFlowFieldDialog>
      </div>
    </v-navigation-drawer>
    <div v-if="showMessageDialog">
      <MessageDialog
        :displayDialog="showMessageDialog"
        :messageObject="errorMessageShow ? errorMessageObject : okMessageObject"
        @closeMessageDialog="closeMessageDialog"
      ></MessageDialog>
    </div>
  </v-container>
</template>

<script>
import axios from "axios";
import HistoryComponent from "./HistoryComponent.vue";
import WorkFlowFieldDialog from "./workFlowFieldDialog.vue";
import MessageDialog from "./MessageDialog.vue";
import {getFromLocalStorage, setToLocalStorage} from "../store/localStorageInterface"
import NRCNumberComponent from "./NRCNumberComponent.vue"
export default {
  props: ["itemDetails", "screenDefinition", "viewId"],
  components: {
    HistoryComponent,
    WorkFlowFieldDialog,
    NRCNumberComponent,
    MessageDialog,
  },
  data() {
    return {
      showDrawer: true,
      screenDetails: "",
      fieldDefinition: "",
      disabled: true,
      status_action: "",
      dataItem: "",
      items: ["1", "2", "3", "4", "5", "6", "7", "8"],
      benched: 0,
      assigneeUserGroupOptions: [],
      // fullWidthImageIndex : null,
      fullWidthImage: false,
      commentsArray: [],
      fieldObject: {},
      itemDetails: "",
      showWorkFlowFieldDialog: false,
      commentTextArea: "",
      fields: {
        field_name: {},
      },
      showMessageDialog: false,
      errorMessageShow: false,
      errorMessageObject: {},
      okMessageObject: {},
    };
  },
  methods: {
    closeMessageDialog(emitObject) {
      var closeDialogFlag = false;
      if(emitObject && emitObject.errorDisplayMessage){
        closeDialogFlag = emitObject.errorDisplayMessage
      }
      this.showMessageDialog = emitObject.flag;
      this.$emit("closeDialog", closeDialogFlag);
    },
    closeWorkFlowDialog(flag) {
      this.showWorkFlowFieldDialog = flag;
    },
    clickClose() {
      this.showDrawer = false;
      this.$emit("closeSidePanel", false);
    },
    changeWorkFlow(key, assigneeGroup, item) {
      this.showWorkFlowFieldDialog = true;
      this.fieldObject["assigneeGroup"] = assigneeGroup;
      this.fieldObject["display"] = true;
      this.fieldObject["viewId"] = this.$props.viewId;
      this.fieldObject["action"] = "Workflow";
      this.itemDetails = this.dataItem;
      this.fieldObject["next_status"] = key;
    },
    getButtonColor(actionItem) {
      if (actionItem.hasOwnProperty("color")) {
        return actionItem.color;
      } else {
        return "#2A409A";
      }
    },
    getImageClass(index) {
      return {
        fullWidthImage: this.fullWidthImageIndex === index,
      };
    },
    onImageClick(index) {
      if (this.fullWidthImageIndex === index) {
        this.fullWidthImageIndex = null;
      } else {
        this.fullWidthImageIndex = index;
      }
    },
    clickEdit() {},
    insertComment() {
      var options = {
        comment: [
          {
            text: [this.commentTextArea],
            userId: getFromLocalStorage("userId"),
            dateTime: Date().toString(),
          },
        ],
        applicationId: this.$route.query.applicationId,
        // userId: getFromLocalStorage("userId"),
        token: getFromLocalStorage("token"),
        authType: getFromLocalStorage("authType"),
        defaultSortKey: this.dataItem["sk_assignee"],
      };

      axios({
        method: "post",
        url: process.env.VUE_APP_BACKEND_URL + "/insertComment",
        data: options,
        headers: {
          "content-type": "application/json",
        },
      })
        .then((addRecordResponse) => {
        })
        .catch((addRecordError) => {
          if (
            addRecordError.response &&
            addRecordError.response.status &&
            addRecordError.response.status == 401
          ) {
            this.$root.$emit('update_session_value', true);
          }
          else {
            this.errorMessageShow = true;
          if (
            addRecordError.response &&
            addRecordError.response.data &&
            addRecordError.response.data.errorObject
          ) {
            this.errorMessageObject = {
              errorMessageShow: this.errorMessageShow,
              error_code: addRecordError.response.data.errorObject.error_code,
              error_uuid: addRecordError.response.data.errorObject.error_uuid,
            };
          } else {
            this.errorMessageObject = {
              errorMessageShow: this.errorMessageShow,
              error_code: "",
              error_uuid: "",
            };
          }
          this.showMessageDialog = true;
          }
        });
    },
  },
  mounted() {
    this.screenDetails = this.$props.screenDefinition;
    this.fieldDefinition = this.screenDetails.fieldDetails.fields;
    this.dataItem = this.$props.itemDetails;
    this.commentsArray = this.dataItem.Comments;
    // this.commentsArray.reverse();
    this.status_action = this.dataItem["status_action"];
    this.fields.field_name = this.dataItem;
    this.assigneeUserGroupOptions.push(
      this.fields.field_name["assignee_user_group"]
    );
  },
};
</script>

<style>
.scroll {
  overflow-y: scroll;
}
</style>