<template>
  <div id="divSelector" style="overflow-y: scroll; height: 64vh; box-shadow: none;">
    <v-card style="box-shadow: none;">
      <div
        class="items"
        style="
          display: flex;
          justify-content: space-between;
          flex-direction: column;
        "
      >
        <div v-for="(fieldItem, index) in fieldDefinition" :key="index">
          <div v-if="fieldItem.key in fields.field_name && fieldItem.input_type !== 'label' && checkFieldHide(fieldItem)">
            <div v-if="!fieldItem.isDependent">
              <div v-if="fieldItem.input_type === 'date_input'">
                <div v-if="fieldItem.key === 'CreatedAt'">
                  <div class="items_sec">
                    <label style="color: #828282">{{
                      fieldItem.display_name[$i18n.locale]
                    }}</label>
                    <div>
                      <label>{{
                        formatDate(fields.field_name[fieldItem.key])
                      }}</label>
                    </div>
                  </div>
                </div>
                <div v-else-if="fieldItem.key === 'UpdatedAt'">
                  <div class="items_sec">
                    <label style="color: #828282">{{
                      fieldItem.display_name[$i18n.locale]
                    }}</label>
                    <div>
                      <label>{{
                        formatDate(fields.field_name[fieldItem.key])
                      }}</label>
                    </div>
                  </div>
                  <v-divider></v-divider>
                </div>
                <v-divider></v-divider>
              </div>
              <div
                v-else-if="fieldItem.key === 'consolidated_overall_breach_status'"
              >
                <div class="items_sec">
                  <label style="color: #828282">{{
                    fieldItem.display_name[$i18n.locale]
                  }}</label>
                  <div v-if="fields.field_name[fieldItem.key]">
                    <label style="color: #333333">Breached</label>
                  </div>
                  <div v-else>
                    <label style="color: #333333">Not Breached</label>
                  </div>
                </div>
                <v-divider></v-divider>
              </div>
              <div
                v-else-if="fieldItem.input_type === 'slaBreachTime'"
              >
                <div class="items_sec">
                  <label style="color: #828282">{{
                    fieldItem.display_name[$i18n.locale]
                  }}</label>
                  {{ formatBreachTime(fieldItem, fields.field_name[fieldItem.key]) }}
                </div>
                <v-divider></v-divider>
              </div>
              <div v-else-if="fieldItem.input_type === 'textarea'">
                <div class="items_sec">
                  <label style="color: #828282">
                    {{ fieldItem.display_name[$i18n.locale] }}</label
                  >
                  <div>
                    <pre
                      style="
                        white-space: pre-line;
                        line-height: 100%;
                        font-family: Poppins;
                      "
                    >
                          {{ fields.field_name[fieldItem.key] }}
                        </pre
                    >
                  </div>
                </div>
                <v-divider></v-divider>
              </div>
              <div v-else-if="fieldItem.input_type === 'alert'">
                <div class="items_sec">
                  <label style="color: #828282">
                    {{ fieldItem.display_name[$i18n.locale] }} ({{fieldItem.alertConfiguration.timezone}})</label
                  >
                  <div>
                    <pre
                      style="
                        white-space: pre-line;
                        line-height: 100%;
                        font-family: Poppins;
                      "
                    >
                          {{ formateDateTime(fields.field_name[fieldItem.key] , fieldItem) }}
                        </pre
                    >
                  </div>
                </div>
                <v-divider></v-divider>
              </div>
              <div v-else-if="fieldItem.input_type === 'number'">
                <div class="items_sec">
                  <label style="color: #828282">
                    {{ fieldItem.display_name[$i18n.locale] }}</label
                  >
                  <div>
                    <pre
                      style="
                        white-space: pre-line;
                        line-height: 100%;
                        font-family: Poppins;
                      "
                    >
                      {{ numberFormatter(fields.field_name[fieldItem.key], fieldItem.key) }}
                    </pre>
                  </div>
                </div>
                <v-divider></v-divider>
              </div>
              <div v-else-if="fieldItem.input_type === 'multiselect'">
                <div class="items_sec">
                  <label style="color: #828282">
                    {{ fieldItem.display_name[$i18n.locale] }}</label
                  >
                  <div>
                    <pre
                      style="
                        white-space: pre-line;
                        line-height: 100%;
                        font-family: Poppins;
                      "
                    >
                      {{ multiselectFormatter(fields.field_name[fieldItem.key]) }}
                    </pre>
                  </div>
                </div>
                <v-divider></v-divider>
              </div>
              <div v-else-if="fieldItem.input_type === 'datetime'">
                <div class="items_sec">
                  <label style="color: #828282">
                    {{ fieldItem.display_name[$i18n.locale] }} 
                  </label>
                  <div>
                    <pre
                      style="
                        white-space: pre-line;
                        line-height: 100%;
                        font-family: Poppins;
                      "
                    >
                      {{ formatDateTime(fields.field_name[fieldItem.key] , fieldItem) }}
                    </pre>
                  </div>
                </div>
                <v-divider></v-divider>
              </div>
              <div v-else-if="fieldItem.input_type === 'table'">
                <div class="items_sec" @click="renderComponent(fieldItem.key)" :id="'click'+fieldItem.key">
                  <label style="color: #828282">
                    {{ fieldItem.display_name[$i18n.locale] }}</label
                  >
                </div>
                <div v-if="showTable[fieldItem.key]">
                  <TableComponentVue
                    :mode="tableMode"
                    :dataItems = "fields.field_name[fieldItem.key]"
                    :tableConfiguration="getTableConfiguration(fieldItem.key)"
                    :showFooter=true
                  ></TableComponentVue>
                </div>
                <v-divider></v-divider>
              </div>
              <div v-else-if="fieldItem.input_type === 'checklist'">
                <div class="items_sec">
                  <label style="color: #828282">
                    {{ fieldItem.display_name[$i18n.locale] }}</label
                  >
                <div>
                <div v-for="element in fields.field_name[fieldItem.key]" style="display:flex; flex-direction:row; justify-content: space-between; align-items: center;">
                    <pre
                      style= "white-space: pre-line;
                        line-height: 100%;
                        font-family: Poppins;
                        margin-top: 5px"
                      >{{ element.display[$i18n.locale] }}
                    </pre>
                    <div>
                      <v-icon v-if="element.checked" size="1.3rem">mdi-checkbox-marked</v-icon>
                      <v-icon v-else size="1.3rem" >mdi-checkbox-blank-outline</v-icon>
                    </div>
                </div>
                  </div> 
                </div>
                <v-divider></v-divider>
              </div>
              <div
                v-else-if="
                  fieldItem.input_type === 'options' &&
                  fieldItem.view_type === 'reference'
                "
              >
                <div class="items_sec">
                  <label style="color: #828282;">
                    {{ fieldItem.display_name[$i18n.locale] }}</label
                  >
                  <div>
                  <div v-for="optionValue in fieldItem.option_values" style="justify-content:space-between">
                    <div
                      v-if="optionValue.key === fields.field_name[fieldItem.key]" align="right"
                    >
                      {{ optionValue.value }}
                    </div>
                  </div>
                  <div v-if="fieldItem.isMasterViewConfig" align="left">
                    <MasterViewComponent  v-if="fieldItem.isMasterViewConfig && viewData[fieldItem.key] " v-model="viewData"
                      :keyName="fieldItem.key"
                    ></MasterViewComponent>
                  </div>
                  </div>
                </div>
                <v-divider></v-divider>
              </div>
              <div
                v-else-if="
                  fieldItem.input_type === 'hierarchicalDropdown' &&
                  fieldItem.view_type === 'reference'
                "
              >
                <div class="items_sec">
                  <label style="color: #828282;">
                    {{ fieldItem.display_name[$i18n.locale] }}</label
                  >
                  <div align="right">
                    {{ fields.field_name[fieldItem.key+"_reference"] }}
                    <div v-if="fieldItem.isMasterViewConfig" align="left">
                      <MasterViewComponent  v-if="fieldItem.isMasterViewConfig && viewData[fieldItem.key] " v-model="viewData"
                        :keyName="fieldItem.key" 
                      ></MasterViewComponent>
                    </div>
                  </div>
                </div>
                <v-divider></v-divider>
              </div>
              <div
                v-else-if="fieldItem.key === 'consolidated_stage_breach_status'"
              >
                <div class="items_sec">
                  <label style="color: #828282">{{
                    fieldItem.display_name[$i18n.locale]
                  }}</label>
                  <div v-if="fields.field_name[fieldItem.key]">
                    <label style="color: #333333">Breached</label>
                  </div>
                  <div v-else>
                    <label style="color: #333333">Not Breached</label>
                  </div>
                </div>
                <v-divider></v-divider>
              </div>
              <!-- slatable -->
              <div v-else-if="fieldItem.key === 'stage_breach_status'">
                <div class="items_sec">
                  <label style="color: #828282">{{
                    fieldItem.display_name[$i18n.locale]
                  }}</label>
                  <div v-if="fields.field_name[fieldItem.key]">
                    <v-row
                      dense
                      @click="showDialog(fields.field_name[fieldItem.key],fieldItem)"
                      style="cursor: pointer"
                    >
                      <v-col class="heading"
                        >View Details<v-icon style="color: rgba(47, 128, 237, 1)"
                          >mdi-information-outline</v-icon
                        ></v-col
                      >
                    </v-row>
                  </div>
                </div>

                <v-divider></v-divider>
              </div>
              <div v-else-if="fieldItem.key === 'overall_breach_status'">
                <div class="items_sec">
                  <label style="color: #828282">{{
                    fieldItem.display_name[$i18n.locale]
                  }}</label>
                  <div v-if="fields.field_name[fieldItem.key]">
                    <v-row
                      dense
                      @click="showDialog(fields.field_name[fieldItem.key],fieldItem)"
                      style="cursor: pointer"
                    >
                      <v-col class="heading"
                        >View Details<v-icon style="color: rgba(47, 128, 237, 1)"
                          >mdi-information-outline</v-icon
                        ></v-col
                      >
                    </v-row>
                  </div>
                </div>

                <v-divider></v-divider>
              </div>
              <div
                v-else-if="
                  fieldItem.isVisible && fieldItem.key !== 'status_action' &&
                  fieldItem.key !== 'CreatedAt' &&
                  fieldItem.key !== 'UpdatedAt'
                "
              >
                <div class="items_sec">
                  <label style="color: #828282">{{
                    fieldItem.display_name[$i18n.locale]
                  }}</label>
                  <div v-if="fieldItem.input_type === 'fileupload'">
                    <div v-if="fields.field_name[fieldItem.key] === null || fields.field_name[fieldItem.key] === ''">
                      No File Attached
                    </div>
                  </div>
                  <div v-else-if="fieldItem.input_type === 'multifileupload'">
                    <div v-if="fields.field_name[fieldItem.key] !== ''">
                      <v-btn
                        @click="toggleVisibility(fieldItem.key)"
                        color="#2A409A"
                        style="color: white; font-size: 12px"
                      > 
                      {{ fileFieldsVisibility[fieldItem.key] ? `Hide List` : `Show List` }}
                      </v-btn>
                    </div>
                    <div v-else>
                      No File Attached
                    </div>
                  </div>
                  <div v-else-if="fieldItem.input_type === 'currency'">
                    <div class="item_names">
                      <div>
                        <pre
                          style= "white-space: pre-line;line-height: 100%;font-family: Poppins;"
                        >
                          {{ formatCurrency(fields.field_name[fieldItem.key],fieldItem) }}
                        </pre
                        >
                      </div>
                    </div>
                  </div>
                  <div v-else-if="fieldItem.input_type === 'priority'">
                    <div class="item_names">
                      <div>
                        <pre
                          style= "white-space: pre-line;line-height: 100%;font-family: Poppins;"
                        >
                        {{formatPriority(fields.field_name[fieldItem.key],fieldItem)}}
                        </pre
                        >
                      </div>
                    </div>
                  </div>
                  <div v-else-if="fieldItem.input_type === 'URL'">
                      <div v-if="fields.field_name[fieldItem.key] !== ''">
                        <label>
                          <a :href="fields.field_name[fieldItem.key]" target="_blank"> {{fields.field_name[fieldItem.key]}}</a>
                        </label>
                      </div>
                    </div>
                  <div  v-else-if="fieldItem.key === 'assignee'">
                  <div class="item_names">
                    <div>{{fields.field_name['assigneeUserName']}}</div>
                    <div>{{fields.field_name[fieldItem.key]}}</div>
                  </div>
                  </div>
                  <div v-else-if="fieldItem.key === 'created_by'">
                    <div class="item_names">
                      <div>{{fields.field_name['createdByName']}}</div>
                      <div>{{fields.field_name[fieldItem.key]}}</div>
                    </div>
                  </div>
                  <div v-else-if="fieldItem.key === 'updated_by'">
                    <div class="item_names">
                      <div>{{fields.field_name['updatedByName']}}</div>
                      <div>{{fields.field_name[fieldItem.key]}}</div>
                    </div>
                  </div>
                  <div v-else>
                    <label style="color: #333333">{{
                      fields.field_name[fieldItem.key]
                    }}</label>
                  </div>
                </div>
                <div v-if="fieldItem.input_type === 'fileupload'">
                    <div v-if="fields.field_name[fieldItem.key] !== ''" style="display: flex; flex-direction: row; justify-content: space-between; align-items: center; padding: 8px; padding-top: 0px;">
                        <div
                            class="hover-container"
                            @mouseenter="hoveredIndex = fieldItem.key"
                            @mouseleave="hoveredIndex = null"
                            @click="viewAndDownloadFile(fieldItem.key, true)"
                            >
                            <img
                              :src="hoveredIndex === fieldItem.key ? activeImage : unactiveImage"
                              style="height: 16px;"
                              class="file-icon"
                            />
                            <span class="file-name">
                              {{ formatFileName(fieldItem.key) }}
                            </span>
                            </div>
                            <v-btn
                            color="#2A409A"
                            style="color: white; font-size: 12px;"
                            @click="viewAndDownloadFile(fieldItem.key, false)"
                            >
                            <v-icon left>mdi-download</v-icon>
                            Download
                            </v-btn>
                    </div>
                </div>
                <div v-if="fieldItem.input_type === 'multifileupload' && fields.field_name[fieldItem.key] !== '' && fileFieldsVisibility[fieldItem.key]">
                  <MultiFileDownloadComponent
                  :workflow_id="workflow_id"
                  :fileFieldKey = "fieldItem.key"
                  :fieldDetails="fields.field_name"
                  @closemultiFileDownloadDialog="closemultiFileDownloadDialog"
                  ></MultiFileDownloadComponent>
                </div>
                <v-divider></v-divider>
              </div>
            </div>
            <div v-else>
              <div
                v-if="
                  (fieldItem.dependentValue.includes(
                    fields.field_name[fieldItem.dependentField]
                  ) && fields.field_name[fieldItem.key] !== '') ||
                  (fieldItem.dependentField == 'current_status' && fields.field_name[fieldItem.key] !== '')" 
              >
                <div v-if="fieldItem.isVisible">
                  <div v-if="fieldItem.input_type === 'table'">
                    <div class="items_sec" @click="renderComponent(fieldItem.key)" :id="'click'+fieldItem.key">
                      <label style="color: #828282">
                        {{ fieldItem.display_name[$i18n.locale] }}</label
                      >
                    </div>
                    <div v-if="showTable[fieldItem.key]">
                      <TableComponentVue
                        :mode="tableMode"
                        :dataItems = "fields.field_name[fieldItem.key]"
                        :tableConfiguration="getTableConfiguration(fieldItem.key)"
                        :showFooter=true
                      ></TableComponentVue>
                    </div>
                    <v-divider></v-divider>
                  </div>
                <div v-else class="items_sec">
                  <label style="color: #828282">{{
                    fieldItem.display_name[$i18n.locale] 
                  }}
                    <label v-if="fieldItem['alertConfiguration'] !=undefined">
                      ({{fieldItem['alertConfiguration']['timezone']}})
                    </label>
                  </label>
                  <div v-if="fieldItem.input_type === 'fileupload'">
                    <div v-if="fields.field_name[fieldItem.key] === null || fields.field_name[fieldItem.key] === ''">
                      No File Attached
                    </div>
                  </div>
                  <div v-else-if="fieldItem.input_type === 'multifileupload'">
                    <div v-if="fields.field_name[fieldItem.key]!== ''">
                        <v-btn
                        @click="toggleVisibility(fieldItem.key)"
                        color="#2A409A"
                        style="color: white; font-size: 12px" 
                        > {{ fileFieldsVisibility[fieldItem.key] ? `Hide List` : `Show List` }}
                        </v-btn>
                    </div>
                    <div v-else>
                        No File Attached
                    </div>
                </div>
                <div v-else-if="fieldItem.input_type === 'currency'">
                  <div class="item_names">
                    <div>
                      <pre
                        style= "white-space: pre-line;line-height: 100%;font-family: Poppins;"
                      >
                        {{ formatCurrency(fields.field_name[fieldItem.key],fieldItem) }}
                      </pre
                      >
                    </div>
                  </div>
                </div>
                <div v-else-if="fieldItem.input_type === 'alert'">
              <div class="items_sec">
                <div>
                  <pre
                    style="
                      white-space: pre-line;
                      line-height: 100%;
                      font-family: Poppins;
                    "
                  >
                         {{ formateDateTime(fields.field_name[fieldItem.key] , fieldItem) }}
                      </pre
                  >
                </div>
              </div>
                </div>
                <div v-else-if="fieldItem.input_type === 'priority'">
                  <div class="item_names">
                    <div>
                      <pre
                        style= "white-space: pre-line;line-height: 100%;font-family: Poppins;"
                      >
                      {{formatPriority(fields.field_name[fieldItem.key],fieldItem)}}
                      </pre
                      >
                    </div>
                  </div>
                </div>
                <div v-else-if="fieldItem.input_type === 'number'">
                  <div class="items_names">
                    <div>
                      <pre
                        style="
                          white-space: pre-line;
                          line-height: 100%;
                          font-family: Poppins;
                        "
                      >
                        {{ numberFormatter(fields.field_name[fieldItem.key], fieldItem.key) }}
                      </pre>
                    </div>
                  </div>
                </div>
                <div v-else-if="fieldItem.input_type === 'multiselect'">
                  <div class="items_names">
                    <div>
                      <pre
                        style="
                          white-space: pre-line;
                          line-height: 100%;
                          font-family: Poppins;
                        "
                      >
                        {{ multiselectFormatter(fields.field_name[fieldItem.key]) }}
                      </pre>
                    </div>
                  </div>
                </div>
                <div v-else-if="fieldItem.input_type === 'datetime'">
                  <div class="items_sec">
                    <div>
                      <pre
                        style="
                          white-space: pre-line;
                          line-height: 100%;
                          font-family: Poppins;
                        "
                      >
                        {{ formatDateTime(fields.field_name[fieldItem.key] , fieldItem) }}
                      </pre>
                    </div>
                  </div>
                </div>
                <div v-else-if="fieldItem.input_type === 'checklist'">
                  <div v-for="element in fields.field_name[fieldItem.key]" style="display:flex; flex-direction:row; justify-content: space-between; align-items: center;">
                    <pre
                      style= "white-space: pre-line;
                        line-height: 100%;
                        font-family: Poppins;
                        margin-top: 5px"
                      >{{ element.display[$i18n.locale] }}
                    </pre>
                    <div>
                      <v-icon v-if="element.checked" size="1.3rem">mdi-checkbox-marked</v-icon>
                      <v-icon v-else size="1.3rem" >mdi-checkbox-blank-outline</v-icon>
                    </div>
                  </div>
                </div>
                <div v-else-if="fieldItem.input_type === 'URL'">
                  <div v-if="fields.field_name[fieldItem.key] !== ''">
                    <label>
                      <a :href="fields.field_name[fieldItem.key]" target="_blank"> {{fields.field_name[fieldItem.key]}}</a>
                    </label>
                  </div>
                </div>
                <div
                    v-else-if="
                      fieldItem.input_type === 'options' &&
                      fieldItem.view_type === 'reference'
                    "
                  >
                  <div>
                    <div v-for="optionValue in fieldItem.option_values" style="justify-content:space-between">
                      <div
                        v-if="optionValue.key === fields.field_name[fieldItem.key]"
                        align="right"
                      >
                        {{ optionValue.value }}
                      </div>
                    </div>
                    <div v-if="fieldItem.isMasterViewConfig" align="left">
                      <MasterViewComponent  v-if="fieldItem.isMasterViewConfig && viewData[fieldItem.key] " v-model="viewData"
                        :keyName="fieldItem.key" 
                      ></MasterViewComponent>
                    </div>
                  </div>
                </div>
                <div
                  v-else-if="
                    fieldItem.input_type === 'hierarchicalDropdown' &&
                    fieldItem.view_type === 'reference'
                  "
                >
                  <div align="right">
                    {{ fields.field_name[fieldItem.key+"_reference"] }}
                    <div v-if="fieldItem.isMasterViewConfig" align="left">
                      <MasterViewComponent  v-if="fieldItem.isMasterViewConfig && viewData[fieldItem.key] " v-model="viewData"
                        :keyName="fieldItem.key" 
                      ></MasterViewComponent>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <label style="color: #333333">{{
                    fields.field_name[fieldItem.key]
                  }}</label>
                </div>
                </div>
                <div v-if="fieldItem.input_type === 'fileupload'">
                    <div v-if="fields.field_name[fieldItem.key] !== ''" style="display: flex; flex-direction: row; justify-content: space-between; align-items: center; padding: 8px; padding-top: 0px;">
                        <div
                          class="hover-container"
                          @mouseenter="hoveredIndex = fieldItem.key"
                          @mouseleave="hoveredIndex = null"
                          @click="viewAndDownloadFile(fieldItem.key, true)"
                          >
                          <img
                            :src="hoveredIndex === fieldItem.key ? activeImage : unactiveImage"
                            style="height: 16px;"
                            class="file-icon"
                          />
                          <span class="file-name">
                            {{ formatFileName(fieldItem.key) }}
                          </span>
                          </div>
                          <v-btn
                          color="#2A409A"
                          style="color: white; font-size: 12px;"
                          @click="viewAndDownloadFile(fieldItem.key, false)"
                          >
                          <v-icon left>mdi-download</v-icon>
                          Download
                          </v-btn>
                        </div>
                </div>
                <div v-if="fieldItem.input_type === 'multifileupload' && fields.field_name[fieldItem.key] !== '' && fileFieldsVisibility[fieldItem.key]">
                  <MultiFileDownloadComponent
                  :workflow_id="workflow_id"
                  :fileFieldKey = "fieldItem.key"
                  :fieldDetails="fields.field_name"
                  @closemultiFileDownloadDialog="closemultiFileDownloadDialog"
                  ></MultiFileDownloadComponent>
                </div>
                <v-divider></v-divider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-card>
    <div v-if="slaBreachStatusDialog">
      <SlaBreachStatus
        :breachData="breachStatus"
        :itemDetails = "itemDetails"
        :breachKey = "breachKey"
        :displaySlaDialog="displaySlaDialogFlag"
        @clicked="closeSlaBreachStatusDialog"
      >
      </SlaBreachStatus>
    </div>
    <div v-if="showMessageDialog">
        <!-- :dialogText="dialogText" -->
      <MessageDialog
        :displayDialog="showMessageDialog"
        :messageObject="errorMessageShow ? errorMessageObject : okMessageObject"
        @closeMessageDialog="closeMessageDialog"
      ></MessageDialog>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import SlaBreachStatus from "./SlaBreachStatus.vue";
import AssigneeDisplayNameComponent from "./AssigneeDisplayNameComponent.vue";
import MessageDialog from "./MessageDialog.vue";
import { currencyFormat } from "../mixins/currencyFormat.js";
import MultiFileDownloadComponent from "./MultiFileDownloadComponent.vue";
import TableComponentVue from "./TableComponent.vue";
import MasterViewComponent from "./MasterViewComponent.vue";
import {getFromLocalStorage} from "../store/localStorageInterface"
import { formatFileNameMixin } from "../mixins/formatFileName.js";
export default {
  props: ["itemDetails", "screenDefinition", "viewId"],
  components: {
    SlaBreachStatus,
    AssigneeDisplayNameComponent,
    MessageDialog,
    MultiFileDownloadComponent,
    TableComponentVue,
    MasterViewComponent
  },
  data() {
    return {
      breachKey:"",
      fieldDefinition: "",
      fields: {
        field_name: {},
      },
      dataItem: "",
      screenDetails: "",
      slaBreachStatusDialog: false,
      breachStatus: {},
      displaySlaDialogFlag: false,
      showMessageDialog: false,
      errorMessageShow: false,
      errorMessageObject: {},
      okMessageObject: {},
      currentScopeKey:"",
      multiFileArray:[],
      fileFieldKey:"",
      showMultiDownload:false,
      formatConfig : {
            maximumFractionDigits: 20
      },
      tableData: [],
      tableMode: 'VIEW',
      masterViewAccessFields: {},
      masterDataList: {},
      masterDisplayList: {},
      viewData: {
      },
      showMasterViewFields: false,
      alreadyRendered: {},
      showTable: {},
      fileFieldsVisibility: {},
      hoveredIndex: null,
      unactiveImage: require('@/assets/unactiveHyperlink.svg'),
      activeImage: require('@/assets/activeHyperlink.svg'),
    };
  },
  mixins: [currencyFormat, formatFileNameMixin],
  methods: {
    renderComponent(item){
      this.$set(this.showTable, item, true);
    },
    handleScroll(){
      let showTableArr = Object.keys(this.showTable);
      let overallDiv = document.querySelector('#divSelector');
      for(let item of showTableArr){
        let btn = document.querySelector('#click' + item);
        if(btn != null && (!(item in this.alreadyRendered) || !this.alreadyRendered[item]) && (overallDiv.clientHeight - ((btn.getBoundingClientRect().top + 80) - overallDiv.getBoundingClientRect().top) > 0)){
          this.alreadyRendered[item] = true;
          this.$set(this.showTable, item, false);
          this.$nextTick(()=>{
            btn.click();
          })
        }
        if(this.alreadyRendered[item] && !(overallDiv.clientHeight - ((btn.getBoundingClientRect().top + 80) - overallDiv.getBoundingClientRect().top) > 0)){
          this.alreadyRendered[item] = false;
        }
      }
    },
    formatPriority(value,priorityField){
      let priorityData;
      priorityField.option_values.map((options)=>{
        if(options.key === value){
          priorityData =  options.displayText;
          return options
        }
      })
      return priorityData
    },
    formatDate(date) {
      return moment(date).format("DD-MMM-yyyy hh:mm:ss A");
    },
    formatDateTime(value, fieldItem){
      let timezone = 'Asia/Kolkata';
      if(this.fields.field_name.AdditionalFieldInformation && this.fields.field_name.AdditionalFieldInformation[fieldItem.key] && this.fields.field_name.AdditionalFieldInformation[fieldItem.key].timezone){
        timezone = this.fields.field_name.AdditionalFieldInformation[fieldItem.key].timezone;
      }
      else if(fieldItem.validators && fieldItem.validators.date && fieldItem.validators.date.timezone){
         timezone = fieldItem.validators.date.timezone;
      }
      let formattedDateTime = "";
      if(value)
      {
        formattedDateTime = moment.unix(Number(value)).tz(timezone).format("DD-MM-YYYY HH:mm:ss");
        return formattedDateTime+" ("+timezone+")";
      }
      else return formattedDateTime;
    },
    viewFilesList(files, key){
      this.multiFileArray=files;
      this.fileFieldKey = key;
      this.showMultiDownload=true;
    },
    formatFileName(fileFieldKey) {
      var fileUrl = this.fields.field_name[fileFieldKey].split("/").slice(-1)[0];
      if (
        this.fields.field_name.AdditionalFieldInformation[fileFieldKey] &&
        this.fields.field_name.AdditionalFieldInformation[fileFieldKey][fileUrl]
      ) {
        return this.fileNameFormat(
        this.fields.field_name.AdditionalFieldInformation[fileFieldKey][fileUrl]
        );
      }else{
        return this.fileNameFormat(fileUrl);
      } 
	  },
    closemultiFileDownloadDialog(flag) {
      this.showMultiDownload = flag;
    },
    showDialog(value,fieldItem) {
      this.breachKey= fieldItem.key;
      this.slaBreachStatusDialog = true;
      this.breachStatus = value;
      this.displaySlaDialogFlag = true;
    },
    closeSlaBreachStatusDialog(value) {
      this.slaBreachStatusDialog = value;
    },
    formatCurrency(value,currencyField){
      let currencyDisplay = 'narrowSymbol';
      if(this.fields.field_name.AdditionalFieldInformation && this.fields.field_name.AdditionalFieldInformation[currencyField.key]){
        var system;
        var digitsAfterdecimalPoint;
        var currencyCodeValue;
        currencyCodeValue = this.fields.field_name["AdditionalFieldInformation"][currencyField.key]["currencyCode"];
        if(currencyCodeValue){
          if(currencyField.currency_configuration && currencyField.currency_configuration.currencyDisplay){
            if(currencyField.currency_configuration.currencyDisplay === 'code'){
                currencyDisplay = 'code';
              } else if(currencyField.currency_configuration.currencyDisplay === 'symbolAndCode'){
                currencyDisplay = 'symbolAndCode';
              }
          }
          if(currencyField.currency_configuration && currencyField.currency_configuration.format && currencyField.currency_configuration.format[currencyCodeValue]){
            system = currencyField.currency_configuration.format[currencyCodeValue]["system"]
            digitsAfterdecimalPoint = currencyField.currency_configuration.format[currencyCodeValue]["digitsAfterdecimalPoint"];
            return this.formatCurrencyValue(currencyCodeValue,digitsAfterdecimalPoint,value,system,currencyDisplay)
          }
          else {
            return this.formatCurrencyValue(currencyCodeValue,2,value,"English", currencyDisplay)
          }
        }
        else {
          return this.formatCurrencyValue("USD",2,value,"English", currencyDisplay)
        }
      }
      else {
        return this.formatCurrencyValue("USD",2,value,"English", currencyDisplay)
      }
		},
    formateDateTime(value , fieldItem){
      return new Date(value).toLocaleString(undefined , {timezone : fieldItem.alertConfiguration.timezone})
    },
    viewAndDownloadFile(fieldKey, isView) {
      var file_name = this.fields.field_name[fieldKey];
      var read_body = {
        token: getFromLocalStorage("token"),
        authType: getFromLocalStorage("authType"),
        applicationId: this.$route.query.applicationId,
        userId: this.$route.query.userId,
        workflow_id: this.dataItem.workflow_id,
        file_name: file_name,
      };
      axios
        .post(
          process.env.VUE_APP_BACKEND_URL + "/getPresignedURLRead",
          read_body
        )
        .then((readSuccess) => {
          if(isView){
            window.open(readSuccess.data.data, "_blank")
          }
          else{
            axios.get(readSuccess.data.data, {
              responseType: "blob",
            }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const a = document.createElement("a");
            a.href = url;
            a.download = this.formatFileName(fieldKey); 
            document.body.appendChild(a);
            a.click();
            a.remove();
            window.URL.revokeObjectURL(url);
            })
          }
        })
        .catch((readError) => {
          if (
          readError.response &&
          readError.response.status &&
          readError.response.status == 401
        ) {
          this.$root.$emit('update_session_value', true);
        }
        else{
          this.errorMessageShow = true;
           if(readError.response && readError.response.data && readError.response.data.errorObject){
          this.errorMessageObject = {
            errorMessageShow: this.errorMessageShow,
            error_code: readError.response.data.errorObject.error_code,
            error_uuid: readError.response.data.errorObject.error_uuid,
          };
        }
        else{
          this.errorMessageObject = {
            errorMessageShow: this.errorMessageShow,
            error_code: "",
            error_uuid: "",
          };
        }
        this.showMessageDialog = true;
        }
        });
    },
    closeMessageDialog(emitObject) {
      var closeDialogFlag = false;
      if(emitObject && emitObject.errorDisplayMessage){
        closeDialogFlag = emitObject.errorDisplayMessage
      }
      this.showMessageDialog = emitObject.flag;
      this.$emit("closeDialog", closeDialogFlag);
    },
    numberFormatter(numberInput, key){
      var input = numberInput;
      for(let field of this.fieldDefinition){
        if(field.key == key){
          if(field.number_configuration && field.number_configuration.numberSystem){
            if(field.number_configuration.numberSystem == "English"){
              const NumberFormatter = new Intl.NumberFormat("en-US", this.formatConfig);
              input = NumberFormatter.format(input); 
            }
            else if(field.number_configuration.numberSystem == "Arabic"){
              const NumberFormatter = new Intl.NumberFormat("en-AR", this.formatConfig);
              input = NumberFormatter.format(input); 
            }
          }
          break;
        }
      }
      return input
    },
    multiselectFormatter(value){
      let optionsSelected = value.map(option => {
        return option.value
      });
      return optionsSelected.toString();
    },
    getTableConfiguration(key){
      for(let field of this.fieldDefinition){
        if(field.key == key){
          if(field.table_configuration){
            return field.table_configuration;
          }
        }
      }
      return null;
    },
    async getMasterViewFields() {
      for (let fieldItem of this.fieldDefinition) {
        if (fieldItem.isMasterViewConfig) {
          let userGroup = getFromLocalStorage("userGroup");
          if (fieldItem.masterViewConfig
            && this.$props.itemDetails.workflow_id in fieldItem.masterViewConfig
            && this.$props.itemDetails.current_status in fieldItem.masterViewConfig[this.$props.itemDetails.workflow_id]
            && userGroup in fieldItem.masterViewConfig[this.$props.itemDetails.workflow_id][this.$props.itemDetails.current_status]
            && !this.masterDataList[fieldItem.view_id]
          ) {

            this.masterViewAccessFields[fieldItem.key] = fieldItem.masterViewConfig[this.$props.itemDetails.workflow_id][this.$props.itemDetails.current_status][userGroup];
            try {
              let payload = {
                method: "post",
                url: process.env.VUE_APP_BACKEND_URL + "/displayMasterValue",
                data: {
                  applicationId: this.$route.query.applicationId,
                  token: getFromLocalStorage("token"),
                  authType: getFromLocalStorage("authType"),
                  viewId: fieldItem.view_id
                },
                headers: {
                  "content-type": "application/json",
                },
              };
              let apiResponse = await axios(payload);
              if (apiResponse.data && apiResponse.data.data) {
                this.masterDataList[fieldItem.view_id] = apiResponse.data.data;
                if (this.fields.field_name[fieldItem.key] && this.masterDataList[fieldItem.view_id]) {
                  let masterViewField = this.masterDataList[fieldItem.view_id].find((field) => field._id === this.fields.field_name[fieldItem.key]);
                  if (masterViewField) {
                    this.$set(this.viewData, fieldItem.key, {});
                    this.$set(this.viewData[fieldItem.key], 'display_name', {});
                    // this.viewData[fieldItem.key] = {}
                    for (let key of this.masterViewAccessFields[fieldItem.key]) {
                      if (masterViewField[key]) {
                        this.$set(this.viewData[fieldItem.key], key, masterViewField[key]);
                        this.$set(this.viewData[fieldItem.key]['display_name'], key, apiResponse.data.displayNames[key]);
                        // this.viewData[fieldItem.key][key] = masterViewField[key];
                      }
                    }
                  }
                }
              }
            } catch (apiError) {
              if (
                apiError.response &&
                apiError.response.status &&
                apiError.response.status == 401
              ) {
                this.$root.$emit('update_session_value', true);
              }
              else {
                this.errorMessageShow = true;
                if (apiError.response && apiError.response.data && apiError.response.data.errorObject) {
                  this.errorMessageObject = {
                    errorMessageShow: this.errorMessageShow,
                    error_code: apiError.response.data.errorObject.error_code,
                    error_uuid: apiError.response.data.errorObject.error_uuid,
                  };
                }
                else {
                  this.errorMessageObject = {
                    errorMessageShow: this.errorMessageShow,
                    error_code: "",
                    error_uuid: "",
                  };
                }
                this.showMessageDialog = true;
              }
              this.showSpinner = false;
              this.showAssigneeSection = true;
            }
          }
        }
      }
    },
    formatBreachTime(field, fieldData){
      let breachFormat = 'llll';
      if ('breachTimeConfiguration' in field && 'format' in field['breachTimeConfiguration']) {
        breachFormat = field['breachTimeConfiguration']['format'];
      }
      let showBreachTime = fieldData !== undefined && fieldData !== '';
      if(showBreachTime){
        return moment(fieldData).format(breachFormat);
      } else {
        return '';
      }
    },
    checkFieldHide(fieldItem) {
      if (
        fieldItem.field_access &&
        fieldItem.field_access.hide 
      ) {
        if(fieldItem.field_access.hide['default'] && fieldItem.field_access.hide['default'].includes(getFromLocalStorage("userGroup"))) {
          return false;
        }
        if(fieldItem.field_access.hide[this.$props.itemDetails.workflow_id] && 
          fieldItem.field_access.hide[this.$props.itemDetails.workflow_id]['defaultStage'] && 
          fieldItem.field_access.hide[this.$props.itemDetails.workflow_id]['defaultStage'].includes(getFromLocalStorage("userGroup"))
          ) {
          return false;
        }
        if(fieldItem.field_access.hide[this.$props.itemDetails.workflow_id] && 
          fieldItem.field_access.hide[this.$props.itemDetails.workflow_id][this.$props.itemDetails.current_status] && 
          fieldItem.field_access.hide[this.$props.itemDetails.workflow_id][this.$props.itemDetails.current_status].includes(getFromLocalStorage("userGroup"))
          ) {
          return false;
        }
      }
      return true;
    },
    toggleVisibility(fieldKey){
      this.fileFieldsVisibility[fieldKey] = !this.fileFieldsVisibility[fieldKey];
    }
  },
  mounted() {
    this.screenDetails = this.$props.screenDefinition;
    this.fieldDefinition = this.screenDetails.fieldDetails.fields;
    this.dataItem = this.$props.itemDetails;
    this.fields.field_name = this.dataItem;
    this.applicationId = this.$props.applicationId;
    this.workflow_id = this.dataItem.workflow_id;
    this.file_name = this.dataItem.file_name;
    for(let fieldItem of this.fieldDefinition) {
      if(fieldItem.input_type === "table") {
        this.$set(this.showTable, fieldItem.key, true);
      }
      if(fieldItem.input_type === "multifileupload"){
        if(this.fields.field_name[fieldItem.key] !== ''){
          this.$set(this.fileFieldsVisibility,fieldItem.key, true);
        }
      }
    }
    this.getMasterViewFields();
    let isTableAvailable = this.fieldDefinition.some((field)=> field.input_type == 'table');
    if(isTableAvailable){
      let div = document.querySelector('#divSelector');
      div.addEventListener('scroll', this.handleScroll);
    }
  },
};
</script>

<style scoped>
.items {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.items_sec {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 5px;
  margin: 4px;
}
.heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: rgba(47, 128, 237, 1);
}
.items_sec h5 {
  font-size: 0.8rem;
  padding: 3px;
}

.title_det_cmpont {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  float: left;
  width: 100%;
}
.item_names {
  text-align: right;
  display:flex;
  flex-direction:column;
}

.hover-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	cursor: pointer;
	overflow: none;
}
  
.file-icon {
	padding-left: 0px;
	padding-right: 2px;
	margin: 0px;
}
  
.file-name {
	text-decoration: none;
	color: inherit;
}
  
.hover-container:hover .file-name {
	text-decoration: underline;
	color: #3553B8;
}
  
.hover-container:hover .file-icon {
	filter: brightness(0.8);
}

</style>