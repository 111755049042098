<template>
  <div>
    <div style="display: flex; flex-direction:row; justify-content:space-between;">
      <div style="width:100%;">
        <div v-if="showSpinner">
          <v-overlay color="transparent">
          <SpinnerComponent></SpinnerComponent>
          </v-overlay>
        </div>
        <div v-else class="d-flex flex-column" style="display:flex; flex-direction:row; justify-content:space-between; height:100%;" >
          <div style="display:flex; flex-direction: row; justify-content: space-between; ">
            <div style="display:flex; flex-direction:column; padding-right:10px;" :class="tableClass">
              <v-container class="pl-2.5 py-5 px-3" style="float:left; display:flex; flex-direction:column;">
                <v-row class="py-1" style="display:flex; justify-content:center;" >
                  <div class="btn btn-primary tooltip">
                    <v-text-field
                        style="border: 1px solid #BDBDBD;"
                        solo
                        flat
                        dense
                        hide-details="auto"
                        :label="searchFieldText"
                        clearable
                        clear-icon="mdi-close"
                        @click:clear="getSearchRecordEmpty()"
                        v-model="searchValue"
                        @keyup.enter="getSearchRecord()"
                        append-icon="mdi-magnify"
                        @click:append="getSearchRecord"
                      >
                      </v-text-field>
                    <div class="top">
                      <p>{{searchFieldText}}</p>
                      <i></i>
                    </div>
                  </div>
                  <v-spacer></v-spacer>
                  <div v-if="showPullButton"  :style="{'margin-right': showPullButton ? '15px':'0px' }" >
                    <div>
                      <v-btn  v-if="!hidePullDialog" solo flat tile dence style="border-radius:5px 0px 0px 5px; padding:20px;"  @click="changePullCondition()">  <img src="@/assets/pen.png" /> </v-btn>
                      <v-btn color="green"  @click="openPullDialog()" tile dence style="border-radius:0px 5px 5px 0px; padding:20px;"><span style="color:white;">PULL</span> </v-btn>
                    </div>
                  </div>
                  <template>
                    <v-dialog
                      v-model="pullDialog"
                      max-width="500px"
                    >
                      <v-card>
                        <v-card-title
                          style="border-bottom: 1px solid #bdbdbd; color: #4f4f4f"
                        >
                          {{ $t("Pull Feature") }}
                        </v-card-title>
                        <v-container>
                        <v-row dense>
                          <v-col cols="6" class="pa-5">
                            <v-subheader class="sub-heading">{{$t("Workflow")}} </v-subheader>
                          </v-col>
                          <v-col cols="6" class="pa-5">
                            <v-autocomplete
                              ref="form"
                              solo
                              flat
                              dense
                              :disabled="selectedDisabled"
                              hide-details
                              class="rounded-lg"
                              style="border: 1px solid #bdbdbd"
                              background-color="white"
                              append-icon="mdi-chevron-down"
                              :items="pullWorkFlowItems"
                              filled
                              item-value="workflowId"
                              item-text="workFlowName"
                              label="Select Workflow"
                              v-model="pullSelectedWorkflow"
                            ></v-autocomplete> 
                          </v-col>
                        </v-row>
                        <v-row v-if="showMakerCheckerSelect" dense>
                          <v-col cols="6" class="pa-5">
                            <v-subheader class="sub-heading">{{$t("Pull Condition")}} </v-subheader>
                          </v-col>
                          <v-col cols="6" class="pa-5">
                            <v-autocomplete
                            solo
                            flat
                            dense
                            filled
                            style="border: 1px solid #bdbdbd; height: 50px;"
                              background-color="white"
                            class="rounded-lg"
                            v-model="selectedMakerCheckerCondition"
                            label="Pull Condition"
                            :items="pullConditions"
                          ></v-autocomplete>
                          </v-col>
                        </v-row>
                        </v-container>
                        <!-- <div v-if="showMakerCheckerSelect" style="padding: 10px">
                          <v-autocomplete
                            solo
                            flat
                            class="rounded-lg"
                            v-model="selectedMakerCheckerCondition"
                            :items="pullConditions"
                            label="Pull Condition"
                          ></v-autocomplete>
                        </div> -->
                        <v-card-actions style="border-top: 1px solid #bdbdbd">
                          <v-spacer></v-spacer>
                          <v-btn
                            color="white darken-1"
                            class="pullBtnDsbl"
                            text
                            :disabled="!pullWorkFlowItems.length"
                            @click="clickPull()"
                          >
                            {{$t("Pull")}}
                            <v-icon class="pl-5"> mdi-source-pull </v-icon>
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </template>
                  <div :style="{'display': selectedScope ? 'flex':'none' }"
                    style = "
                      width:15%;
                      min-width: 15%;
                      max-width: 25%;
                      display: flex;
                      flex-direction: row;
                      justify-content: right;
                    "
                  >
                    <div style=" width: 100%; padding:0px 20px 0px 20px;">
                      <v-autocomplete
                        outlined
                        dense
                        hide-details
                        class="rounded-lg"
                        background-color="white"
                        append-icon="mdi-chevron-down"
                        :items="scopes"
                        :item-text="item => item.scope_display_name[$i18n.locale]"
                        item-value="key"
                        label="Scope"
                        v-if="selectedScope"
                        v-model="selectedScope"
                        :disabled="scopes.length===1"
                        @change="fetchData()"
                      ></v-autocomplete>
                    </div>
                  </div>
                  <div v-if="viewType === 'archive' && screenDefinition.showInactivePartitions == true" 
                    style="
                      width: 15%;
                      min-width: 15%;
                      border: 1px solid #bdbdbd;
                      align-items: center;
                      border-radius: 5px;
                      max-width: 50%;
                      height: 45px;
                      background-color: white;
                      flex-direction: row;
                      justify-content: center;
                      margin-bottom: 15px;"
                  >
                  <div style="width: 100%; padding: 0px 10px;">
                    <v-select
                      dense
                      hide-details
                      class="rounded-lg"
                      append-icon="mdi-chevron-down"
                      :items="monthRanges"
                      :item-text="item => item.text"
                      item-value="value"
                      label="Select Month"
                      v-model="selectedMonthRange"
                      chips
                      @change="sendSelectedMonthRange"
                      :disabled="monthRanges.length === 0"
                      clearable
                      @click:clear="onSelectClear" 
                    ></v-select>
                  </div>
                  </div>
                  <div v-if="filterSetsEnabled" :style="{'margin-right': filterSetsEnabled ? '15px':'0px'}" class="btn btn-primary tooltip">
                    <div class="d-flex flex-row mb-6 " style="background-color: white; border-radius: 5px; width: 180px;">
                      <v-autocomplete
                        item-text="displayName.en"
                        item-value="key"
                        style="font-size: 15px; font-weight: 500; height: 40px !important; border-right: 2px solid #E8E8EB; padding-top:5px;"
                        v-model="selectedFilterSet"
                        :items="filterConfig"
                        dense
                        solo-filled
                        flat
                        class="ml-3 mt-0"
                        hide-details
                        placeholder="Select Filter"
                        return-object
                        :disabled="filterConfig.length==1"
                        @change="displayFilterSetDescription()"
                      ></v-autocomplete>
                      <div class="pa-1">
                        <v-switch color="#39FF14" v-model="applyFilter" style="height:25px;" class="mt-0 rounded-0" @change="handleFilterAction()" :disabled="!isFilterSetSelected">
                        </v-switch>
                      </div>
                    </div>
                    <div class="top" v-if="filterSetDescription !== ''">
                      <p>{{filterSetDescription}}</p>
                      <i></i>
                    </div>
                  </div>
                  <div v-if="showAddButtonComputed"  style="width:12%;" :style="{'display': showAddButtonComputed ? 'flex':'none' }">
                    <v-btn
                      class="rounded-lg"
                      depressed
                      color="#2A409A"
                      style="color: white; width:100%; text-transform: none; height:39px; font-size: 16px"
                      @click="clickAdd()"
                    >
                      {{ $t("AddNew") }}
                    </v-btn>
                  </div>
                  <!-- Commented as upload csv currently untested with scope changes -->
                  <!-- <div v-if="showCsvButtonComputed" class="pr-0" style="padding: 10px">
                    <v-btn
                      depressed
                      class="rounded-lg"
                      color="#2A409A"
                      style="color: white; text-transform: none; height:43px"
                      @click="clickUploadCsv()"
                    >
                      {{ $t("UploadCsv") }}
                    </v-btn>
                  </div> -->
                  <div v-if="!showPullButton" :style="{'display': !showPullButton ? 'flex':'none' }" style="padding-left:20px; padding-right:20px; " >
                    <v-btn
                      depressed
                      class="rounded-lg px-3"
                      color="#2A409A"
                      style="color: white; text-transform: none; width:100%; height:39px"
                      @click="resetFilters()"
                      >
                      <v-icon class="pa-0" style="font-size: 20px"> mdi-refresh</v-icon>
                    </v-btn
                    >
                  </div>
                <div v-if = !disableFilter>
                  <div class="pl-0" style="width:50px;" >
                    <div class="filterButton" :class="iscolored ? 'filterBtnColored' : ''" 
                      depressed @click="getFilterableField()">
                      <v-icon style="font-size:1rem;" :class="whiteIconFeature ? 'whiteIcon' : 'blackIcon' " > mdi-tune-vertical </v-icon> 
                    </div>                                                    
                    </div>
                  </div>  
                </v-row>
             </v-container>
              <div style="width:100%;">
                <v-overlay :value="showPaginationSpinner">
                  <SpinnerComponent></SpinnerComponent>
                </v-overlay>
                <v-data-table
                  style="width:100%; justify-content:center; align-items: center;"
                  height="calc(90vh - 180px)"
                  :headers="headers"
                  :items="dataItems"
                  fixed-header
                  class="elevation-2 customStyle rounded-lg"
                  disable-pagination
                  hide-default-footer
                  multi-sort
                >
                  <template  style="color:white; border:1px solid;"  v-slot:[`item.issue_type`]="{ item }">
                    <v-chip   :color="getColor(item)">{{ item.issue_type }}</v-chip>
                  </template>
                  <template 
                    v-slot:[`item.consolidated_overall_breach_status`]="{ item }"
                  >
                    <div v-if="item.consolidated_overall_breach_status">
                      <v-chip   color="#EB5757">{{$t("Breached")}} </v-chip>
                    </div>
                    <div v-else>
                      <v-chip>{{$t("Not Breached")}} </v-chip>
                    </div>
                  </template>
                  <template 
                  v-slot:[`item.consolidated_stage_breach_status`]="{ item }"
                  >
                    <div v-if="item.consolidated_stage_breach_status">
                      <v-chip   color="#EB5757">{{$t("Breached")}} </v-chip>
                    </div>
                    <div v-else>
                      <v-chip  >{{$t("Not Breached")}}</v-chip>
                    </div>
                  </template>
                  <template v-slot:[`item.current_status`]="{ item }">
                    <h4 :style="getStyle(item.current_status)">
                      {{ item.status_text[$i18n.locale] }}
                    </h4>
                  </template>
                  <template v-for= "currency of currencyFields" v-slot:[`item.${currency.key}`]="{ item }">
                    <div>
                      {{formatCurrency(currency.key,item)}}
                    </div>
                  </template>
                  <template v-for= "datetimeField of datetimeFields" v-slot:[`item.${datetimeField.key}`]="{ item }">
                    <div>
                      {{formatDatetime(datetimeField,item[datetimeField.key], item.AdditionalFieldInformation)}}
                    </div>
                  </template>
                  <template v-for= "multiselectField of multiselectFields" v-slot:[`item.${multiselectField.key}`]="{ item }">
                    <div>
                      {{formatMultiselect(item[multiselectField.key], multiselectField.key)}} 
                    </div>
                  </template>
                  <template v-for= "slaBreachField of slaBreachTimeFields" v-slot:[`item.${slaBreachField.key}`]="{ item }">
                    <div>
                      {{formatBreachTime(slaBreachField,item[slaBreachField.key])}}
                    </div>
                  </template>
                  <template v-for= "alert of alertDateTime" v-slot:[`item.${alert.key}`]="{ item }">
                    <div>
                      {{formatAlertDateTime(alert.key,item)}}
                    </div>
                  </template>
                  <template v-slot:[`item.CreatedAt`]="{ item }">
                    <div v-if="item.CreatedAt">
                      {{ formatDate(item.CreatedAt) }}
                    </div>
                  </template>
                  <template v-slot:[`item.UpdatedAt`]="{ item }">
                    <div v-if="item.UpdatedAt">
                      {{ formatDate(item.UpdatedAt) }}
                    </div>
                  </template>
                  <template v-for="availableField in availableNumberInputFields" v-slot:[`item.${availableField}`]="{ item }">
                    <div v-if="item[availableField]">
                      {{ numberFormatter(item[availableField], availableField) }}
                    </div>
                  </template>
                  <template v-if="showPriority" v-slot:[`item.priority`]="{ item }">
                    {{ formatPriority(item.priority) }}
                  </template>
                  <template v-slot:[`item.status_action`]="{ item, header }">
                    <div :style="header.width === undefined ? 'display:flex' : ''">
                      <div
                        v-for="action in item.status_action"
                        style="margin: 4px 4px; display: inline;"
                        v-if="showActionOnConditionBtn(action, item) && checkShowActionForCreator(action, item)"
                      >
                        <v-btn
                          depressed
                          :color="getButtonColor(action)"
                          style="color: white; margin: 4px 0px;text-transform: none;
                            height: 29px;
                            padding: 4px 8px;
                            min-width: 0px;
                            font-size:14px;
                            border-radius: 4px;"
                          @click="
                          changeWorkFlow(
                            action,
                            item
                            )
                            "
                          >{{ action.displayText[$i18n.locale] }}</v-btn
                        >
                      </div>
                    </div>
                  </template>
                  <template v-slot:[`item.assignee`]="{ item }">
                    <div v-if="item.assigneeUserName" style="display:flex;flex-direction: row;">
                      {{item.assigneeUserName}}
                    </div>
                    <div v-else>
                      {{item.assignee}}
                    </div>
                  </template>
                  <template v-slot:[`item.created_by`]="{ item }">
                    <div v-if="item.createdByName" style="display:flex;flex-direction: row;">
                     {{item.createdByName}}
                    </div>
                    <div v-else>
                      {{item.created_by}}
                    </div>
                  </template>
                  <template v-slot:[`item.updated_by`]="{ item }">
                    <div v-if="item.updatedByName" style="display:flex;flex-direction: row;">
                     {{item.updatedByName}}
                    </div>
                    <div v-else>
                      {{item.updated_by}}
                    </div>
                  </template>
                  <template style=" width:100px;" v-slot:[`item.actions`]="{ item }">
                    <div style="display:flex; flex-wrap:no-wrap; flex-direction: row-reverse; width:100px;  justify-content:space-between;">
                      <div style="width:50px; padding:5px;"
                        v-if="item.showNotesButton"
                        @click="openNotesDialog(item)"
                        >
                        <v-badge
                            :value="item.hasComment === true"
                            color="#FF6B00"
                            offset-x="5"
                            offset-y="5"
                            dot
                          >
                          <img style=" width:100%;" src="../assets/Comment.svg" />
                        </v-badge> 
                      </div>

                      <div @click="clickView(item)" style="width:50px;  padding:5px;" >
                        <img style=" width:100%;" src="../assets/View.svg" />
                      </div>

                      <div style="width: 50px; padding:5px; cursor: pointer;"
                        v-if="item.showEditButton"
                        @click="clickUpdate(item)"
                        >
                        <img style="width:100%;" src="../assets/Edit.svg" />
                      </div>

                      <div style="width:50px; padding:5px; cursor: pointer;" v-if="toggleReAssignee(item)" @click="openReAssigneeSection(item)" >
                        <img style="width:100%;" src="../assets/UpdateAssignee.svg" />
                      </div>
                    </div>
                  </template>
                  <template v-slot:footer>
                  <div style="text-align: right" class="pt-5" v-if="showLoadMore">
                      <v-btn
                        depressed
                        class="rounded-lg"
                        color="#2A409A"
                        style="color: white; width: 20%"
                        @click="getMoreData()"
                        >{{$t("Load More")}}
                      </v-btn>
                    </div>
                  </template>
                </v-data-table>
              </div>
            </div>

            <div v-if="showFilterComponent" style="height:78vh; " :class="filterClass">
              <div style="background-color:white; display:flex; flex-direction:column; justify-content:space-between; width:100%; height:100%;">

                <div style="display:flex; padding:5px 10px; height:10%; border-bottom:2px solid #E0E0E0; background-color:white; flex-direction:row; justify-content:space-between; align-items:center;">
                  <h3 style="padding-top:10px; padding-left:20px;">Filter</h3> 
                  <div >
                    <v-icon @click="closeFilter()" style="color: red; padding-right:10px; cursor: pointer">mdi-close</v-icon>
                  </div>
                </div>
                <div style="width:100%; height:80%; overflow-y:scroll;" class="filter_fields">
                  <div style="width:100%;">
                    <template>
                      <v-container fluid >
                        <v-autocomplete
                          outlined 
                          :menu-props="{offsetY: true}"  
                          deletable-chips 
                          small-chips 
                          dense 
                          hide-details  
                          background-color="white"
                          v-model="selectedWorkNames"
                          :items="workFlowItems"
                          @input="searchInput=null"
                          item-value="workflowId"
                          item-text="workFlowName"
                          label="Select WorkFlow"
                          @change="fetchAllStatus()"
                          multiple
                          height="50px"
                        >
                          <template v-slot:prepend-item > 
                            <v-list-item              
                              ripple
                              @mousedown.prevent
                              @change="toggleOne" 
                              style="margin:-13px 10px -5px 10px; padding:7px;"                       
                            >
                              <v-list-item-action>
                                <v-icon :color="selectedWorkNames.length > 0 ? 'indigo darken-4' : ''" >
                                  {{ iconOne }}
                                </v-icon>
                              </v-list-item-action>
                              <v-list-item-content>
                                <v-list-item-title>
                                  Select All
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-divider class="mt-2"></v-divider>
                          </template> 
                            <template v-slot:selection="{ item, index }">
                              <span v-if="index === 0" class="blue--text text-caption">
                                <v-chip style="width:90px;" deletable-chips small-chips hide-details>{{ellipsisText(item.workFlowName, 7)}}</v-chip> 
                                <v-chip v-if="selectedWorkNames.length>1" style="width:120px;" deletable-chips small-chips hide-details>(+{{selectedWorkNames.length-1}}){{"Selected"}}</v-chip> 
                              </span>
                            </template>
                        </v-autocomplete>
                      </v-container>
                    </template> 
                  </div>

                  <div style="width:100%;">
                    <template>
                      <v-container fluid>
                        <v-autocomplete
                          outlined :menu-props="{offsetY: true}" deletable-chips small-chips dense hide-details  background-color="white"
                          v-model="selectedStatusOnes"
                          :items="statusItems"
                          @input="searchInput=null"
                          label="Status"
                          multiple
                          height="50px" 
                        >
                          <template v-slot:prepend-item> 
                            <v-list-item
                              v-if="showStatus== null || !showStatus" 
                              ripple
                              @mousedown.prevent
                              @change="toggleTwo"                        
                            >
                              <v-list-item-action>
                                <v-icon :color="selectedStatusOnes.length > 0 ? 'indigo darken-4' : ''" >
                                  {{ iconTwo }}
                                </v-icon>
                              </v-list-item-action>
                              <v-list-item-content>
                                <v-list-item-title>
                                  Select All
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-divider class="mt-2"></v-divider>
                          </template> 
                           <template v-slot:selection="{ index }" >
                              <span style=""
                                v-if="index === 0"
                                class="grey--text text-caption"
                                >
                                <v-chip  style=" width:90px;">{{selectedStatusOnes[0] | ellipsis}}</v-chip>
                                <v-chip v-if="selectedStatusOnes.length>1" style=" width:120px;">(+{{selectedStatusOnes.length-1}}){{"Selected"}}</v-chip>
                              </span>
                            </template>
                        </v-autocomplete>
                      </v-container>
                    </template> 
                  </div>

                  <div v-if="showAssignedToGroupItems" style="width:100%;">
                    <template>
                      <v-container fluid>
                        <v-autocomplete
                          :close-on-select="false"
                          outlined :menu-props="{offsetY: true}" deletable-chips small-chips dense hide-details  background-color="white"
                          v-model="selectedAssignedToGroups"
                          :items="assignedToGroupItems"
                          label="Select Assigned Group"
                          @input="searchInput=null"
                          @change="fetchAssigneesOnChange()"
                          multiple
                          height="50px"
                        >
                          <template v-slot:prepend-item> 
                            <v-list-item
                              v-if="showGroup== null || !showGroup" 
                              ripple
                              @mousedown.prevent
                              @change="toggleThree"                        
                            >
                              <v-list-item-action>
                                <v-icon :color="selectedAssignedToGroups.length > 0 ? 'indigo darken-4' : ''" >
                                  {{ iconThree }}
                                </v-icon>
                              </v-list-item-action>
                              <v-list-item-content>
                                <v-list-item-title>
                                  Select All
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-divider class="mt-2"></v-divider>
                          </template>
                           <template v-slot:selection="{ index }" >
                              <span v-if="index === 0" class="grey--text text-caption" >
                                <v-chip style=" width:90px;">{{selectedAssignedToGroups[0] | ellipsis}}</v-chip>
                                <v-chip v-if="selectedAssignedToGroups.length>1" style="width:120px; ">(+{{selectedAssignedToGroups.length-1}}){{"Selected "}}</v-chip>
                              </span>
                            </template> 
                        </v-autocomplete>
                      </v-container>
                    </template> 
                  </div>

                  <div v-if="showAssigneeItems" style="width:100%;">
                    <template>
                      <v-container fluid>
                        <v-autocomplete  
                          :close-on-select="false"
                          v-model="selectedAssignees"
                          outlined :menu-props="{offsetY: true}" 
                          deletable-chips
                          small-chips
                          dense
                          hide-details  
                          background-color="white"
                          :items="assigneeItems"
                          label="Select Assignee"
                          @input="searchInput=null"
                          multiple
                          color="rgb(42, 64, 154)"
                           height="50px"
                        > 
                          <template v-slot:prepend-item > 
                            <v-list-item
                              v-if="showAssign== null || !showAssign" 
                              ripple
                              @mousedown.prevent
                              @change="toggleFour"                        
                            >
                              <v-list-item-action>
                                <v-icon :color="selectedAssignees.length > 0 ? 'indigo darken-4' : ''" >
                                  {{ iconFour }}
                                </v-icon>
                              </v-list-item-action>
                              <v-list-item-content>
                                <v-list-item-title>
                                  Select All
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-divider class="mt-2"></v-divider>
                          </template> 
                        <template v-slot:selection="{ index }" >
                          <span style="" class="blue--text text-caption" v-if="index === 0">
                            <v-chip  style=" width:90px;">{{selectedAssignees[0] | ellipsis}}</v-chip>
                            <v-chip  v-if="selectedAssignees.length>1" style="width:120px; ">(+{{selectedAssignees.length-1}}){{"Selected"}}</v-chip>
                          </span> 
                        </template>
                        </v-autocomplete>
                      </v-container>
                    </template>     
                  </div>

                  <div v-if="showCreatedByGroup" style="width:100%;">
                    <template>
                      <v-container fluid>
                        <v-autocomplete
                          :close-on-select="false"
                          outlined :menu-props="{offsetY: true}" deletable-chips small-chips dense hide-details  background-color="white"
                          v-model="selectedCreatedByGroups"
                          :items="createdByGroupItems"
                          label="Select Created By Group"
                          @input="searchInput=null"
                          @change="fetchCreatedByUsersOnChange()"
                          multiple
                          height="50px"
                        >
                        <template v-slot:prepend-item> 
                            <v-list-item
                              ripple
                              @mousedown.prevent
                              @change="toggleFive"                        
                            >
                              <v-list-item-action>
                                <v-icon :color="selectedCreatedByGroups.length > 0 ? 'indigo darken-4' : ''" >
                                  {{ iconFive }}
                                </v-icon>
                              </v-list-item-action>
                              <v-list-item-content>
                                <v-list-item-title>
                                  Select All
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-divider class="mt-2"></v-divider>
                          </template>
                           <template v-slot:selection="{ index }" >
                              <span v-if="index === 0" class="grey--text text-caption" >
                                <v-chip style=" width:90px;">{{selectedCreatedByGroups[0] | ellipsis}}</v-chip>
                                <v-chip v-if="selectedCreatedByGroups.length>1" style="width:120px; ">(+{{selectedCreatedByGroups.length-1}}){{"Selected "}}</v-chip>
                              </span>
                            </template> 
                        </v-autocomplete>
                      </v-container>
                    </template> 
                  </div>

                  <div v-if="showCreatedByGroup" style="width:100%;">
                    <template>
                      <v-container fluid>
                        <v-autocomplete
                          :close-on-select="false"
                          outlined :menu-props="{offsetY: true}" deletable-chips small-chips dense hide-details  background-color="white"
                          v-model="selectedCreatedByUsers"
                          :items="createdByItems"
                          label="Select Created By"
                          @input="searchInput=null"
                          @change="selectCreatedBy()"
                          multiple
                          height="50px"
                        >
                        <template v-slot:prepend-item> 
                            <v-list-item
                              ripple
                              @mousedown.prevent
                              @change="toggleSix"                        
                            >
                              <v-list-item-action>
                                <v-icon :color="selectedCreatedByUsers.length > 0 ? 'indigo darken-4' : ''" >
                                  {{ iconSix }}
                                </v-icon>
                              </v-list-item-action>
                              <v-list-item-content>
                                <v-list-item-title>
                                  Select All
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-divider class="mt-2"></v-divider>
                          </template>
                           <template v-slot:selection="{ index }" >
                              <span v-if="index === 0" class="grey--text text-caption" >
                                <v-chip style=" width:90px;">{{selectedCreatedByUsers[0] | ellipsis}}</v-chip>
                                <v-chip v-if="selectedCreatedByUsers.length>1" style="width:120px; ">(+{{selectedCreatedByUsers.length-1}}){{"Selected "}}</v-chip>
                              </span>
                            </template> 
                        </v-autocomplete>
                      </v-container>
                    </template> 
                  </div>

                  <div style="width:100%; padding:13px;">
                    <v-menu  v-model="menu1" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto" >
                      <template v-slot:activator="{ on,  atters }">
                        <v-text-field  height="50px" outlined hide-details clearable v-model="startDate" dense solo  flat clear-icon="mdi-close" :label="$t('From Date')"
                          prepend-inner-icon="mdi-calendar" readonly v-bind="atters" v-on="on" ></v-text-field>
                      </template>
                      <v-date-picker @change="enableToDate()"
                        v-model="startDate" :max="maxdate" @input="menu1 = false"
                      ></v-date-picker>
                    </v-menu>
                  </div>

                  <div style="width:100%; padding:13px;">
                    <v-menu  v-model="menu2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto" >
                      <template v-slot:activator="{ on, atters}">
                        <v-text-field :disabled="disableToDate" height="50px" hide-details outlined clearable v-model="endDate" solo dense flat clear-icon="mdi-close" :label="$t('To Date')"
                          prepend-inner-icon="mdi-calendar" readonly v-bind="atters"  v-on="on"  ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="endDate" @change="validateFIlterDate()" :max="maxdate" :min="startDate" @input="menu2 = false"
                      ></v-date-picker>
                    </v-menu>
                  </div> 

                  <div v-for="field in filterableFields" style="width:100%; padding:13px;">
                    <div v-if="showFilterField">
                      <!-- since only in priority field we have a different structure for optionvalues we are having ternary operators at item-text and ellipsisText -->          
                      <v-autocomplete 
                        outlined 
                        :menu-props="{offsetY: true}" 
                        color="rgb(42, 64, 154)" 
                        deletable-chips 
                        small-chips 
                        dense 
                        hide-details
                        class="rounded-lg"
                        background-color="white"
                        append-icon="mdi-chevron-down"
                        :items="field.option_values"
                        multiple
                        chips
                        item-value="key"
                        :item-text="field.key ==='priority' ? 'displayText': 'value'"
                        @input="searchInput=null"
                        v-model="selectedFilterableFields[field.key]"
                        :label="field.display_name"  
                        @change="selectOptions(field)"
                        height="50px"
                      >
                        <template v-slot:prepend-item >
                          <v-list-item
                            ripple
                            @mousedown.prevent
                          >
                            <v-list-item-content >
                              <v-list-item-title>
                                <div style="display:flex; align-items:center; height:30px;">
                                  <v-checkbox 
                                    v-model="allSelected[field.key]"  
                                    @click="selectAll(field)"              
                                  ></v-checkbox>
                                  <p style="padding-top:12px; padding-left:20px;">Select All</p>
                                </div>
                              </v-list-item-title> 
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider class="mt-2"></v-divider>
                        </template> 
                        <template v-slot:selection="{item, index }" >
                          <span v-if="index === 0" class="blue--text text-caption">
                            <v-chip :title="item" deletable-chips hide-details style="width:90px;">{{field.key ==='priority' ? ellipsisText(item['displayText'], 7):  (field.input_type == 'options' && field.viewType == 'reference') ? ellipsisText(item.value, 7) : ellipsisText(item, 7)}}</v-chip>
                            <v-chip deletable-chips v-if="selectedFilterableFields[field.key].length>1" hide-details style="width:120px; ">(+{{selectedFilterableFields[field.key].length-1}}){{"Selected"}}</v-chip>
                          </span>
                        </template>
                      </v-autocomplete> 
                    </div> 
                  </div>
                </div>

                <div style="display:flex; flex-direction:row; height:10%; width:100%; padding:15px 0px 15px 0px; border-top:2px solid #E0E0E0; justify-content:space-around; align-items:center;  bottom:0;" >
                  <div style="width:36%;">
                    <v-btn class="" elevation="0"  style=" border:1px solid gray; height:39px; width:100%;" @click="resetFilters()">
                      {{$t("Reset")}}
                    </v-btn>
                  </div>
                  <div style="width:36%;">
                    <v-btn class="" elevation="0" style=" background-color:rgb(42, 64, 154); color:white; height:39px; width:100%;" @click="applyFilters()">
                      {{$t("Apply")}}
                    </v-btn>
                  </div>
                </div>

              </div>
            </div> 
          </div>
        </div>
      </div>
    </div>

    <div v-if="noRecordDialog">
      <NoRecordFound
        :noRecordText="noRecordText"
      ></NoRecordFound>
    </div>

    <div v-if="showDialog">
      <Dialog
        :screenDefinition="screenDefinition"
        :dialogObject="dialogObject"
        :selectedScope="selectedScope"
        @closeDialog="closeDialog"
      ></Dialog>
    </div>

    <div  v-if="showNotesDialog">
      <notesOnRecordDialogComponent
      :notesDialogProps="notesDialogProps"
      @closeNotesDialog="closeNotesDialog"
      :viewType="viewType"
      @updateShowCommentBox="updateShowCommentBox"
      ></notesOnRecordDialogComponent>
    </div>
    <div v-if="showUpdateDialog">
      <UpdateFieldDialog
        :screenDefinition="screenDefinition"
        :fieldDetails="screenDefinition.fieldDetails.fields"
        :fieldUpdateItem="fieldUpdateItem"
        :updateFieldDialogObject="updateFieldDialogObject"
        @closeUpdateFieldDialog="closeUpdateFieldDialog"
        :selectedScope="selectedScope"
      ></UpdateFieldDialog>
    </div>

    <div v-if="showWorkFlowFieldDialog">
      <WorkFlowFieldDialog
        :fieldObject="fieldObject"
        :showIndependentFields="showIndependentFields"
        :screenDefinition="screenDefinition"
        :itemDetails="itemDetails"
        @closeWorkFlowDialog="closeWorkFlowDialog"
        :selectedScope="selectedScope"
      ></WorkFlowFieldDialog>
    </div>

    <div v-if="showSidePanel">
      <DetailsComponent
        :isDownload = "isDownload"
        :applicationName = "applicationName"
        :screenDefinition="screenDefinition"
        :dialogObject="dialogObject"
        :itemDetails="itemDetails"
        :workFlowItems = "workFlowItems"
        :viewId="viewId"
        :latestAssignee="lastestAssigneeConfig"
        :latestAssigneeFlag="latestAssigneeFlag"
        :selectedScope="selectedScope"
        :showIndependentFields="showIndependentFields"
        @closeSidePanel="closeSidePanel"
        @closeDetailsComponent="closeDetailsComponent"
      >
      </DetailsComponent>
    </div>
    <div v-if="showConfirmationDialog">
      <ConfirmationDialog
        :confirmationDialogObject="confirmationDialogObject"
        @closeDialog="closeConfirmationDialog"
      ></ConfirmationDialog>
    </div>
    <div v-if="showErrorValidationDialog">
      <ErrorValidationDialog
        :displayErrorValidationDialog="showErrorValidationDialog"
        :errorValidationDialogObject="errorValidationDialogObject"
        @closeErrorValidationDialog="closeErrorValidationDialog"
      />
    </div>
    <div v-if="showCsvUploadDialog">
      <CsvUploadDialog
        :screenDefinition="screenDefinition"
        :csvDialogObject="csvDialogObject"
        @closeCsvDialog="closeCsvDialog"
      ></CsvUploadDialog>
    </div>
    <div v-if="showMessageDialog">
      <MessageDialog
        :displayDialog="showMessageDialog"
        :messageObject="errorMessageShow ? errorMessageObject : okMessageObject"
        @closeMessageDialog="closeMessageDialog"
      ></MessageDialog>
    </div>
    <div v-if="showMoreItemsPopup">
      <ShowMoreItemsPopup
        @closeShowMorePopup="closeShowMorePopup"
      ></ShowMoreItemsPopup>
    </div>
    <div v-if="reAssigneeUpdate">
      <AssigneeUpdateComponent
        :updateFieldDialogObject="reAssigneeUpdateDetails"
        :fieldDefinition="screenDefinition.fieldDetails.fields"
        @closeReAssigneeUpdate="closeReAssigneeUpdate"
      ></AssigneeUpdateComponent>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment-timezone";
import Dialog from "./Dialog.vue";
import SidePanel from "./SidePanelComponent.vue";
import SpinnerComponent from "./SpinnerComponent.vue";
import ConfirmationDialog from "./ConfirmationDialog.vue";
import ShowMoreItemsPopup from './ShowMoreItemsPopup.vue';
import notesOnRecordDialogComponent from './notesOnRecordDialogComponent.vue';
import WorkFlowFieldDialog from "./workFlowFieldDialog.vue";
import DetailsComponent from "./DetailsComponent.vue";
import ErrorValidationDialog from "./ErrorValidationDialog.vue";
import CsvUploadDialog from "./CsvUploadDialog.vue";
import UpdateFieldDialog from "./UpdateFieldDialog.vue";
import MessageDialog from "./MessageDialog.vue";
import AssigneeDisplayNameComponent from "./AssigneeDisplayNameComponent.vue";
import NoRecordFound from "../components/NoRecordFound.vue";
import Sidebar from "../components/SideBar.vue"
import AssigneeUpdateComponent from "./AssigneeTicketUpdate.vue"
import { currencyFormat } from "../mixins/currencyFormat.js";
import {getFromLocalStorage, setToLocalStorage} from "../store/localStorageInterface"
export default {
  props: ["workFlowScreenDetails", "closeSidebarFilter","applicationName"],
  components: {
    Dialog,
    SidePanel,
    SpinnerComponent,
    notesOnRecordDialogComponent,
    ConfirmationDialog,
    WorkFlowFieldDialog,
    DetailsComponent,
    ErrorValidationDialog,
    CsvUploadDialog,
    UpdateFieldDialog,
    MessageDialog,
    AssigneeDisplayNameComponent,
    NoRecordFound,
    Sidebar,
    ShowMoreItemsPopup,
    AssigneeUpdateComponent
  },
  data() {
    return {
      notesDialogProps: {},
      selectedMonthRange: '',
      monthRanges: [
          { text: 'Jun 2023', value: '2023-06' },
          { text: 'May 2023', value: '2023-05' },
          { text: 'Apr 2023', value: '2023-04' },
          { text: 'Mar 2023', value: '2023-03' },
          { text: 'Feb 2023', value: '2023-02' },
          { text: 'Jan 2023', value: '2023-01' },
          { text: 'Dec 2022', value: '2022-12' },
          { text: 'Nov 2022', value: '2022-11' },
          { text: 'Oct 2022', value: '2022-10' },
          { text: 'Sep 2022', value: '2022-09' },
          { text: 'Aug 2022', value: '2022-08' },
          { text: 'Jul 2022', value: '2022-07' },
          { text: 'Jun 2022', value: '2022-06' }
      ],
      consolidateParentFieldDefinition:{},
      disableToDate:true,
      showIndependentFields:false,
      toggleSideNav:true,
      showWorkFlow: null,
      showStatus: null,
      showGroup: null,
      showAssign: null, 
      searchTerm: "",
      iscolored: false,
      viewType: "",
      iscoloredCalender: false,
      whiteIconCalender: false,
      whiteIconFeature: false,
      selectedDisabled: false,
      pullBtn: false,
      pullDialog: false,
      errorValidation: [],
      headers: [],
      dataItems: [],
      stageList: "",
      fieldObject: {},
      showMessageDialog: false,
      showSpinner: true,
      showSidePanel: false,
      showAddButton: false,
      showPaginationSpinner: false,
      screenDefinition: "",
      workFlowItems: [],
      workFlowItemsCopy: [],
      pullWorkFlowItems: [],
      pullWorkflowItemsSelected: [],
      assignedToGroupItems: [],
      assignedToGroupItemsCopy: [],
      assigneeItems: [],
      assigneeItemsCopy: [],
      statusItems: [],
      statusItemsCopy: [],
      showErrorValidationDialog: false,
      showDialog: false,
      showPullButton: false,
      showLoadMore: false,
      confirmationDialogObject: {},
      showConfirmationDialog: false,
      showWorkFlowFieldDialog: false,
      showUpdateDialog: false,
      showNotesDialog: false,
      itemDetails: "",
      statusValue: "",
      isDownload:true,
      showMakerCheckerSelect: false,
      dialogObject: {},
      searchFieldText: "Search by ID",
      errorValidationDialogObject: {
        data: {
          errorList: [],
        },
      },
      lastEvaluatedKey: "",
      selectedWorkflow: "ALL",
      selectedWorkNames: [],
      selectedSearchWorkflow: "",
      selectedSearchStatus: "",
      selectedSearchGroup: "",
      selectedSearchAssign: "",
      pullSelectedWorkflow: "",
      selectedAssignedToGroup: "ALL",
      selectedAssignedToGroups: [],
      selectedAssignee: "ALL",
      selectedAssignees: [],
      selectedStatus: "ALL",
      selectedStatusOnes: [],
      selectedMakerCheckerCondition: "",
      userType: "",
      updateFieldDialogObject: {},
      userGroup: "",
      showAssignedToGroupItems: false,
      showAssigneeItems: false,
      showFilterField:false,
      userGroupItems: [],
      viewId: "",
      viewType: "",
      pullConditions: ["Maker", "Checker"],
      userGroupSelected: "",
      showCsvUploadDialog: false,
      csvDialogObject: {},
      showUploadCsvButton: false,
      showFilters: false,
      showFilterDate: false,
      searchValue: "",
      startDate: "",
      endDate: "",
      lastestAssigneeConfig:{},
      latestAssigneeFlag : false,
      workflowDisable: false,
      maxdate: new Date().toISOString().slice(0, 10),
      isActive: false,
      noActive: true,
      dateValue: false,
      selectedScope: "",
      filterDateAlert: false,
      filterSetDescription: "",
      showCreatedByGroup: false,
      createdByGroupItems: [],
      selectedCreatedByGroups: [],
      createdByItems: [],
      selectedCreatedByUsers: [],
      numberRule: (searchValue) => {
        if (!searchValue) {
          return true;
        }

        if (searchValue.match(/^[0-9]+$/)) {
          if (searchValue.length < 6) {
            return "Record id length must be 6";
          } else {
            return true;
          }
        } else if (typeof searchValue === "string") {
          return "Please enter Valid record Id";
        } else {
          return "Please enter Valid record Id";
        }
      },
      showMessageDialog: false,
      errorMessageShow: false,
      errorMessageObject: {},
      okMessageObject: {},
      scopeAddAccess:{},
      noRecordDialog: false,
      noRecordText: "",
      menu1: false,
      menu2: false,
      activeButton: false,
      filterableField:[],
      filterableFields:[],
      selectedFilterableFields:{}, 
      drawer: false, 
      showFilterComponent:false,
      allSelected:{},
      tableClass: "col-12",
      filterClass: "col-0",
      availableNumberInputFields: [],
      currencyFields:[],
      datetimeFields:[],
      alertDateTime:[],
      multiselectFields:[],
      showMoreItemsPopup: false,
      isPullDone:false,
      gettimezone:"",
      showPriority: false,
      hidePullDialog:false,
      slaBreachTimeFields: [],
      filterSetsEnabled: false,
      filterSetEnabledWithRoleDef: {},
      filterConfig: [],
      availableFilterSetsList: {},
      selectedFilterSet: {},
      applyFilter: false,
      display: [],
      isFilterSetSelected: false,
      filterOptionModified: false,
      reAssigneeUpdate: false,
      reAssigneeUpdateFlag: false,
      reAssigneeUpdateDetails: {},
      assigneeFieldItem: [],
      fieldDefinition: {},
      slaOptions: ['Breached', 'Not Breached'],
      selectedOverallbreach: [],
      selectedStagebreach: [],
      disableFilter: false,
      recordItemCounter:0,
      recordDataObject: {

      }
    };
  },
  mixins: [currencyFormat],
  computed: { 
    selectedAllWorkFlows() {
      return this.selectedWorkNames.length === this.workFlowItems.length;
    },
    selectedSomeWorkFlows() {
      return this.selectedWorkNames.length > 0 && !this.workFlowItems;
    },
    iconOne() {
      if (this.selectedAllWorkFlows) return "mdi-close-box";
      if (this.selectedSomeWorkFlows) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    selectedAllStatus() {
      return this.selectedStatusOnes.length === this.statusItems.length;
    },
    selectedSomeStatus() {
      return this.selectedStatusOnes.length > 0 && !this.statusItems;
    },
    iconTwo() {
      if (this.selectedAllStatus) return "mdi-close-box";
      if (this.selectedSomeStatus) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    selectedAllAssignedGroups() {
      return (
        this.selectedAssignedToGroups.length ===
        this.assignedToGroupItems.length
      );
    },
    selectedSomeAssignedGroups() {
      return (
        this.selectedAssignedToGroups.length > 0 && !this.assignedToGroupItems
      );
    },
    iconThree() {
      if (this.selectedAllAssignedGroups) return "mdi-close-box";
      if (this.selectedSomeStatus) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    iconFive() {
      if (this.selectedCreatedByGroups.length ===this.createdByGroupItems.length) return "mdi-close-box";
      if (this.selectedCreatedByGroups.length > 0 && !this.createdByGroupItems) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    iconSix() {
      if (this.selectedCreatedByUsers.length ===this.createdByItems.length) return "mdi-close-box";
      if (this.selectedCreatedByUsers.length > 0 && !this.createdByItems) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    selectedAllAssignees() {
      return this.selectedAssignees.length === this.assigneeItems.length;
    },
    selectedSomeAssignees() {
      return this.selectedAssignees.length > 0 && !this.assigneeItems;
    },
    iconFour() {
      if (this.selectedAllAssignees) return "mdi-close-box";
      if (this.selectedSomeAssignees) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    showAddButtonComputed: {
      cache: false,
      get: function () {
        if (this.scopes && this.scopes.length > 0) {
          if (this.selectedScope && this.selectedScope != "ALL") {
            if (this.scopeAddAccess && Object.keys(this.scopeAddAccess).length > 0) {
              if (this.selectedScope in this.scopeAddAccess) {
                return this.showAddButton;
              }
              return false;
            } else {
              return this.showAddButton;
            }
          } else {
            return false;
          }
        } else {
          return this.showAddButton;
        }
      },
    },
    showCsvButtonComputed: {
      cache: false,
      get: function () {
        if (this.scopes && this.scopes.length > 0) {
          if (this.selectedScope && this.selectedScope != "ALL") {
            return this.showUploadCsvButton;
          } else {
            return false;
          }
        } else {
          return this.showUploadCsvButton;
        }
      },
    },
  },
  methods: {
    sendSelectedMonthRange() {
      this.fetchData();
    },
    onSelectClear() {
      this.selectedMonthRange = '';
      this.fetchData();
    },
    openReAssigneeSection(item){
      this.reAssigneeUpdate = true;
      this.reAssigneeUpdateDetails["fields"] = item;
      this.reAssigneeUpdateDetails["display"] = true;
      this.reAssigneeUpdateDetails["viewId"] = this.viewId;
      this.reAssigneeUpdateDetails["viewType"] = "workflow";
    },
    closeReAssigneeUpdate(){
      this.reAssigneeUpdate = false;
      this.fetchData();
    },
    updateShowCommentBox(value) {
      this.viewType = value;
    },
    toggleReAssignee(item){
      var showReAssgineeFlagTwo = false;
      var showReAssignButton = item['showReAssignButton'];
      var workflowId = item["workflow_id"]
      if (this.assigneeFieldItem.hasOwnProperty("field_access")) {
        if (this.assigneeFieldItem["field_access"].hasOwnProperty("update")) {
          if (
            this.assigneeFieldItem["field_access"]["update"].hasOwnProperty(
              workflowId
            )
          ) {
            if (
              this.assigneeFieldItem["field_access"]["update"][item['workflow_id']].hasOwnProperty(item["current_status"])) {
            if (
              this.assigneeFieldItem["field_access"]["update"][item['workflow_id']][item["current_status"]].includes(
                getFromLocalStorage("userGroup")
              )
            ) {
              showReAssgineeFlagTwo = true;
            }
            }
          }
        }
      }
      if(showReAssgineeFlagTwo == true && showReAssignButton == true && this.reAssigneeUpdateFlag == true){
        return true;
      }
      return false;

    },
    checkAndAddConsolidateMainField(dataItems) {
        if(Object.keys(this.consolidateParentFieldDefinition).length > 0) {
          // iterating over the depedent keys
          let dependentDefObject = {};
          for(let DependentChildKey of this.consolidateParentFieldDefinition['consolidatedDependentKeys']) {
            for ( let fieldData of this.screenDefinition.fieldDetails.fields ) {
              /* checking for the key field && 
              checking if it is dependent && 
              checking if it is dependent on the parent. */
              if (DependentChildKey === fieldData['key'] && 
                  fieldData['isDependent'] &&
                  fieldData['dependentField'] === this.consolidateParentFieldDefinition['key'] )
                {
                  dependentDefObject[DependentChildKey] = fieldData;
                }
              }
          }
          for(let DependentChildKey of this.consolidateParentFieldDefinition['consolidatedDependentKeys']) {
            // getting the fieldDefinition of a particular dependent Key
            let DependentChildFieldDef = dependentDefObject[DependentChildKey];
            
            // when the DependentChildKey field Definition does not exist
            if(!DependentChildFieldDef) {
              continue;
            }
            // adding the consolidated dependent value to each record
            for (let recordData of dataItems) {
              /* checking for the presence of dependentkey in record &&
               checking if it is empty && checking that consolidated value is undefined && 
               checking the value of the parent value is in the depented child field depndentvalue . */
              if (DependentChildKey in recordData && 
                  recordData[DependentChildKey].trim() &&
                  !recordData[`${this.consolidateParentFieldDefinition['key']}_consolidatedFieldValue`] &&
                  DependentChildFieldDef['dependentValue'].includes(recordData[this.consolidateParentFieldDefinition['key']])) 
                {
                  recordData[`${this.consolidateParentFieldDefinition['key']}_consolidatedFieldValue`] = recordData[DependentChildKey];
              } 

              // fallback if nothing matches our conditional check making it empty 
              if (!recordData[`${this.consolidateParentFieldDefinition['key']}_consolidatedFieldValue`] && dataItems.indexOf(recordData) === dataItems.length - 1) {
                recordData[`${this.consolidateParentFieldDefinition['key']}_consolidatedFieldValue`] = '';
              }
            }
          }
        }
        return dataItems;
      },
    ellipsisText(text, count){
      if(text.length>count){
        return `${text.slice(0, count)}…`
      }
      return text
    },
    enableToDate(){
      this.validateFIlterDate();
      if(this.startDate.length != 0){
        this.disableToDate = false;
      }else{
        this.disableToDate = true;
      }
    },
    fetchFilterSets() {
      if (this.filterSetsEnabled) {
        this.display = this.filterSetEnabledWithRoleDef.display;
        let filterSetConfiguration = this.filterSetEnabledWithRoleDef
          .filterSetConfiguration
          ? this.filterSetEnabledWithRoleDef.filterSetConfiguration
          : null;
        if (filterSetConfiguration !== null) {
          this.filterConfig = filterSetConfiguration;
          for (let config of filterSetConfiguration) {
            this.availableFilterSetsList[config.key] = config;
            delete this.availableFilterSetsList[config.key]["key"];
          }
          if (this.filterConfig.length == 1) {
            this.selectedFilterSet = this.filterConfig[0];
            this.displayFilterSetDescription();
            this.isFilterSetSelected = true;
          }
        }
      }
    }, 
    populateFilterSets(){
      let customFilterFields = [];
      let fields = (this.screenDefinition !== '' && this.screenDefinition.fieldDetails.fields) ? this.screenDefinition.fieldDetails.fields : '';
      if(Array.isArray(fields)){
        for(let field of fields){
          if(field.isFilterable && new Set(['options', 'radio', 'toggle', 'slaStatus','priority', 'optionsGroup']).has(field.input_type)) {
            customFilterFields.push(field.key);
          }
        }
      }
      this.isFilterSetSelected = true;
      if(this.filterSetsEnabled){
        this.selectedWorkNames = this.selectedFilterSet.selectedWorkflow ? this.selectedFilterSet.selectedWorkflow : [];
        if(this.selectedWorkNames.length > 0) {
          this.fetchAllStatus();
        }
        this.selectedStatusOnes = this.selectedFilterSet.selectedStatus ? this.selectedFilterSet.selectedStatus : [];
        if(this.display.includes('All')){
          this.selectedAssignedToGroups = this.selectedFilterSet.selectedAssignedToGroup ? this.selectedFilterSet.selectedAssignedToGroup : [];
          if(this.selectedAssignedToGroups.length > 0 && !this.selectedAssignedToGroups.includes('ALL')){
            this.fetchAssigneesOnChange();
          }
          this.selectedAssignees = this.selectedFilterSet.selectedAssignee ? this.selectedFilterSet.selectedAssignee : [];
        }
        if(this.selectedFilterSet.customFilterableFields && customFilterFields.length > 0){
          for(let customFilterKey of customFilterFields){
            if(customFilterKey in this.selectedFilterSet.customFilterableFields){
              this.selectedFilterableFields[customFilterKey]  = this.selectedFilterSet.customFilterableFields[customFilterKey];
            }
            this.selectedFilterableFields = JSON.parse(JSON.stringify(this.selectedFilterableFields));
          }
        }
      }
    },
    handleFilterAction(){
      if(this.applyFilter){
        this.populateFilterSets();
        this.applyFilters();
      } else {
        this.resetFilters();
      }
    },
    displayFilterSetDescription(){
      this.isFilterSetSelected = true;
      if(this.selectedFilterSet.hasOwnProperty('description')){
        this.filterSetDescription = this.selectedFilterSet.description.en;
      } else {
        this.filterSetDescription = "";
      }
      if(this.applyFilter){
        this.filterOptionModified = true;
        this.applyFilters();
      }
    },
    compareSelectedArray(arr1 , arr2){
      const set1 = new Set(arr1);
      const set2 = new Set(arr2);
      let list1 = [...set1];
      let list2 = [...set2];
      list1.sort();
      list2.sort();
      if (list1.length !== list2.length) {
        return false;
      }
      return JSON.stringify(list1) === JSON.stringify(list2);
    },
    compareObjects(){
      let isFilterSetModified  = false;
      if(this.selectedFilterSet.selectedWorkflow !== undefined && !this.compareSelectedArray(this.selectedFilterSet.selectedWorkflow, this.selectedWorkNames)){
        isFilterSetModified = true;
      }
      if(this.selectedFilterSet.selectedStatus !== undefined && !this.compareSelectedArray(this.selectedFilterSet.selectedStatus, this.selectedStatusOnes)){
        isFilterSetModified = true;
      }
      if(this.selectedFilterSet.selectedAssignedToGroup !== undefined && this.showAssignedToGroupItems && !this.compareSelectedArray(this.selectedFilterSet.selectedAssignedToGroup, this.selectedAssignedToGroups)){
        isFilterSetModified = true;
      }
      if(this.selectedFilterSet.selectedAssignee !== undefined && this.showAssigneeItems && !this.compareSelectedArray(this.selectedFilterSet.selectedAssignee, this.selectedAssignees)){
        isFilterSetModified = true;
      }
      let selectedCustomFieldsKeys = Object.keys(this.selectedFilterableFields);
      if(this.showFilterField && this.selectedFilterSet.customFilterableFields!== undefined && selectedCustomFieldsKeys.length > 0){
        for(let key of selectedCustomFieldsKeys){
          if(!Array.isArray(this.selectedFilterSet.customFilterableFields[key])){
            if(this.selectedFilterSet.customFilterableFields[key] !== this.selectedFilterableFields[key]){
              isFilterSetModified = true;
            }
          }
          else if(!this.compareSelectedArray(this.selectedFilterSet.customFilterableFields[key], this.selectedFilterableFields[key])){
            isFilterSetModified = true;
          }
        }
      }
      if(isFilterSetModified && this.filterConfig.length > 1){
        this.selectedFilterSet = {};
        this.applyFilter = false;
        this.isFilterSetSelected = false;
      }else if(isFilterSetModified && this.filterConfig.length == 1){
        this.applyFilter = false;
        this.isFilterSetSelected = false;
      }
    },
    applyFilters(){
      if(this.filterSetsEnabled){
        if(this.filterOptionModified){
          this.populateFilterSets();
        }
        this.compareObjects();
        this.filterOptionModified = false;
      }
      this.errorValidation = [];
      var ifNoError = true;
      if (this.startDate || this.endDate) {
        if (!this.startDate) {
          this.errorValidation.push("From date is required when To Date is given");
          ifNoError = false;
        }
        else if (!this.endDate) {
          this.endDate = new Date().toISOString().split('T')[0];
        }
      }
      if(this.errorValidation.length > 0){
        this.errorValidationDialogObject["display"] = true;
        this.errorValidationDialogObject["valueValidationErrorList"] = this.errorValidation;
        this.showErrorValidationDialog = true;
      }
      if(ifNoError){
        this.fetchData();
      }
      if (this.filterConfig.length == 1) {
        this.selectedFilterSet = this.filterConfig[0];
        this.isFilterSetSelected = true;
      }
    },
    closeFilter(){
      this.iscolored = false
      this.whiteIconFeature = false
      this.showFilterComponent = !this.showFilterComponent;
      if(this.showFilterComponent){
        this.tableClass = "col-9";
        this.filterClass = "col-3";
      }
      else {
        this.tableClass = "col-12";
        this.filterClass = "col-0";
      }
      this.$emit("toggleWithFilterDrawer", true)
    },
    validateFIlterDate(){
      if(this.startDate && this.endDate){
        if (this.startDate > this.endDate) {
          this.endDate = ''
        }
      }
    },
    selectAll(field) {  
      var itemKeys = []
      // this will only work array of objs with key property
      // conditionally check for array of strings also
      itemKeys = field.option_values.map(function (item) {
        if ( field.input_type == 'priority' || (field.input_type == 'options' && field.viewType == 'reference') ){
          return item.key;
        } else {
          return item;
        }
      }) 
      if (this.allSelected[field.key]){
        this.$set(this.selectedFilterableFields, field.key, itemKeys)  
      } else {
        this.$set(this.selectedFilterableFields, field.key, [])
      }
    },
    selectOptions(field){ 
      var itemKeys = []
      itemKeys = field.option_values.map(function (item) {
        return item.key;
      })
      if(this.selectedFilterableFields.hasOwnProperty(field.key) && Array.isArray(this.selectedFilterableFields[field.key]) ){
        if(itemKeys.length === this.selectedFilterableFields[field.key].length){ 
            this.allSelected[field.key] = true
        }else{ 
          this.allSelected[field.key] = false
        }
      }
    },
    toggleOne() {
      this.showWorkFlow = false;
      this.$nextTick(() => {
        if (this.selectedAllWorkFlows) {
          this.selectedWorkNames = [];
          this.statusItems = [];
          this.selectedStatusOnes = [];
          this.showWorkFlow = null;
        } else {
          this.selectedWorkNames = this.workFlowItems.slice();
          this.fetchAllStatus();
        }
      });
    },
    toggleTwo() {
      this.showStatus = false;
      this.$nextTick(() => {
        if (this.selectedAllStatus) {
          this.selectedStatusOnes = [];
          this.showStatus = null;
        } else {
          this.selectedStatusOnes = this.statusItems.slice(); 
        }
      });
    },
    toggleThree() {
      this.showGroup = false;
      this.$nextTick(() => {
        if (this.selectedAllAssignedGroups) {
          this.selectedAssignedToGroups = [];
          this.assigneeItems = [];
          this.showGroup = null;
        } else {
          this.selectedAssignedToGroups = this.assignedToGroupItems.slice();
          this.fetchAssigneesOnChange();
        }
      });
    },
    toggleFour() {
      this.showAssign = false;
      this.$nextTick(() => {
        if (this.selectedAllAssignees) {
          this.selectedAssignees = [];
          this.showAssign = null;
        } else {
          this.selectedAssignees = this.assigneeItems.slice();
        }
      });
    },
    toggleFive() {
      this.$nextTick(() => {
        if (this.selectedCreatedByGroups.length === this.createdByGroupItems.length) {
          this.selectedCreatedByGroups = [];
          this.createdByItems = [];
          this.selectedCreatedByUsers = [];
          // this.assigneeItems = [];
        } else {
          this.selectedCreatedByGroups = this.createdByGroupItems.slice();
          this.fetchCreatedByUsersOnChange();
        }
        this.resetCreatedByUsers();
      });
    },
    toggleSix() {
      this.$nextTick(() => {
        if (this.selectedCreatedByUsers.length === this.createdByItems.length) {
          this.selectedCreatedByUsers = [];
        } else {
          this.selectedCreatedByUsers = this.createdByItems.slice();
          this.selectCreatedBy();
        }
      });
    },
    selectCreatedBy(){
      this.selectedFilterableFields['createdBy'] = this.selectedCreatedByUsers;
    },
    resetFilters() {
      this.applyFilter = false;
      this.startDate="";
      this.endDate="";
      this.allSelected = {}
      this.selectedStatus = "ALL";
      this.selectedStatusOnes = [];
      this.selectedAssignee = "ALL";
      this.selectedAssignees = [];
      this.assigneeItems = [];
      this.assigneeItemsCopy = []
      this.selectedAssignedToGroup = "ALL";
      this.selectedAssignedToGroups = [];
      this.selectedWorkflow = "ALL"; 
      this.selectedFilterableFields = {};  
      this.selectedCreatedByGroups = [];
      this.selectedCreatedByUsers = [];  
      this.itemKeys="";
      this.statusItems = [];
      this.resetCreatedByUsers();
      this.selectedWorkNames = [];
      if (this.filterConfig.length > 1) {
        this.selectedFilterSet = {};
        this.isFilterSetSelected = false;
      } else if (this.filterConfig.length == 1) {
        this.selectedFilterSet = this.filterConfig[0];
        this.isFilterSetSelected = true;
      }
      this.fetchData();
      this.enableToDate(); 
    },
    isRequired(value) {
      return value ? true : "This field is required";
    },
    getFilterableField(){
      this.filterableFields = []
      this.$emit("toggleSideBarFlagData", this.showFilterComponent)
      this.showFilterComponent = !this.showFilterComponent;
      if(this.showFilterComponent){
        this.tableClass = "col-9";
        this.filterClass = "col-3";
      }
      else {
        this.tableClass = "col-12";
        this.filterClass = "col-0";
      }
      this.iscolored = !this.iscolored;
      this.iscoloredCalender = false;
      this.whiteIconFeature = !this.whiteIconFeature;
      this.whiteIconCalender = false; 
      for(var fieldForFilter of this.screenDefinition.fieldDetails.fields){
        if(fieldForFilter.isFilterable && new Set(['options', 'radio', 'toggle', 'slaStatus', 'priority', 'optionsGroup', 'createdBy']).has(fieldForFilter.input_type)){
          if (fieldForFilter.input_type == 'createdBy'){
            this.showCreatedByGroup = true;
          } else {
            var field = {};
            field["key"] = fieldForFilter.key
            field['input_type'] = fieldForFilter.input_type;
            field["display_name"] = fieldForFilter.display_name.en;
            field["viewType"] = fieldForFilter.view_type;
            if(fieldForFilter.input_type == 'slaStatus'){
              field["option_values"] = ['Breached', 'Not Breached'];
            } else if (fieldForFilter.input_type == 'toggle'){
              field["option_values"] = [true, false];
            } else {
              field["option_values"] = fieldForFilter.option_values;
            }
            this.filterableFields.push(field);
          }
        }
      }  
    },
    showFiltersMethod() { 
      this.showFilters = !this.showFilters;
      this.showFilterDate = false; 
      this.iscolored = !this.iscolored;
      this.iscoloredCalender = false;
      this.whiteIconFeature = !this.whiteIconFeature;
      this.whiteIconCalender = false;
    },
    showFilterDateMethod() {
      this.showFilterDate = !this.showFilterDate;
      this.showFilters = false;
      this.iscoloredCalender = !this.iscoloredCalender;
      this.iscolored = false;
      this.whiteIconCalender = !this.whiteIconCalender;
      this.whiteIconFeature = false;
    },
    formatDate(date) {
      return moment(date).format("DD-MMM-yyyy hh:mm:ss A");
    },
    closeWorkFlowDialog(flag) {
      this.showWorkFlowFieldDialog = flag;
      this.fetchData();
    },
    closeUpdateFieldDialog(flag) {
      this.showUpdateDialog = flag;
      this.fetchData();
    },
    closeNotesDialog(flag) {
      this.showNotesDialog = flag;
    },
    getSearchFieldLabel(searchableFields) {
      this.searchFieldText = "Search by ID";
      if (searchableFields.length > 0) {
        for (var index = 0; index < searchableFields.length; index++) {
          if (this.$store.state.locale === "en") {
            this.searchFieldText =
              this.searchFieldText +
              " , " +
              searchableFields[index].display_name.en;
          } else {
            this.searchFieldText =
              this.searchFieldText +
              " , " +
              searchableFields[index].display_name.mm;
          }
        }
      }
    },
    clickAdd() {
      this.showDialog = true;
      this.dialogObject["display"] = true;
      this.dialogObject["title"] = "Add a Record";
      this.dialogObject["viewId"] = this.viewId;
      this.dialogObject["buttons"] = ["Save", "Cancel"];
      this.dialogObject["viewType"] = "workflow";
    },
    clickUploadCsv() {
      this.showCsvUploadDialog = true;
      this.csvDialogObject["display"] = true;
      this.csvDialogObject["title"] = "Upload Csv";
      this.csvDialogObject["viewId"] = this.viewId;
    },
    clickView(item) {
      this.showSidePanel = true;
      this.itemDetails = item;
    },
    closeConfirmationDialog(flag) {
      this.showConfirmationDialog = false;
      this.showDialog = flag;
      this.$emit("closeDialog", flag);
    },
    closeErrorValidationDialog(flag) {
      this.showErrorValidationDialog = flag;
    },
    closeDialog(flag) {
      this.showDialog = flag;
      this.fetchData();
    },
    closeMessageDialog(emitObject) {
      var closeDialogFlag = false;
      if (emitObject && emitObject.errorDisplayMessage) {
        closeDialogFlag = emitObject.errorDisplayMessage;
      }
      this.showSpinner = emitObject.flag;
      this.showPaginationSpinner = emitObject.flag;
      this.showMessageDialog = emitObject.flag; 
      this.$emit("closeDialog", closeDialogFlag);
    },
    closeCsvDialog(flag) {
      this.showCsvUploadDialog = flag;
      this.resetFilters();
    },
    closeSidePanel(data) {
      this.showSidePanel = data;
    },
    closeDetailsComponent(flag) {
      this.showSidePanel = flag;
      this.fetchData();
    },
    getStyle(status) {
      for (var fieldItem in this.screenDefinition.fieldDetails.fields) {
        var fieldDetails = this.screenDefinition.fieldDetails.fields[fieldItem];
        if (fieldDetails.key === "current_status") {
          // this.statusValue = fieldDetails.displayText[status][this.$i18n.locale];
          return "color:".concat(fieldDetails.color[status]).toString();
        }
      }
    },
    openPullDialog() {
      if((this.selectedMakerCheckerCondition || this.showMakerCheckerSelect === false) && this.pullSelectedWorkflow && this.isPullDone){
        this.clickPull();
      }else{
        this.pullDialog = true;
        this.isPullDone = true;
      }
    },
    changePullCondition(){
      this.pullDialog = true;
    },
    clickPull() {
      if (this.pullSelectedWorkflow == "") {
        this.showErrorValidationDialog = true;
        if (
          !this.errorValidationDialogObject.data.errorList.includes(
            "Select WorkFlow"
          )
        ) {
          this.errorValidationDialogObject.data.errorList.push(
            "Select WorkFlow"
          );
        }
      } else if (
        this.showMakerCheckerSelect == true &&
        this.selectedMakerCheckerCondition == ""
      ) {
        this.showErrorValidationDialog = true;
        this.errorValidationDialogObject.data.errorList.splice(0, 1);
        if (
          !this.errorValidationDialogObject.data.errorList.includes(
            "Select Pull Condition"
          )
        ) {
          this.errorValidationDialogObject.data.errorList.push(
            "Select Pull Condition"
          );
        }
      } else {
        this.showPaginationSpinner = true;
        this.pullDialog = false;
        axios({
          method: "post",
          url: process.env.VUE_APP_BACKEND_URL + "/pullRecord",
          data: {
            applicationId: this.$route.query.applicationId,
            viewId: this.viewId,
            // userId: "bj.testuser03@kbzbank.com",
            roleGroup: getFromLocalStorage("userGroup"),
            // userId: getFromLocalStorage("userId"),
            token: getFromLocalStorage("token"),
            authType: getFromLocalStorage("authType"),
            selectedScope: this.selectedScope,
            itemCount: 1,
            makerCheckerPullCondition: this.selectedMakerCheckerCondition,
            workflowId: this.pullSelectedWorkflow,
          },
          headers: {
            "content-type": "application/json",
          },
        })
          .then((response) => {
            if (response.status === 200) {
              if (response.data && response.data.message == "success") {
                this.fetchData();
              }
              if (response.data && response.data.message == "No Record Found") {
                this.fetchData();
                // location.reload();
                this.noRecordDialog = true;
                this.noRecordText = response.data.message;
              }
              this.showPaginationSpinner = false;
            }
          }, (this.noRecordDialog = false))
          .catch((error) => {
            if (
              error.response &&
              error.response.status &&
              error.response.status == 401
            ) {
              this.$root.$emit("update_session_value", true);
            } else {
              this.errorMessageShow = true;
              if (
                error.response &&
                error.response.data &&
                error.response.data.errorObject
              ) {
                this.errorMessageObject = {
                  errorMessageShow: this.errorMessageShow,
                  error_code: error.response.data.errorObject.error_code,
                  error_uuid: error.response.data.errorObject.error_uuid,
                };
              } else {
                this.errorMessageObject = {
                  errorMessageShow: this.errorMessageShow,
                  error_code: "",
                  error_uuid: "",
                };
              }
              this.showMessageDialog = true;
            }
          });
      }
    },
    getColor(item) {
      var itemValue = item.issue_type;
      for (var fieldItem in this.screenDefinition.fieldDetails.fields) {
        var fieldDetails = this.screenDefinition.fieldDetails.fields[fieldItem];
        if (fieldDetails.key === "issue_type") {
          for (var option in fieldDetails.option_values) {
            if (fieldDetails.option_values[option].value === itemValue) {
              return fieldDetails.option_values[option].color;
            }
          }
        }
      }
    },
    getButtonColor(item) {
      if (item.hasOwnProperty("color")) {
        return item.color;
      } else {
        return "#2A409A";
      }
    },
    getMoreData() {
    
      this.showPaginationSpinner = true;
     this.recordDataObject= {};
      axios({
        method: "post",
        url: process.env.VUE_APP_BACKEND_URL + "/getScreenDefinition",
        data: {
          applicationId: this.$route.query.applicationId,
          viewId: this.viewId,
          viewType: this.viewType,
          token: getFromLocalStorage("token"),
          authType: getFromLocalStorage("authType"),
          lastEvaluatedKey: this.lastEvaluatedKey,
          startDate: (this.startDate === "" || this.startDate === null) ? undefined : this.startDate.concat(" 00:00:00"),
          endDate: (this.endDate === "" || this.endDate === null) ? undefined : this.endDate.concat(" 23:59:59"),
          selectedWorkflow: this.selectedWorkflow,
          searchValue: this.searchValue,
          selectedAssignedToGroup: this.selectedAssignedToGroups.length > 0 ? this.selectedAssignedToGroups : "ALL",
          selectedAssignee: this.selectedAssignee,
          selectedStatus: this.selectedStatus,
          selectedScope: this.selectedScope,
          selectedFilterData: this.selectedFilterableFields,
          selectedMonthRange: this.selectedMonthRange
        },
        headers: {
          "content-type": "application/json",
        },
      })
        .then((response) => {
          this.getSearchFieldLabel(response.data.searchableFields);
          this.lastEvaluatedKey = response.data.LastEvaluatedKey;
          this.userType = response.data.userType;
          this.userGroup = response.data.userGroup;
          this.workFlowItems = [...response.data.workFlowItems];
          this.assignedToGroupItems = [...response.data.workflowGroups];
          if (!this.lastEvaluatedKey) {
            this.showLoadMore = false;
          } else if (response.data.data.length == 0 && this.lastEvaluatedKey) {
            this.openMoreItemsPopup();
            this.showLoadMore = true;
          } 
          else {
            this.showLoadMore = true;
          }
          this.dataItems = [...this.dataItems, ...this.checkAndAddConsolidateMainField(response.data.data)];
          this.showPaginationSpinner = false;
          this.showPullButton = response.data.showPullButton;
          if(response.data.disableFilter) {
            this.disableFilter = response.data.disableFilter;
          }
          this.recordItemCounter += response.data.data.length;
          this.recordDataObject = {
           'count' : this.recordItemCounter,
           'loadmore': this.showLoadMore
          }
          this.$emit("recordItemCounter", this.recordDataObject)
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.status &&
            error.response.status == 401
          ) {
            this.$root.$emit("update_session_value", true);
          } else {
            this.errorMessageShow = true;
            if (
              error.response &&
              error.response.data &&
              error.response.data.errorObject
            ) {
              this.errorMessageObject = {
                errorMessageShow: this.errorMessageShow,
                error_code: error.response.data.errorObject.error_code,
                error_uuid: error.response.data.errorObject.error_uuid,
              };
            } else {
              this.errorMessageObject = {
                errorMessageShow: this.errorMessageShow,
                error_code: "",
                error_uuid: "",
              };
            }
            this.showMessageDialog = true;
          }
        });
    },

    getSearchRecordEmpty() {
      if (this.searchValue) {
        this.message = "";
        this.searchValue = "";
        this.fetchData();
      } else if (this.searchValue.length === 0) {
      } else {
        this.message = "";
        this.searchValue = "";
      }
    },

    getSearchRecord() {
      if (this.searchValue !== "" && this.searchValue.trim() !== "") {
        this.searchValue = this.searchValue.trim();
        this.showPaginationSpinner = true;
        axios({
          method: "post",
          url: process.env.VUE_APP_BACKEND_URL + "/getScreenDefinition",
          data: {
            applicationId: this.$route.query.applicationId,
            viewId: this.viewId,
            viewType: this.viewType,
            token: getFromLocalStorage("token"),
            authType: getFromLocalStorage("authType"),
            selectedWorkflow: this.selectedWorkflow,
            selectedAssignedToGroup: this.selectedAssignedToGroups.length > 0 ? this.selectedAssignedToGroups  : "ALL",
            selectedAssignee: this.selectedAssignee,
            selectedStatus: this.selectedStatus,
            selectedScope: this.selectedScope,
            searchValue: this.searchValue,
            selectedFilterData: this.selectedFilterableFields,
            selectedMonthRange: this.selectedMonthRange
          },
          headers: {
            "content-type": "application/json",
          },
        })
          .then((response) => {
            this.recordItemCounter = 0;
            if (response.status === 200) {
              this.recordItemCounter = response.data.data.length;
              this.recordDataObject = {
              'count' : this.recordItemCounter,
              'loadmore': this.showLoadMore
              }
              this.$emit("recordItemCounter", this.recordDataObject)
              if (response.data == "No Record Found") {
                this.showSpinner = false;
                this.showMessageDialog = true;
                this.dialogText = "No Record Found";
              } else {
                if (response.data.disableFilter) {
                  this.disableFilter = response.data.disableFilter;
                }
                this.getSearchFieldLabel(response.data.searchableFields);
                this.dataItems = [];
                this.dataItems = [...this.dataItems, ...this.checkAndAddConsolidateMainField(response.data.data)];
                this.lastEvaluatedKey = response.data.LastEvaluatedKey;
                this.userType = response.data.userType;
                this.userGroup = response.data.userGroup;
                this.workFlowItems = [...response.data.workFlowItems];
                this.assignedToGroupItems = [...response.data.workflowGroups];
                if (!this.lastEvaluatedKey) {
                  this.showLoadMore = false;
                }
                else if (response.data.data.length == 0 && this.lastEvaluatedKey) {
                  this.openMoreItemsPopup();
                  this.showLoadMore = true;
                } else {
                  this.showLoadMore = true;
                }
                this.showPaginationSpinner = false;
                this.showPullButton = response.data.showPullButton;
              }
              this.showPaginationSpinner = false;
            }
          })
          .catch((error) => {
            this.showPaginationSpinner = false;
            if (
              error.response &&
              error.response.status &&
              error.response.status == 401
            ) {
              this.$root.$emit("update_session_value", true);
            } else {
              this.errorMessageShow = true;
              if (
                error.response &&
                error.response.data &&
                error.response.data.errorObject
              ) {
                this.errorMessageObject = {
                  errorMessageShow: this.errorMessageShow,
                  error_code: error.response.data.errorObject.error_code,
                  error_uuid: error.response.data.errorObject.error_uuid,
                };
              } else {
                this.errorMessageObject = {
                  errorMessageShow: this.errorMessageShow,
                  error_code: "",
                  error_uuid: "",
                };
              }
              this.showMessageDialog = true;
            }
          });
      }
    },
    isNumeric(value) {
      return !isNaN(parseFloat(value)) && isFinite(value);
    },
    isLogicalOperator(value) {
      if (value == "$and" || value == "$not" || value == "$or") {
        return true;
      } else {
        return false;
      }
    },
    isComparisonOperator(value) {
      if (
        value == "$ne" ||
        value == "$in" ||
        value == "$nin" ||
        value == "$lt" ||
        value == "$lte" ||
        value == "$gt" ||
        value == "$gte"
      ) {
        return true;
      } else {
        return false;
      }
    },
    getValue(statement, item) {
      let comparisonOperator;
      if (statement == true) {
        return true;
      }
      if (statement == undefined) {
        return false;
      }
      if (typeof statement == "object" && Object.keys(statement).length > 1) {
        let truthValues = [];
        for (var key in statement) {
          truthValues.push(this.getValue({ [key]: statement[key] }, item));
        }
        return truthValues.every((v) => v === true);
      }
      for (var key in statement) {
        comparisonOperator = "";
        if (key instanceof Object && !Array.isArray(key)) {
          return this.getValue(key, item);
        } else if (this.isLogicalOperator(key)) {
          if (key == "$and") {
            return statement[key].every((v) => this.getValue(v,item) === true);
          } else if (key == "$not") {
            return !this.getValue(statement[key], item);
          } else if (key == "$or") {
            return statement[key].reduce(
              (a, b) => this.getValue(b, item) || this.getValue(a, item)
            );
          } else {
            return false;
          }
        } else if (
          statement[key] instanceof Object &&
          Object.keys(statement[key]).length == 1 &&
          this.isComparisonOperator(Object.keys(statement[key])[0])
        ) {
          comparisonOperator = Object.keys(statement[key])[0];
          if (comparisonOperator == "$ne") {
            return (
              item[key] != statement[key][comparisonOperator]
            );
          } else if (comparisonOperator == "$in") {
            return statement[key][comparisonOperator].includes(
              item[key]
            );
          } else if (comparisonOperator == "$nin") {
            return !statement[key][comparisonOperator].includes(
              item[key]
            );
          } else if (comparisonOperator == "$lt") {
              if(this.isNumeric(item[key])){
              return (
                Number(item[key]) <
                Number(statement[key][comparisonOperator])
              );
              }
            return false;
          } else if (comparisonOperator == "$lte") {
            if (this.isNumeric(item[key])) {
              return (
                Number(item[key]) <=
                Number(statement[key][comparisonOperator])
              );
            }
            return false;
          } else if (comparisonOperator == "$gt") {
            if (this.isNumeric(item[key])) {
              return (
                Number(item[key]) >
                Number(statement[key][comparisonOperator])
              );
            }
            return false;
          } else if (comparisonOperator == "$gte") {
            if (this.isNumeric(item[key])) {
              return (
                Number(item[key]) >=
                Number(statement[key][comparisonOperator])
              );
            }
            return false;
          } else {
            return false;
          }
        } else if (
          typeof statement[key] == "boolean" ||
          typeof statement[key] == "string" ||
          (typeof statement[key] == "object" &&
            statement[key].constructor === String) ||
            this.isNumeric(statement[key]) ||
          typeof statement[key] == "boolean"
        ) {
          return item[key] == statement[key];
        } else {
          return false;
        }
      }
    },
    showActionOnConditionBtn(action, item){
      if(action['showActionOnCondition']){
        if(action.conditionalActionConfigStatement){
          return this.getValue(action.conditionalActionConfigStatement, item);
        } else {
          return false;
        }
      }
      return true;
    },

    checkShowActionForCreator(action, item) {
      if(!('showActionForCreator' in action)) {
        return true;
      }
      if(action.showActionForCreator) {
        if(item.CreatedBy === getFromLocalStorage('userId') && 
          item.CreatedByGroup === getFromLocalStorage('userGroup')) {
            return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    changeWorkFlow(action, item) {
      if(action.showIndependentFields){
        this.showIndependentFields = action.showIndependentFields;
      }else{
        this.showIndependentFields = false
      }
      this.fieldObject["assignToCreator"] = {};
      this.showWorkFlowFieldDialog = true;
      this.fieldObject["assigneeGroup"] = action.defaultAssigneeGroup;
      if (action.assignToCreator && (action.assignToCreator === true || (typeof action.assignToCreator === 'object' && Object.keys(action.assignToCreator).length>0))) {
        this.fieldObject["assignToCreator"] = action.assignToCreator;
        if(item.RecentAssignee && item.RecentAssignee.default && item.RecentAssignee.default[0].roleGroup) {
          this.fieldObject["assigneeGroup"] = item.RecentAssignee.default[0].roleGroup;
        }
      }
      this.fieldObject["display"] = true;
      this.fieldObject["viewId"] = this.viewId;
      this.fieldObject["action"] = "Workflow";
      this.fieldObject["persistAssignee"] = action.persistAssignee;
      this.itemDetails = item;
      this.fieldObject["next_status"] = action.key;
      this.fieldObject["previousAssignee"] = [];
      this.fieldObject["selfAssign"] = {};
      this.fieldObject["excludeSelfInAssignment"] = false;
      this.fieldObject["returnAction"] = {};
      this.fieldObject["lastStage"];
      for (var statusIndex in item.status_action) {
        if (item.status_action[statusIndex].key === action.key) {
          if (
            item.status_action[statusIndex].hasOwnProperty(
              "returnToPreviousAssignee"
            )
          ) {
            if (
              item.status_action[
                statusIndex
              ].returnToPreviousAssignee.hasOwnProperty("stage")
            ) {
              var previousStage =
                item.status_action[statusIndex]["returnToPreviousAssignee"][
                  "stage"
                ];
              if (item.hasOwnProperty("RecentAssignee")) {
                if (
                  item.RecentAssignee.hasOwnProperty(
                    item.status_action[statusIndex].returnToPreviousAssignee[
                      "stage"
                    ]
                  )
                ) {
                  this.fieldObject["previousAssignee"] = item.RecentAssignee[previousStage];
                  if(item.RecentAssignee && item.RecentAssignee[previousStage] && item.RecentAssignee[previousStage][0].roleGroup) {
                    this.fieldObject["assigneeGroup"] = item.RecentAssignee[previousStage][0].roleGroup;
                  }
                }
              }
            }
          }
          if (item.status_action[statusIndex].selfAssign) {
            this.fieldObject["selfAssign"] = item.status_action[statusIndex];
          }
          if(item.status_action[statusIndex].returnToLastAssignee && item.status_action[statusIndex].returnToLastAssignee.status && item.LastAssignee) {
            this.fieldObject["assigneeGroup"] = item.LastAssignee.roleGroup;
            this.fieldObject["LastAssignee"] = item.status_action[statusIndex].returnToLastAssignee;
          }
          if(item.status_action[statusIndex].excludeSelfInAssignment){
            this.fieldObject["excludeSelfInAssignment"] = item.status_action[statusIndex].excludeSelfInAssignment;
          }
          if ( "returnAction" in item.status_action[statusIndex] && Object.keys(item.status_action[statusIndex].returnAction > 0)) {
            this.fieldObject["returnAction"] = item.status_action[statusIndex].returnAction;
            if ("lastStage" in item){
              this.fieldObject["lastStage"] = item.lastStage;
              if("RecentAssignee" in item && item.lastStage in item.RecentAssignee){
                this.fieldObject["assigneeGroup"] = item.RecentAssignee[item.lastStage][0].roleGroup;
              }
            }
          }
        }
      }
    },
    clickUpdate(item) {
      this.updateFieldDialogObject["display"] = true;
      this.updateFieldDialogObject["viewId"] = this.viewId;
      this.updateFieldDialogObject["viewType"] = "workflow";
      this.fieldUpdateItem = item;
      this.showUpdateDialog = true;
    },
    openNotesDialog(item) {
      let userEmail = getFromLocalStorage('userId');
      this.notesDialogProps = {
        recordId : item['_id'],
        applicationId: this.$route.query.applicationId,
        userId: userEmail
      }
      this.showNotesDialog = true;
    },
    fetchStatusActions() {
      axios({
        method: "post",
        url: process.env.VUE_APP_BACKEND_URL + "/getAllStages",
        data: {
          applicationId: this.$route.query.applicationId,
          workflowId: this.selectedWorkflow,
          token: getFromLocalStorage("token"),
          authType: getFromLocalStorage("authType"),
        },
        headers: { "content-type": "application/json" },
      })
      .then((response) => {
        this.statusItems = [...this.statusItems, ...response.data.stages];
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status &&
          error.response.status == 401
        ) {
          this.$root.$emit("update_session_value", true);
        } else {
          this.errorMessageShow = true;
          if (
            error.response &&
            error.response.data &&
            error.response.data.errorObject
          ) {
            this.errorMessageObject = {
              errorMessageShow: this.errorMessageShow,
              error_code: error.response.data.errorObject.error_code,
              error_uuid: error.response.data.errorObject.error_uuid,
            };
          } else {
            this.errorMessageObject = {
              errorMessageShow: this.errorMessageShow,
              error_code: "",
              error_uuid: "",
            };
          }
          this.showMessageDialog = true;
        }
      });
    },
    fetchData() {      
      this.recordItemCounter = 0;    
      if (
        this.selectedStatusOnes.length == this.statusItems.length ||
        this.selectedStatusOnes.length <= 0
      ) {
        this.selectedStatus = "ALL";
      } else if (this.selectedStatusOnes.length > 0) {
        this.selectedStatus = this.selectedStatusOnes;
      }
      if (
        this.selectedAssignees.length == this.assigneeItems.length ||
        this.selectedAssignees.length <= 0
      ) {
        this.selectedAssignee = "ALL";
      } else if (this.selectedAssignees.length > 0) {
        this.selectedAssignee = this.selectedAssignees;
      }
      this.showPaginationSpinner = true;
      this.viewId = this.$props.workFlowScreenDetails.viewId;
      this.viewType = this.$props.workFlowScreenDetails.viewType;
      axios({
        method: "post",
        url: process.env.VUE_APP_BACKEND_URL + "/getScreenDefinition",
        data: {
          applicationId: this.$route.query.applicationId,
          viewId: this.viewId,
          viewType: this.viewType,
          token: getFromLocalStorage("token"),
          authType: getFromLocalStorage("authType"),
          selectedWorkflow: this.selectedWorkflow,
          searchValue: this.searchValue,
          selectedAssignedToGroup:  this.selectedAssignedToGroups.length > 0 ? this.selectedAssignedToGroups : "ALL",
          selectedAssignee: this.selectedAssignee,
          selectedStatus: this.selectedStatus,
          selectedScope: this.selectedScope,
          selectedFilterData: this.selectedFilterableFields,
          startDate: (this.startDate === "" || this.startDate === null) ? undefined : this.startDate.concat(" 00:00:00"),
          endDate: (this.endDate === "" || this.endDate === null) ? undefined : this.endDate.concat(" 23:59:59"),
          selectedMonthRange: this.selectedMonthRange
        },
        headers: {
          "content-type": "application/json",
        },
      })
        .then((getScreenDefinitionResponse) => {
          if (getScreenDefinitionResponse.data.disableFilter) {
            this.disableFilter = getScreenDefinitionResponse.data.disableFilter;
          }
          this.headers = [];
          if(getScreenDefinitionResponse.data.lastestAssignee && getScreenDefinitionResponse.data.latestAssigneeFlag){
              this.lastestAssigneeConfig = getScreenDefinitionResponse.data.lastestAssignee;
              this.latestAssigneeFlag = getScreenDefinitionResponse.data.latestAssigneeFlag;
          }
          this.getSearchFieldLabel(
            getScreenDefinitionResponse.data.searchableFields
          );
          this.screenDefinition = getScreenDefinitionResponse.data;
          this.showPullButton = getScreenDefinitionResponse.data.showPullButton;
          this.filterSetsEnabled = getScreenDefinitionResponse.data.filterSetsEnabled;
          if(this.filterSetsEnabled && this.filterConfig.length == 0){
            this.filterSetEnabledWithRoleDef =
              getScreenDefinitionResponse.data.filterSetEnabledWithRoleDef;
            this.fetchFilterSets();
          }
          this.showMakerCheckerSelect =
            getScreenDefinitionResponse.data.showMakerCheckerSelect;
          this.lastEvaluatedKey =
            getScreenDefinitionResponse.data.LastEvaluatedKey;
          if (this.screenDefinition.data.length == 0 && this.lastEvaluatedKey) {
            this.openMoreItemsPopup();
            this.showLoadMore = true;
          } else if (this.lastEvaluatedKey) {
            this.showLoadMore = true;
          }
          else {
            this.showLoadMore = false;
          }
          this.recordItemCounter = getScreenDefinitionResponse.data.data.length;
          this.recordDataObject = {
            'count': this.recordItemCounter,
            'loadmore': this.showLoadMore
          }
          this.$emit("recordItemCounter", this.recordDataObject)
          this.populateScopes();
          this.dataItems = [];
          this.$nextTick(() => {
            this.dataItems = this.checkAndAddConsolidateMainField(this.screenDefinition.data);
          });
          this.userType = getScreenDefinitionResponse.data.userType;
          this.userGroup = getScreenDefinitionResponse.data.userGroup;
          this.pullWorkFlowItems =
            getScreenDefinitionResponse.data.workFlowItems;
          if (this.pullWorkFlowItems.length === 1) {
            if(this.showMakerCheckerSelect == false){
              this.hidePullDialog = true;
            }
            this.isPullDone = true;
            this.selectedDisabled = true;
            this.pullSelectedWorkflow = this.pullWorkFlowItems[0];
          }
          if (getScreenDefinitionResponse.data.workFlowItems.length == 1) {
            this.workFlowItems = [
              ...getScreenDefinitionResponse.data.workFlowItems,
              ];
              this.selectedWorkflow=this.workFlowItems[0].workflowId;
              this.workflowDisable=true;
              this.fetchStatusActions();
          }
          else{
            this.workFlowItems = [
              "ALL",
              ...getScreenDefinitionResponse.data.workFlowItems,
            ];
          }
          this.assignedToGroupItems = [
            "ALL",
            ...getScreenDefinitionResponse.data.workflowGroups,
          ];
          this.createdByGroupItems = [
            ...getScreenDefinitionResponse.data.createdByGroups,
          ];
          if (this.showWorkFlow == null || this.showWorkFlow == false) {
            this.workFlowItems = [
              ...getScreenDefinitionResponse.data.workFlowItems,
            ];
            this.workFlowItemsCopy = this.workFlowItems;
          }
          if (this.showGroup == null || this.showGroup == false) {
            this.assignedToGroupItems = [
              ...getScreenDefinitionResponse.data.workflowGroups,
            ];
            this.assignedToGroupItemsCopy = this.assignedToGroupItems;
          }
          if (this.userType === "Group Admin") {
            this.selectedAssignedToGroup = this.userGroup;
            this.resetAssignees();
            this.fetchAssigneesResp(this.selectedAssignedToGroup)
              .then((response) => {
                if (this.showAssign == null || this.showAssign == false) {
                  this.assigneeItems = [
                    ...this.assigneeItems,
                    ...response.data.data.map(function (item) {
                      return item.email;
                    }),
                  ];
                  this.assigneeItemsCopy = this.assigneeItems;
                }
              })
              .catch((error) => {
                if (
                  error.response &&
                  error.response.status &&
                  error.response.status == 401
                ) {
                  this.$root.$emit("update_session_value", true);
                } else {
                  this.errorMessageShow = true;
                  if (
                    error.response &&
                    error.response.data &&
                    error.response.data.errorObject
                  ) {
                    this.errorMessageObject = {
                      errorMessageShow: this.errorMessageShow,
                      error_code: error.response.data.errorObject.error_code,
                      error_uuid: error.response.data.errorObject.error_uuid,
                    };
                  } else {
                    this.errorMessageObject = {
                      errorMessageShow: this.errorMessageShow,
                      error_code: "",
                      error_uuid: "",
                    };
                  }
                  this.showMessageDialog = true;
                }
              });
            }
          this.availableNumberInputFields = []
          for (var fieldItem in this.screenDefinition.fieldDetails.fields) {
            var fieldDetails =
              this.screenDefinition.fieldDetails.fields[fieldItem];
            if(fieldDetails.input_type === "table" || fieldDetails.input_type === "label"){
              fieldDetails.isMainField = false;
            }
            if (
              fieldDetails.isMainField && fieldDetails.isVisible &&
              ((fieldDetails.hasOwnProperty("mainFieldViewAccess") &&
                fieldDetails.mainFieldViewAccess.includes(this.userGroup)) ||
                !fieldDetails.hasOwnProperty("mainFieldViewAccess"))
                && !(this.pullWorkFlowItems.length === 1 && fieldDetails.key == 'issue_type') // Skipping issue_type field if there is only one workflow
            ) {
              if(fieldDetails.input_type === "currency") {
                var isAvailable = this.currencyFields.some(element => {
                  if(element.key === fieldDetails.key){
                    return true
                  }
                  else{
                    return false
                  }
                })
                if(!isAvailable) {
                  this.currencyFields.push(fieldDetails)
                }
              }
              if(fieldDetails.input_type === "datetime") {
                var isAvailable = this.datetimeFields.some(element => {
                  if(element.key === fieldDetails.key){
                    return true
                  }
                  else{
                    return false
                  }
                })
                if(!isAvailable) {
                  this.datetimeFields.push(fieldDetails)
                }
              }
              if(fieldDetails.input_type === "multiselect") {
                var isAvailable = this.multiselectFields.some(element => {
                  if(element.key === fieldDetails.key){
                    return true
                  }
                  else{
                    return false
                  }
                })
                if(!isAvailable) {
                  this.multiselectFields.push(fieldDetails)
                }
              }
              if(fieldDetails.input_type === "slaBreachTime") {
                let isAvailable = this.slaBreachTimeFields.some((element) => element.key === fieldDetails.key)
                if(!isAvailable) {
                  this.slaBreachTimeFields.push(fieldDetails)
                }
              }
              if(fieldDetails.input_type === "priority") {
                this.showPriority = true;
              }
              if(fieldDetails.input_type === "alert") {
                var isAvailable = this.alertDateTime.some(element => {
                  if(element.key === fieldDetails.key){
                    return true
                  }
                  else{
                    return false
                  }
                })
                if(!isAvailable) {
                  this.alertDateTime.push(fieldDetails)
                }
              }
              var headerObject = {};
              if (this.$i18n.locale === "mm") {
                headerObject["text"] = fieldDetails.display_name.mm;
              } else {
                headerObject["text"] = fieldDetails.display_name.en;
              }
              if (fieldDetails.view_type === "reference" && fieldDetails.input_type !== "multiselect") {
                headerObject["value"] = fieldDetails.key.concat("_reference");
              } else {
                if(fieldDetails.input_type=='alert'){
                  headerObject["text"] = fieldDetails.display_name.en.concat(" ("+fieldDetails.alertConfiguration.timezone+")")
                }
                headerObject["value"] = fieldDetails.key;
                
              }
              if (fieldDetails.tableProperties && fieldDetails.tableProperties.columnWidth) {
                headerObject['width'] = fieldDetails.tableProperties.columnWidth;
              }
              this.headers.push(headerObject);
              if ( "consolidatedMainFieldDisplayName" in fieldDetails && 'consolidatedDependentKeys' in fieldDetails ){
                this.consolidateParentFieldDefinition = fieldDetails;
                let consolidateDateMainFieldHeader = {
                  value: `${fieldDetails['key']}_consolidatedFieldValue`
                  }
                if (this.$i18n.locale === "mm") {
                    consolidateDateMainFieldHeader["text"] = fieldDetails.consolidatedMainFieldDisplayName.mm;
                } else {
                    consolidateDateMainFieldHeader["text"] = fieldDetails.consolidatedMainFieldDisplayName.en;
                }
                this.headers.push(consolidateDateMainFieldHeader);
              }
            }
            if(fieldDetails.input_type == "number"){
              this.availableNumberInputFields.push(fieldDetails.key)
            }
            if(fieldDetails.input_type === "assigneeOptions"){
              this.assigneeFieldItem = fieldDetails;
              if(getScreenDefinitionResponse.data.assignee_update == true){
                this.reAssigneeUpdateFlag = true;
              }              
            }
          }
          this.headers.forEach(element => {
            if(element.value === "status_action"){  
              element.sortable=false
            }
          });
          this.headers.push({
            text: "Actions",
            value: "actions",
            align: "center",
            fixed: true,
            width: "10%",
            sortable: false
          });
          if (
            (this.scopes &&
              this.scopes.length > 0 &&
              this.selectedScope == "ALL") ||
            this.viewType == "archive"
          ) {
            this.headers = this.headers.filter((header) => {
              return header.value != "status_action";
            });
          }
          this.showAddButton = this.screenDefinition.action.includes("add");
          this.showUploadCsvButton =
            this.screenDefinition.action.includes("add");
          this.showPaginationSpinner = false;
          this.showSpinner = false;
          this.setFilterVisibility();
        })
        .catch((getScreenDefinitionError) => {
          this.showSpinner = false;
          this.showPaginationSpinner = false;
          if (
            getScreenDefinitionError.response &&
            getScreenDefinitionError.response.status &&
            getScreenDefinitionError.response.status == 401
          ) {
            this.$root.$emit("update_session_value", true);
          } else {
            this.errorMessageShow = true;
            if (
              getScreenDefinitionError.response &&
              getScreenDefinitionError.response.data &&
              getScreenDefinitionError.response.data.errorObject
            ) {
              this.errorMessageObject = {
                errorMessageShow: this.errorMessageShow,
                error_code:
                  getScreenDefinitionError.response.data.errorObject.error_code,
                error_uuid:
                  getScreenDefinitionError.response.data.errorObject.error_uuid,
              };
            } else {
              this.errorMessageObject = {
                errorMessageShow: this.errorMessageShow,
                error_code: "",
                error_uuid: "",
              };
            }
            this.showMessageDialog = true;
          }
        });
    },
    openMoreItemsPopup () {
      this.showMoreItemsPopup = true;
    },
    closeShowMorePopup (showMore) {
      this.showMoreItemsPopup = false;
      if(showMore) {
        this.getMoreData();
      }
    },
    replaceAllWithEmptyString(selection) {
      if (selection == "ALL") {
        return "";
      } else {
        return selection;
      }
    },
    resetAssignees() {
      this.assigneeItems = [];
      this.assigneeItemsCopy = [];
      this.selectedAssignee = "ALL";
      this.selectedAssignees = [];
      this.filterableItems = [];
    },
    resetCreatedByUsers() {
      this.createdByItems = [];
      this.selectedCreatedByUsers = [];
      this.selectCreatedBy();
    },
    resetStatusses() {
      this.statusItems = [];
      this.selectedStatus = "ALL";
      this.selectedStatusOnes = [];
    },
    fetchAssigneesResp(rolegroup) {
      return axios({
        method: "post",
        url: process.env.VUE_APP_BACKEND_URL + "/getUserList",
        data: {
          applicationId: this.$route.query.applicationId,
          userGroup: rolegroup,
          token: getFromLocalStorage("token"),
          authType: getFromLocalStorage("authType"),
        },
        headers: { "content-type": "application/json" },
      });
    },
    fetchCreatedByUsersOnChange() {
      this.resetCreatedByUsers();
      if (this.selectedCreatedByGroups.length > 0) {
          this.selectedCreatedByGroups.forEach((element) => {
            this.fetchAssigneesResp(element)
              .then((response) => {
                  this.createdByItems = [
                    ...this.createdByItems,
                    ...response.data.data.map(function (item) {
                      return item.email;
                    }),
                  ];
              })
              .catch((error) => {
                if (
                  error.response &&
                  error.response.status &&
                  error.response.status == 401
                ) {
                  this.$root.$emit("update_session_value", true);
                } else {
                  this.errorMessageShow = true;
                  if (
                    error.response &&
                    error.response.data &&
                    error.response.data.errorObject
                  ) {
                    this.errorMessageObject = {
                      errorMessageShow: this.errorMessageShow,
                      error_code: error.response.data.errorObject.error_code,
                      error_uuid: error.response.data.errorObject.error_uuid,
                    };
                  } else {
                    this.errorMessageObject = {
                      errorMessageShow: this.errorMessageShow,
                      error_code: "",
                      error_uuid: "",
                    };
                  }
                  this.showMessageDialog = true;
                }
              });
          });
      }
      this.selectedFilterableFields['createdByGroup'] = this.selectedCreatedByGroups;
    },
    fetchAssigneesOnChange() {
      this.resetAssignees();
      if (
        this.selectedAssignedToGroups.length ==
          this.assignedToGroupItems.length
      ) {
        this.assignedToGroupItems.forEach((element) => {
          this.selectedAssignedToGroup = element;
          this.fetchAssigneesResp(this.selectedAssignedToGroup)
            .then((response) => {
              if (this.showAssign == null || this.showAssign == false) {
                this.assigneeItems = [
                  ...this.assigneeItems,
                  ...response.data.data.map(function (item) {
                    return item.email;
                  }),
                ];
                this.assigneeItemsCopy = this.assigneeItems;
              }
            })
            .catch((error) => {
              if (
                error.response &&
                error.response.status &&
                error.response.status == 401
              ) {
                this.$root.$emit("update_session_value", true);
              } else {
                this.errorMessageShow = true;
                if (
                  error.response &&
                  error.response.data &&
                  error.response.data.errorObject
                ) {
                  this.errorMessageObject = {
                    errorMessageShow: this.errorMessageShow,
                    error_code: error.response.data.errorObject.error_code,
                    error_uuid: error.response.data.errorObject.error_uuid,
                  };
                } else {
                  this.errorMessageObject = {
                    errorMessageShow: this.errorMessageShow,
                    error_code: "",
                    error_uuid: "",
                  };
                }
                this.showMessageDialog = true;
              }
            });
        });
        this.selectedAssignedToGroup = "ALL";
        //this.fetchData();
      } else {
        if (this.selectedAssignedToGroups.length > 0) {
          this.selectedAssignedToGroups.forEach((element) => {
            this.selectedAssignedToGroup = element;
            this.fetchAssigneesResp(this.selectedAssignedToGroup)
              .then((response) => {
                if (this.showAssign == null || this.showAssign == false) {
                  this.assigneeItems = [
                    ...this.assigneeItems,
                    ...response.data.data.map(function (item) {
                      return item.email;
                    }),
                  ];
                  this.assigneeItemsCopy = this.assigneeItems;
                }
              })
              .catch((error) => {
                if (
                  error.response &&
                  error.response.status &&
                  error.response.status == 401
                ) {
                  this.$root.$emit("update_session_value", true);
                } else {
                  this.errorMessageShow = true;
                  if (
                    error.response &&
                    error.response.data &&
                    error.response.data.errorObject
                  ) {
                    this.errorMessageObject = {
                      errorMessageShow: this.errorMessageShow,
                      error_code: error.response.data.errorObject.error_code,
                      error_uuid: error.response.data.errorObject.error_uuid,
                    };
                  } else {
                    this.errorMessageObject = {
                      errorMessageShow: this.errorMessageShow,
                      error_code: "",
                      error_uuid: "",
                    };
                  }
                  this.showMessageDialog = true;
                }
              });
          });
          this.selectedAssignedToGroup = this.selectedAssignedToGroups;
          //this.fetchData();
        }
      }
    },
    fetchAllStatus() {
      this.resetStatusses();
      if (
        this.selectedWorkNames.length == this.workFlowItems.length ||
        this.selectedWorkNames.length <= 0
      ) {
        this.workFlowItems.forEach((element) => {
          this.selectedWorkflow = element.workflowId;
          axios({
            method: "post",
            url: process.env.VUE_APP_BACKEND_URL + "/getAllStages",
            data: {
              applicationId: this.$route.query.applicationId,
              workflowId: this.selectedWorkflow,
              token: getFromLocalStorage("token"),
              authType: getFromLocalStorage("authType"),
            },
            headers: { "content-type": "application/json" },
          })
            .then((response) => {
              if (this.showStatus == null || this.showStatus == false) {
                this.statusItems = [
                  ...this.statusItems,
                  ...response.data.stages,
                ];
                this.statusItemsCopy = this.statusItems;
              }
            })
            .catch((error) => {
              if (
                error.response &&
                error.response.status &&
                error.response.status == 401
              ) {
                this.$root.$emit("update_session_value", true);
              } else {
                this.errorMessageShow = true;
                if (
                  error.response &&
                  error.response.data &&
                  error.response.data.errorObject
                ) {
                  this.errorMessageObject = {
                    errorMessageShow: this.errorMessageShow,
                    error_code: error.response.data.errorObject.error_code,
                    error_uuid: error.response.data.errorObject.error_uuid,
                  };
                } else {
                  this.errorMessageObject = {
                    errorMessageShow: this.errorMessageShow,
                    error_code: "",
                    error_uuid: "",
                  };
                }
                this.showMessageDialog = true;
              }
            });
        });
        this.selectedWorkflow = "ALL";
        //this.fetchData();
      } else {
        if (this.selectedWorkNames.length > 0) {
          this.selectedWorkNames.forEach((element) => {
            this.selectedWorkflow = element;
            axios({
              method: "post",
              url: process.env.VUE_APP_BACKEND_URL + "/getAllStages",
              data: {
                applicationId: this.$route.query.applicationId,
                workflowId: this.selectedWorkflow,
                token: getFromLocalStorage("token"),
                authType: getFromLocalStorage("authType"),
              },
              headers: { "content-type": "application/json" },
            })
              .then((response) => {
                if (this.showStatus == null || this.showStatus == false) {
                  this.statusItems = [
                    ...this.statusItems,
                    ...response.data.stages,
                  ];
                  this.statusItemsCopy = this.statusItems;
                }
              })
              .catch((error) => {
                if (
                  error.response &&
                  error.response.status &&
                  error.response.status == 401
                ) {
                  this.$root.$emit("update_session_value", true);
                } else {
                  this.errorMessageShow = true;
                  if (
                    error.response &&
                    error.response.data &&
                    error.response.data.errorObject
                  ) {
                    this.errorMessageObject = {
                      errorMessageShow: this.errorMessageShow,
                      error_code: error.response.data.errorObject.error_code,
                      error_uuid: error.response.data.errorObject.error_uuid,
                    };
                  } else {
                    this.errorMessageObject = {
                      errorMessageShow: this.errorMessageShow,
                      error_code: "",
                      error_uuid: "",
                    };
                  }
                  this.showMessageDialog = true;
                }
              });
          });
          this.selectedWorkflow = this.selectedWorkNames;
          //this.fetchData();
        }
      }
    },
    setFilterVisibility() {
      this.showFilterField = true;
      if (this.userType === "Admin") {
        this.showAssignedToGroupItems = true;
        this.showAssigneeItems = true;
      } else if (this.userType === "Group Admin") {
        this.showAssignedToGroupItems = false;
        this.showAssigneeItems = true;
      } else {
        this.showAssignedToGroupItems = false;
        this.showAssigneeItems = false;
      }
    },
    populateScopes() {
      this.scopes = this.screenDefinition.scopeDefinition;
      if(this.screenDefinition.scopeAccessDefinition) {
        this.scopeAddAccess = this.screenDefinition.scopeAccessDefinition;
      }
      if (this.scopes && this.scopes.length > 0) {
        if (!this.selectedScope && this.scopes.length == 1) {
          this.selectedScope = this.scopes[0].key;
        }
        if (this.scopes.length > 1) {
          this.scopes = [
            {
              scope_display_name: {
                en: "ALL",
                mm: "ALL",
              },
              key: "ALL",
              fieldKeys: {},
            },
            ...this.scopes,
          ];
          if (!this.selectedScope) {
            this.selectedScope = "ALL";
          }
        }
      }
    },
    numberFormatter(numberInput, key){
      var input = numberInput
      for(let field of this.screenDefinition.fieldDetails.fields){
        if(field.key == key){
          if(field.number_configuration && field.number_configuration.numberSystem){
            if(field.number_configuration.numberSystem == "English"){
              const NumberFormatter = new Intl.NumberFormat("en-US", this.formatConfig);
              input = NumberFormatter.format(input); 
            }
            else if(field.number_configuration.numberSystem == "Arabic"){
              const NumberFormatter = new Intl.NumberFormat("en-AR", this.formatConfig);
              input = NumberFormatter.format(input); 

            }
          }
        }
      }
      return input
    },
    formatCurrency(currencykey,fieldData) {
      if(fieldData[currencykey]) {
        var currencyCodeValue;
        var minDigits;
        var currencysystem;
        let currencyDisplay = 'narrowSymbol';
        var currencyfieldDetails = this.screenDefinition.fieldDetails.fields;
        if(fieldData.AdditionalFieldInformation && fieldData.AdditionalFieldInformation[currencykey]){
          currencyCodeValue = fieldData.AdditionalFieldInformation[currencykey]["currencyCode"];
          if(currencyCodeValue){
            for (var field of currencyfieldDetails){
              if(field.key === currencykey){
                if(field.currency_configuration){
                  if(field.currency_configuration.format) {
                    if(currencyCodeValue in field.currency_configuration.format) {
                      minDigits = field.currency_configuration.format[currencyCodeValue].digitsAfterdecimalPoint;
                      currencysystem = field.currency_configuration.format[currencyCodeValue].system;
                    }
                    else {
                      minDigits = 2;
                      currencysystem = 'English';
                    }
                  }
                  if (field.currency_configuration.currencyDisplay) {
                    if(field.currency_configuration.currencyDisplay === 'code'){
                      currencyDisplay = 'code';
                    } else if(field.currency_configuration.currencyDisplay === 'symbolAndCode'){
                      currencyDisplay = 'symbolAndCode';
                    }
                  }
                }
              }
            }
            return this.formatCurrencyValue(currencyCodeValue,minDigits,fieldData[currencykey],currencysystem, currencyDisplay);
          } else {
            return this.formatCurrencyValue("USD",2,fieldData[currencykey],"English", currencyDisplay);
          }
        } else {
            return this.formatCurrencyValue("USD",2,fieldData[currencykey],"English", currencyDisplay);
        }
      }
    },
    formatAlertDateTime(alertkey , fieldData){
      if(fieldData[alertkey]){
        if(this.gettimezone==""){
        this.gettimezone = this.getTimeZone(this.screenDefinition.fieldDetails.fields)
        }
        var formattedDateTime = new Date(fieldData[alertkey]).toLocaleString(undefined , {timezone: this.gettimezone});;
        return formattedDateTime; 
      }
    },
    formatDatetime(fieldItem,value, AdditionalFieldInformation){
      let formatedDatetime = "";
      let timezone = 'Asia/Kolkata';
      if(AdditionalFieldInformation && AdditionalFieldInformation[fieldItem.key] && AdditionalFieldInformation[fieldItem.key].timezone){
        timezone = AdditionalFieldInformation[fieldItem.key].timezone;
      }
      else if(fieldItem.validators && fieldItem.validators.date && fieldItem.validators.date.timezone){
         timezone = fieldItem.validators.date.timezone;  
      }
      if (value){
			  formatedDatetime = moment.unix(Number(value)).tz(timezone).format("DD-MM-YYYY HH:mm:ss");
		  }
		  return formatedDatetime; 
    },
    formatMultiselect(value, key){
      let formattedValue = "";
      if(value){
        let optionsSelected = value.map(option => {
          return option.value
        });
        formattedValue = optionsSelected.toString();
      }
      return formattedValue;
    },
    getTimeZone(fieldsdata){
      for(let i =0 ; i<fieldsdata.length ; i++){
        if(fieldsdata[i].input_type=='alert')
        return fieldsdata[i].alertConfiguration.timezone
      }
    },
    formatPriority(value) {
      let fields = this.screenDefinition.fieldDetails.fields;
      let priorityField = fields.find((field)=> field.input_type == 'priority');
      if (priorityField) {
        let selectedOption = priorityField.option_values.find((option)=> option.key == value);
        if (selectedOption && selectedOption.displayText) {
          return selectedOption.displayText;
        } else {
          return '';
        }

      } else {
        return ''
      }
    }, 
    formatBreachTime(field, fieldData){
      let breachFormat = 'llll';
      if ('breachTimeConfiguration' in field && 'format' in field['breachTimeConfiguration']) {
        breachFormat = field['breachTimeConfiguration']['format'];
      }
      let showBreachTime = fieldData !== undefined && fieldData !== '';
      if(showBreachTime){
        return moment(fieldData).format(breachFormat);
      } else {
        return '';
      }
    }
  },
  mounted() {
    this.fetchData(); 
  }
};
</script>

<style>
.pullBtnDsbl {
  background-color: #27ae60;
  height: 43px;
  border: 1px solid #bdbdbd;
}

.pullBtnDsbl:disabled {
  background-color: #62a37d;
  height: 43px;
  border: 1px solid #bdbdbd;
}

.customStyle .v-data-table-header tr,
.customStyle .v-data-table-header tr th {
  background: #ffffff !important;
  color: #000000 !important;
}

.customStyle .v-data-table__wrapper::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
  height: 5px;
}

.customStyle .v-data-table__wrapper::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: 0;
  background-color: rgb(42, 64, 154);
}

.v-tab--active {
  background-color: rgb(42, 64, 154);
  border-radius: 5px 5px 0px 0px;
  color: white;
  font-size: 0.8rem;
}

.v-tab.v-tab {
  color: white;
}

.v-tab {
  letter-spacing: 0px;
  text-transform: none !important;
}

.v-select .v-select__selections .v-select__selection {
  min-width: 150px;
}
/* .v-select .v-select__selections input {
  display: none;
} */

.error {
  color: red;
  margin-left: 5px;
}

/* .data-table-scroll-bar-width{
  width:5px !important
} */
.sub-heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #333333;
}
.pullContent {
  height: 200px;
  padding-left: 20px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #333333;
}
.tooltip {
  display: inline-block;
  position: relative;
  text-align: center;
  justify-content: right;
}

.tooltip .top {
  min-width: 300px;
  max-width: 900px;
  max-height: 120px;
  top: -20px;
  left: 50%;
  transform: translate(-50%, -100%);
  padding: 10px 5px 0px 5px;
  color: black;
  background-color: #eeeeee;
  font-weight: normal;
  font-size: 13px;
  border-radius: 8px;
  position: absolute;
  z-index: 99999999;
  box-sizing: border-box;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.5);
  display: none;
}

.tooltip:hover .top {
  display: block;
}

.tooltip .top i {
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -12px;
  width: 24px;
  height: 12px;
  overflow: hidden;
}

.tooltip .top i::after {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  background-color: #eeeeee;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.5);
}

.filterBtnColored {
  background-color: rgb(42, 64, 154) !important;
  color: white;
}
.whiteIcon {
  color: white !important;
}
.blackIcon {
  color: black !important;
}
.filterButton {
  cursor: pointer;
  height: 40px;
  font-size: 0.8rem;
  background-color: #ffffff;
  border-radius: 7px;
  border: 1px solid gray;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
@media only screen and (min-width: 960px) and (max-width: 20000px) {
  .container {
    max-width: 100%;
  }
}



/* width */
.filter_fields::-webkit-scrollbar {
  width: 7px;
}

/* Track */
.filter_fields::-webkit-scrollbar-track { 
  border-radius: 7px;
}
 
/* Handle */
.filter_fields::-webkit-scrollbar-thumb {
  background:rgb(42, 64, 154);
  border-radius: 7px;
}

/* Handle on hover */
.filter_fields::-webkit-scrollbar-thumb:hover {
  background:rgb(42, 64, 154);
}

.v-autocomplete:not(.v-input--is-disabled).v-select.v-text-field input {
  pointer-events: inherit;
  min-width: 0px;
}
.v-input__slot::before {
  border-style: none !important;
}
.v-input__slot::after {
  border-style: none !important;
}
.v-input--switch__thumb {
  border-radius: 5px;
  /* background-color: #39FF14; */
}
.v-input--switch__track {
  border-radius: 5px;
  background-color: #C7CAC7;
}
.v-select__slot::placeholder {
  font-weight: 700 !important;
}
</style>
